<template>

  <!-- Modal -->
  <div class="modal fade" id="notificationModal" tabindex="-1" aria-labelledby="notificationModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-header">
          <h1 class="modal-title fs-5" id="notificationModalLabel">Notifications</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="close_btn"></button>
        </div>

        <div class="modal-body">

          <div class="row">

            <template :key="n.id" v-for="(n, i) in notifications.data">

              <div class="col-12" v-if="n.support">

                <notification-support :question_to_support="n.support"/>

              </div>

              <div class="col-10 mb-3 pb-2 border-bottom" v-html="'<span class=\'badge rounded-pill text-bg-secondary me-2\'>' + (i+1) + '</span>' + n.message.replace(/\n/g, '<br>')"></div>

              <div class="col-2 border-bottom mb-3 pb-2 text-center">

                <RouterLink v-if="n.link" :to="n.link" @click="close_btn.click(); scrollTo(n.link)"
                            class="text-decoration-none btn btn-primary rounded-circle btn-sm me-2"
                            target="_blank"> ➤
                </RouterLink>

              </div>


            </template>


          </div>


        </div>

        <div class="modal-footer">

          <div class="container">

            <button type="button" @click="toChatPage()" class="btn btn-primary rounded-pill position-relative">
              Chat
              <span v-if="chat_count" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {{chat_count}}
                  <span class="visually-hidden">unread messages</span>
              </span>
            </button>

            <button type="button" class="btn btn-outline-primary rounded-pill float-end" data-bs-dismiss="modal" @click="mark()">Mark as read</button>
          </div>


          <!--          <button type="button" class="btn btn-primary">Save changes</button>-->
        </div>

      </div>
    </div>
  </div>


</template>

<script setup>
import {ref} from "vue";
import {Notifications} from "@/composables/profile/notification";
import NotificationSupport from '@/components/inc/NotificationSupport.vue'
import {useRouter} from "vue-router";
const router = useRouter()


const {notifications, markAsRead, notification_count, chat_count} = Notifications()

const close_btn = ref()

const toChatPage = async () => {

  await router.push('/chats')

  close_btn.value.click()
}

const mark = () => {

  markAsRead()

  notification_count.value = 0
  chat_count.value = 0

  notifications.data = {} // clear
}

const scrollTo = (link) => {


  setTimeout(() => {

    let h = window.location.hash

    //console.log(h)

    if (h) {
      document.getElementById(h.substring(1)).scrollIntoView();
    }

  }, 1000)

}
</script>
<template>

  <div class="card text-bg-dark text-center comfortaa rounded-4">
    <img src="/img/photo_service.jpg" class="card-img  rounded-4" alt="...">
    <div class="card-img-overlay">

      <h5 class="h2 card-title mt-md-3 fw-bold">Get Your Car Photographed</h5>

      <RouterLink to="/photo-service" class="btn btn-light rounded-pill mt-md-5"> GET STARTED </RouterLink>

      <!--        <p class="card-text h2 mt-md-5 ">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>-->

      <!--        <p class="card-text"><small>Last updated 3 mins ago</small></p>-->
    </div>
  </div>

</template>
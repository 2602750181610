<template>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdropChat" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropChatLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-header m-0">

          <div class="container m-0 p-0">

          <div class="row">

            <div class="col-3 text-center">

              <img v-if="chat_with.data.pic" class="rounded-circle me-2 mt-1 img_provider"
                   :src="'https://hotautomarket.com/storage/photo/'+ chat_with.data.id +'.jpg'"
                   width="70" height="70">

            </div>

            <div class="col-6">

              <span class="h4 modal-title oswald p-0 m-0" id="staticBackdropChatLabel">Chat with
              <br><span class="text-danger"> {{ chat_with.data.name }} {{ chat_with.data.surname }} </span>

                <span class="ms-2 d-inline-block text-success" style="font-size: 14px;" v-if="chat_with.data.verified">verified</span>
          </span>



            </div>

            <div class="col-3 text-end">
              <button @click="from_id = null" type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>

              <br>
              <add-file/>

            </div>

          </div>



            <div class="row mt-3">

              <div class="col-10">

                <!--                <label for="chatControlTextarea" class="form-label">Message</label>-->
                <textarea v-model="message.data.msg" class="form-control" id="chatControlTextarea" rows="2"
                          :placeholder=" message.data.file ? 'Describe file!' : 'Message'">

                </textarea>

              </div>

              <div class="col-2 text-center">

                <button type="button" class="btn btn-primary rounded-pill mt-3" :disabled="chat_loading" @click="sendMessage()">
                  <svg class="mb-1" height="18px" viewBox="0 0 24 24" width="20px"><title>Press Enter to send</title>
                    <path
                        d="M16.6915026,12.4744748 L3.50612381,13.2599618 C3.19218622,13.2599618 3.03521743,13.4170592 3.03521743,13.5741566 L1.15159189,20.0151496 C0.8376543,20.8006365 0.99,21.89 1.77946707,22.52 C2.41,22.99 3.50612381,23.1 4.13399899,22.8429026 L21.714504,14.0454487 C22.6563168,13.5741566 23.1272231,12.6315722 22.9702544,11.6889879 C22.8132856,11.0605983 22.3423792,10.4322088 21.714504,10.118014 L4.13399899,1.16346272 C3.34915502,0.9 2.40734225,1.00636533 1.77946707,1.4776575 C0.994623095,2.10604706 0.8376543,3.0486314 1.15159189,3.99121575 L3.03521743,10.4322088 C3.03521743,10.5893061 3.34915502,10.7464035 3.50612381,10.7464035 L16.6915026,11.5318905 C16.6915026,11.5318905 17.1624089,11.5318905 17.1624089,12.0031827 C17.1624089,12.4744748 16.6915026,12.4744748 16.6915026,12.4744748 Z"
                        fill="#ffffff"></path>
                  </svg>
                </button>

              </div>


            </div>


          </div>

        </div>

        <div class="modal-body scroll" id="chat_div">

          <load-line v-if="chat_loading"/>

          <template :key="m.id" v-for="m in messages.data">


            <div class="mb-2 pt-2 animate__animated animate__fadeInUp" :class="[m.from_id === from_id ? '' : 'text-end']">

              <car-info v-if="m.car" :car="m.car"/>
              <bid-info v-if="m.bid" :bid="m.bid"/>


              <img class="rounded-circle animate__animated animate__fadeIn mb-2 mt-1 me-2"
                   :class="m.from_id === from_id ? 'float-start me-2' : 'float-end ms-2'"
                   v-if="m.user.pic"
                   :src="'https://hotautomarket.com/storage/photo/'+ m.user.id +'.jpg'"
                   width="45" height="45" alt="img">


              <p class="m-0" v-html="activateUrl(m.msg)"></p>


              <small class="text-muted">{{ moment(m.created_at).fromNow() }}</small>
              <img v-if="user_id && m.to_id != user_id && m.mread == 1" src="/img/icons/check_read.svg" width="20">
              <img v-if="user_id && m.to_id != user_id  && m.mread == 0" src="/img/icons/check_unread.svg" width="20">


              <a v-if="m.file" class="d-block mt-1 mb-2 me-4" :href="'https://hotautomarket.com/storage/chat/' + m.file" target="_blank">

                <img src="/img/icons/attachment.svg" width="17" height="17">

                File Attached
              </a>

            </div>
          </template>


        </div>

        <div class="modal-footer">


          <div class="container m-0">

            <div class="row">

              <div class="col-2">
                <img src="/img/icons/attention.png" width="22" height="22" class="float-start me-2 mt-2">
              </div>

              <div class="col-10">
                Using chat can help resolve conflicts over contracts.
              </div>

            </div>

          </div>

          <!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->

        </div>

      </div>
    </div>
  </div>


</template>

<script setup>
import {onBeforeMount, onBeforeUnmount, ref, watch} from 'vue';
import {Chat} from '@/composables/chat';
import {userState} from '@/composables/login';
import {Cars} from '@/composables/profile/cars';
import {Bids} from '@/composables/Bids';

import CarInfo from '@/components/chat/CarInfo.vue';
import BidInfo from '@/components/chat/BidInfo.vue';
import AddFile from '@/components/chat/AddFile.vue';
import LoadLine from '@/components/inc/LoadLine.vue'
import moment from 'moment';

const {storeChat, getChat, message, messages, from_id, scrollToBottom, chat_with, chat_loading, viewed, markMessagesAsRead} = Chat()

const {user_id} = userState()


const {car} = Cars()

const {bid} = Bids()

const sendMessage = async () => {

  if (!message.data.msg && !message.data.file) {
    return
  }

  chat_loading.value = true;

  message.data.car_id = car.data.id ? car.data.id : null
  message.data.bid_id = bid.data.id ? bid.data.id : null

  message.data.from_id = user_id.value
  message.data.to_id = from_id.value

  await storeChat()

  message.data = {}

  //scrollToBottom()
}

const refresh_chat = ref()


onBeforeMount(() => {

  message.data.car_id = null
  message.data.bid_id = null
})

onBeforeUnmount(() => {

  clearInterval(refresh_chat.value)

})

// const stop_refresh = () => {
//
//   clearInterval(refresh_chat.value)
// }


const refresh = () => {

  refresh_chat.value = setInterval(async () => {

    //console.log(price.value)

    await getChat()


    // Mark messages
    if (viewed.value.length > 0) {
      await markMessagesAsRead()
    }

  }, 5000)
}

watch(from_id, () => { // newVal, oldVal

  if (from_id.value) {

    refresh()

  } else {

    clearInterval(refresh_chat.value)
  }

});

const activateUrl = (text) => {

  if(!text || text == null){

    return
  }

  let urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  })

  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}
</script>

<style scoped>

.scroll {

  height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

</style>
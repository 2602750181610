import {ref} from "vue";
import axios from "axios";

const availability_car = ref(0)
const availability = ref({})
const trade_in = ref({})
const sent= ref(false)

export const AvailabilityPublic = () => {

    const sendRequest = async () => {

        try {

            await axios.get('/sanctum/csrf-cookie').then(async () => {

                await axios.post('/api/car-availability', {availability: availability.value, trade_in: trade_in.value, availability_car: availability_car.value})

                availability.value = {}
                trade_in.value = {}

                setTimeout(() => {

                    sent.value = false

                }, 30000)

            });

        } catch (error) {

            console.log('>>> ' + error);
        }

    }


    return {

        availability, trade_in, sendRequest, sent, availability_car

    }
}
<template>

  <div ref="scrollContainer" class="scroll-container m-0 p-0">
    <div
        v-for="item in newsItems"
        :key="item.id"
        class="news-item"
        :data-news-id="item.id">
      <!--      {{ item.text }}-->


      <div class="row  m-0 p-0">

        <div class="col-sm-6 col-lg-4 mb-4 h-auto" :key="c.id" v-for="c in cars.data.data">

          <div class="card rounded-4 h-100 animate__animated animate__fadeIn">


            <!-- cars -->
            <RouterLink class="oswald rounded-top-4 imgPhotoFixed" :to="'/offer/' + c.id" :style="'background-image: url(https://hotautomarket.com/storage/cars/'+ c.photo.pfile +')'">
              <!--            <img v-if="c.photo && c.photo.id" :src="'https://hotautomarket.com/storage/cars/'+ c.photo.pfile"-->
              <!--                 class="card-img-top img-fluid rounded-top-4" :alt="'Offer for ' + c.model.model + (c.model_addition ? ' ' + c.model_addition : '' )">-->
            </RouterLink>
            <div class="card-body">

              <div class="row">

                <div class="col-6">

                  <h5 class="card-title oswald">{{ c.model.model }} <small class="text-muted oswald ms-1" v-if="c.model_addition">{{ c.model_addition }}</small></h5>

                  <span class="oswald h3 d-block" style="color: #1FA2FF">{{ c.model.brand.name }}</span>

                  {{ c.pre_order ? 'Pre order' : '' }}

                  <br v-if=" c.provider.zipcode">
                  <small class="text-danger" v-if="c.brand_new">Brand new</small>
                  <!--                <span v-if="c.provider.zipcode && c.brand_new">, </span>-->

                  <!--                <small v-if=" c.provider.zipcode"-->
                  <!--                       class="text-muted">Zip: {{ c.provider.zipcode }}</small>-->

                </div>

                <div class="col-6 text-end">

                  <price-type :car="c"/>

                  <br v-if="c.year">
                  <span class="d-inline-block mt-2 text-muted oswald">
                Mileage: {{ c.mileage }}, &nbsp;
              </span>
                  <span class="oswald d-inline-block mt-2 text-muted">
                {{ c.year ? c.year : '' }}
              </span>


                  <br v-if="c.commission_amount">
                  <br v-if="c.commission_amount">
                  <span class="mt-2 d-inline-block fw-bold" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Available for brokers" v-if="c.commission_amount"> Brokers Fee<span v-if="user_id">: ${{ Math.floor(c.commission_amount / 1000) + 'K' }} </span>
               </span>
                </div>

              </div>


              <!-- Comment -->
              <!-- <comment-item v-if="c.comment" :comment="c.comment" />-->
            </div>

            <div class="card-footer text-center rounded-bottom-4" style="background-color: #3f90f8" @click="router.push('/offer/' + c.id)">

              <a :href="'/offer/' + c.id" target="_blank" class="text-decoration-none text-light fw-bold">
                {{ languages[selected_language].view_details }}</a>

              <!--          <small class="text-muted">Updated {{ moment(c.updated_at).fromNow() }}</small>-->
            </div>

          </div>

        </div>

      </div>
    </div>


  </div>


</template>


<script setup>
import {ref, onMounted, onBeforeUnmount} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {Cars} from '@/composables/profile/cars';
import {userState} from '@/composables/login';
import PriceType from "@/components/main_page/PriceType.vue";
import {Languages} from '@/composables/Languages';


const props = defineProps(['id'])


const {user_id} = userState()

const {languages, selected_language} = Languages()
//const {translate_main_page} = TranslateMainPage()

const {cars} = Cars() // getHotLease



const scrollContainer = ref(null);
const newsItems = ref([]);
const currentId = ref(0);
const route = useRoute();
const router = useRouter();


// onBeforeMount(() => {
//
//   getListCars('')
// })

// Mock function to load news data
const loadNewsBatch = (count = 1) => {

  const newItems = [];

  for (let i = 0; i < count; i++) {
    currentId.value++;
    //newItems.push({id: currentId.value, text: `News Item ${currentId.value}`});
    newItems.push({id: currentId.value});
  }

  return newItems;
};

// Append initial content
newsItems.value.push(...loadNewsBatch());

// Function to handle scroll event
const handleScroll = () => {
  const container = scrollContainer.value;
  const {scrollTop, scrollHeight, clientHeight} = container;

  // Load more news items when near the bottom
  if (scrollTop + clientHeight >= scrollHeight - 50) {
    newsItems.value.push(...loadNewsBatch());
  }

  // Update URL based on the currently visible item
  const visibleItem = Array.from(container.querySelectorAll('.news-item')).find(item => {
    const rect = item.getBoundingClientRect();
    return rect.top >= 0 && rect.top < window.innerHeight;
  });

  if (visibleItem) {
    const newsId = visibleItem.getAttribute('data-news-id');
    if (route.path !== `/page/${newsId}`) {
      //router.replace({ hash: `/${newsId}` });
      router.replace(`/page/${newsId}`);
    }
  }
};

// Scroll to item when route changes
const scrollToItemFromHash = () => {
  const targetId = parseInt(route.hash.replace('/', ''), 1);
  if (targetId) {
    const targetItem = scrollContainer.value.querySelector(
        `.news-item[data-news-id="${targetId}"]`
    );
    if (targetItem) {
      targetItem.scrollIntoView({behavior: 'smooth'});
    }
  }
};

// Lifecycle hooks
onMounted(() => {
  scrollContainer.value.addEventListener('scroll', handleScroll);
  scrollToItemFromHash(); // Scroll to item on initial load
});

onBeforeUnmount(() => {
  scrollContainer.value.removeEventListener('scroll', handleScroll);
});
</script>


<style scoped>


.scroll-container {
  height: 150vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 20px;
  box-sizing: border-box;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.news-item {
  margin-bottom: 20px;
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
  /* border-radius: 5px; */
}
</style>
<template>


  <h3 class="comfortaa float-start">{{translate_customer_registration[selected_language].customer_registration}}</h3>
  <span class="badge rounded-pill text-bg-primary ms-2 handLink"

        data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true"
        :data-bs-title="translate_customer_registration[selected_language].customer_registration_info">
     info </span>
  <br><br>



  <stepper-list :car="car" />

</template>

<script setup>
import StepperList from '@/components/offer/broker_to_broker/StepperList.vue';
import {TranslateCustomerRegistration} from '@/composables/translate/customer_registration';
import {Languages} from '@/composables/Languages';

const {translate_customer_registration} = TranslateCustomerRegistration()
const {selected_language} = Languages()

defineProps(['car'])

</script>
<template>

  <span v-if="user_type == 2">

  <span v-if="car.data && !car.data.selected_car && selected != true" @click="Add" class="btn btn-outline-secondary btn-sm rounded-pill ms-3 mb-1 text-danger d-inline-block animate__animated animate__zoomIn">
    <img src="/img/icons/plus.svg" width="18" class="mb-1">
  </span>

    <span v-else @click="Remove" class="btn btn-outline-secondary btn-sm rounded-pill ms-3 mb-1 d-inline-block animate__animated animate__zoomIn">
    added
  </span>

  </span>


<!--  {{user_type}}-->

<!--  {{ car.data.selected_car }}-->

</template>

<script setup>
import {ref} from "vue";
import {SelectedCar} from '@/composables/profile/selected_car';
import {userState} from "@/composables/login";
import {Cars} from "@/composables/profile/cars";

const {car, getPrivateCarOffer} = Cars()


const {selected_car, storeSelectedCar, deleteSelectedCar} = SelectedCar()

const {user_id, user_type} = userState()


const selected = ref(false)

const Add = async () => {

  selected.value = true

  selected_car.data.user_id = user_id.value
  selected_car.data.car_id = car.data.id
  // selected_car.data.user_id = props.selected.user_id
  // selected_car.data.car_id = props.selected.car_id

  await storeSelectedCar()

  await getPrivateCarOffer(car.data.id)

}

const Remove = async () => {

  selected.value = false

  await deleteSelectedCar(car.data.selected_car.id)

  await getPrivateCarOffer(car.data.id)

}


</script>
<template>


  <div class="input-group mb-3">
    <!--    <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="remove-text-addon">-->

    <input type="text" v-model="search.data.text" list="brand_list" @focus="router.push('/')"
           class="form-control form-control-lg" id="searchControlInput"
           placeholder="🔎 Search" aria-label="Recipient's username" aria-describedby="remove-text-addon">

    <span class="input-group-text handLink animate__animated animate__fadeInLeft" id="remove-text-addon"
          @click="search.data.text = '', search_model = '', router.push('/')" v-if="search.data.text">
      X
    </span>
  </div>


  <datalist id="brand_list" class="w-100">
    <option :key="'brand_' + i" v-for="(c, i) in cars_models.data">{{ c.name }}</option>
  </datalist>


  <activate-models/>



</template>

<script setup>
import {watch} from "vue";
import {useRouter} from 'vue-router';
import {Cars} from "@/composables/profile/cars";

import ActivateModels from '@/components/search/ActivateModels.vue'

const {getListCars, cars_models, search, search_model} = Cars()

const router = useRouter()


watch(search, () => { // newVal, oldVal
      //console.log('Router:', newVal, oldVal)

      document.getElementById('search_result').scrollIntoView();


      if (search.data && search.data.text) {
        router.push('/')
      }

      getListCars(search.data.text)

      //scroll to top
      //window.scrollTo(0, 0)



    } //, {deep: true, immediate: true}
);
</script>
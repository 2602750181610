import {computed, reactive, ref} from "vue";
import axios from "axios";


const chat_loading = ref(false)

const from_id = ref(0);


const message = reactive({
    data: {}
});

const messages = reactive({
    data: {}
});

const users_chat = reactive({
    data: {}
});

const chat_with = reactive({
    data: {}
});

export const Chat = () => {


    const getChat = async () => {

        try {

            if (from_id.value) {

                await axios.post('/api/chat-messages/' + from_id.value).then((response) => {

                    messages.data = response.data


                })
            }

        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const getChatList = async () => {


        try {

            await axios.post('/api/chat-list').then((response) => {

                users_chat.data = response.data
            })


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const storeChat = async () => {

        chat_loading.value = true

        try {


            await axios.post('/api/chat', message.data)

            await getChat()

            scrollToBottom()

        } catch (e: any) {

            console.log(e)
        }

        chat_loading.value = false
    }

    const markMessagesAsRead = async () => {

        try {
            await axios.post('/api/chat-mark', viewed.value)

            await getChat()

            //scrollToBottom()

        } catch (e: any) {

            console.log(e)
        }
    }


    const viewed = computed(() => {
        return Object.values(messages.data).filter((x: any) => x.mread === 0 && x.from_id === from_id.value ).map((x: any) => x.id)
    })

    const scrollToBottom = () => {
        // get the div element by its id
        const div = document.getElementById('chat_div');

        if (div) {
            // smooth scroll to the bottom of the div
            div.scrollTo({
                top: div.scrollHeight,
                behavior: 'smooth'
            });
        }

        setTimeout(() => {

            if (div) {
                // smooth scroll to the bottom of the div
                div.scrollTo({
                    top: div.scrollHeight,
                    behavior: 'smooth'
                });
            }

        }, 800)

    }


    return {
        message, messages, getChat, storeChat, from_id, scrollToBottom, chat_with, chat_loading,
        viewed, markMessagesAsRead,
        getChatList, users_chat
    }

}

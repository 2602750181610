<template>


  <div class="row">


    <div class="col-12">

      <div class="container">

        <!-- Cookies -->
        <p v-if="!accepted"
           class="text-center alert alert-secondary fixed-bottom animate__animated animate__fadeInUp animate__delay-5s"
           role="alert">

<!--          <img src="/img/icons/2/cookie.svg" class="img-fluid me-2" width="25" alt="accept cookies">-->
          By using HotAutoMarket.Com, you automatically accept cookies. Please see
          <a href="/privacy" target="_blank" class="text-dark fw-bold">Privacy Policy</a>


          <span class="btn btn-outline-secondary rounded-pill bg-white text-dark ms-3 mt-1" @click="agreeWithCookies()">Okay</span>

        </p>
      </div>

    </div>


<!--    <div class="col-12" v-if="!accepted">-->
<!--      <div class="alert alert-danger fixed-bottom h4 animate__animated text-center"-->
<!--           :class="closeAlert ? 'animate__fadeOutDown' : 'animate__fadeInUp'"-->
<!--           role="alert">-->
<!--        last booking was made {{ minMax() }} minutes {{ minMax(3, 59) }} seconds ago-->

<!--      </div>-->
<!--    </div>-->


  </div>


</template>

<script setup>
import {ref, onMounted} from "vue";
import {userState} from "@/composables/login";



const accepted = ref(false)
const closeAlert = ref(false)

const {user_id} = userState();

//localStorage.setItem('cookies_proceed', '')

const agreeWithCookies = () => {

  accepted.value = true
  localStorage.setItem('cookies_proceed', 'true')
}

onMounted(() => {

  //localStorage.setItem('cookies_proceed', '')

  //console.log(localStorage.getItem('cookies_proceed'))

  if (localStorage.getItem('cookies_proceed') == 'true' || user_id.value) {
    accepted.value = true
  }

  closeAlert.value = false

  setTimeout(() => {

    closeAlert.value = true
  }, 5000)

});

// const minMax = (min = 20, max = 60) => {
//
//   min = Math.ceil(min);
//   max = Math.floor(max);
//
//   return Math.ceil(Math.random() * (max - min) + min)
// }
</script>

<!--<style scoped>-->

<!--.cookieConsentContainer {-->
<!--  z-index: 999;-->
<!--  width: 350px;-->
<!--  min-height: 20px;-->
<!--  box-sizing: border-box;-->
<!--  padding: 30px;-->
<!--  background: #8d8d8d;-->
<!--  overflow: hidden;-->
<!--  position: fixed;-->
<!--  bottom: 30px;-->
<!--  right: 30px;-->
<!--  /*display: none*/-->
<!--}-->

<!--.cookieConsentContainer .cookieTitle a {-->
<!--  font-family: OpenSans, arial, "sans-serif";-->
<!--  color: #FFF;-->
<!--  font-size: 22px;-->
<!--  line-height: 20px;-->
<!--  display: block-->
<!--}-->

<!--.cookieConsentContainer .cookieDesc p {-->
<!--  margin: 0;-->
<!--  padding: 0;-->
<!--  font-family: OpenSans, arial, "sans-serif";-->
<!--  color: #FFF;-->
<!--  font-size: 13px;-->
<!--  line-height: 20px;-->
<!--  display: block;-->
<!--  margin-top: 10px-->
<!--}-->

<!--.cookieConsentContainer .cookieDesc a {-->
<!--  font-family: OpenSans, arial, "sans-serif";-->
<!--  color: #FFF;-->
<!--  text-decoration: underline-->
<!--}-->

<!--.cookieConsentContainer .cookieButton a {-->
<!--  display: inline-block;-->
<!--  font-family: OpenSans, arial, "sans-serif";-->
<!--  color: #FFF;-->
<!--  font-size: 14px;-->
<!--  font-weight: 700;-->
<!--  margin-top: 14px;-->
<!--  background: #525252;-->
<!--  box-sizing: border-box;-->
<!--  padding: 15px 24px;-->
<!--  text-align: center;-->
<!--  transition: background .3s-->
<!--}-->

<!--.cookieConsentContainer .cookieButton a:hover {-->
<!--  cursor: pointer;-->
<!--  background: #3E9B67-->
<!--}-->

<!--@media (max-width: 980px) {-->
<!--  .cookieConsentContainer {-->
<!--    bottom: 0 !important;-->
<!--    left: 0 !important;-->
<!--    width: 100% !important-->
<!--  }-->
<!--}-->


<!--</style>-->
<template>


  <div class="card mt-4 animate__animated animate__fadeIn">

    <div class="card-header pb-0">

      <h4 class="oswald">Leasing</h4>

    </div>


    <div class="card-body">


      <div class="row text-center">

        <div class="col-sm-12 col-md-4 mb-3 h-auto">
          <span class="text-muted">Down payment</span>
          <h5 class="mt-2 oswald text-danger">${{ car.data.leasing_down_payment ? car.data.leasing_down_payment : 0 }}</h5>
        </div>

        <div class="col-sm-12 col-md-4 mb-3 h-auto" v-if="car.data.leasing_first_payment">
          <span class="text-muted">First payment</span>
          <h5 class="mt-2 oswald text-danger">${{ car.data.leasing_first_payment }}</h5>
        </div>


        <div class="col-sm-12 col-md-4 h-auto" v-if="car.data.leasing_monthly_payment">
          <span class="text-muted">Payment monthly</span>
          <br><small class="text-muted">with good credit score</small>
          <h5 class="mt-2 oswald text-danger">${{ car.data.leasing_monthly_payment }}</h5>
        </div>

        <div class="col-sm-12 col-md-4 h-auto" v-if="car.data.lease_term_months">
          <span class="text-muted">Terms</span>
          <h5 class="mt-2 oswald text-danger">{{ car.data.lease_term_months }} months</h5>
        </div>

        <div class="col-sm-12 col-md-4" v-if="car.data.total_lease_miles">
          <span class="text-muted">Total miles</span>
          <h5 class="mt-2 oswald text-danger">{{ car.data.total_lease_miles }} </h5>
        </div>


        <!--        <div class="col-sm-12 col-md-4" v-if="car.data.miles_per_month">-->
        <!--          <span class="text-muted">Miles per month</span>-->
        <!--          <h5 class="mt-2 oswald text-danger">{{car.data.miles_per_month}}</h5>-->
        <!--        </div>-->

        <div class="col-sm-12 col-md-4" v-if="car.data.	miles_per_year">
          <span class="text-muted">Miles per year</span>
          <h5 class="mt-2 oswald text-danger">{{ car.data.miles_per_year }}</h5>
        </div>

        <div class="col-sm-12 border-top mt-3 text-start" v-if="car.data.miles_per_month">
          <p v-if="car.data.leasing_description" v-html="car.data.leasing_description.replace(/\n/g, '<br>')">
          </p>
        </div>


      </div>

      <h2 class="h5 mt-2">OUR SERVICES:</h2>


        <ul>
          <li> Save up to 30% With HotAutoMarket</li>
          <li>Wholesale pricing for more than 24 brands</li>
          <li>No Negotiations at Dealership</li>
          <li>Car Delivery to your Home as Soon as Today</li>
        </ul>


      <p class="small text-muted">
        Due at signing: inception fees only. Аll lease payments do not include sales tax. Inception fees: 1st monthly payment, license plate & registration processing fee. Sign & Drive: all fees included.
      </p>
      <p class="small text-muted">
        * Taxes, title, registration, tags, insurance, and optional equipment, products, packages, and accessories are not included in base MSRP. Monthly payment is being updated according to actual car inventory. Monthly payment is being updated according to actual car inventory. We always keep working on pricing updates and it might be changed without notice. Pictures of the cars are for display purposes only and may change according to exact trim and model you choose.
      </p>


    </div>
  </div>


</template>

<script setup>
import {Cars} from "@/composables/profile/cars";

const {car} = Cars()
</script>
<template>


  <!--  bg-light-->
  <nav class="navbar navbar-expand-lg border-bottom shadow-sm navbar-dark bg-dark">
    <div class="container">

      <RouterLink class="d-none d-sm-block
      fw-light
      navbar-brand oswald animate__animated animate__fadeIn m-0" to="/">
        <!--        <img src="/img/logo.png" alt="Logo" height="30" class="d-inline-block align-text-top float-start me-2">-->

        <span class="h3 text-gradient-red fw-light">HOT AUTO MARKET</span>
      </RouterLink>

      <RouterLink style="line-height: 1;" class="d-block d-sm-none
      fw-light
      oswald animate__animated animate__fadeIn m-0 p-0" to="/">
        <!--        <img src="/img/logo.png" alt="Logo" height="30" class="d-inline-block align-text-top float-start me-2">-->

        <span class="text-gradient-red m-0 p-0 d-inline-block">HOT AUTO</span>
        <br><span class="text-gradient-red m-0 p-0 d-inline-block">MARKET</span>
      </RouterLink>

      <span class="navbar-text handLink oswald fw-light badge rounded-pill text-bg-light text-uppercase me-2 ms-2 me-md-3 animate__animated animate__fadeIn delay-0"
            @click="activateBuyCarModal()">&nbsp;{{languages[selected_language].buy}}&nbsp;</span>

      <span class="navbar-text handLink oswald  fw-light badge rounded-pill text-bg-light me-2 ms-1 text-uppercase animate__animated animate__fadeIn delay-1"
            @click="btn_sell = true, activateSellCarModal()"> {{languages[selected_language].sell}} </span>

      <RouterLink to="/board" class="navbar-text oswald  fw-light text-uppercase text-decoration-none handLink badge rounded-pill text-bg-light me-2 ms-2 me-md-3 animate__animated animate__fadeIn delay-3">
        {{languages[selected_language].wanted.title}} </RouterLink>
      <!--      <span v-else class="navbar-text handLink btn btn-light text-dark btn-sm rounded-pill me-2"-->
      <!--            data-bs-toggle="modal" data-bs-target="#addCarModal">SELL</span>-->


      <span v-if="loggedIn && notification_count == 0 && chat_count == 0" class="navbar-text h5 d-inline-block me-1 me-md-5 ms-2 pt-1 handLink animate__animated animate__fadeInDown delay-4" @click="focusOnSearch()" style="width: 23px; height: 23px">
        <img src="/img/icons/magnifier.svg" alt="search in market" class="img-fluid">
      </span>
      <span v-else-if="notification_count || chat_count" class="position-relative animate__animated animate__swing me-1 ms-1" data-bs-toggle="modal" data-bs-target="#notificationModal">
        <img src="/img/icons/bell.svg" class="handLink" width="30">
        <small class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            <small> {{ notification_count ? notification_count : chat_count }} </small>
            <small class="visually-hidden">unread messages</small>
        </small>
      </span>


      <button class="navbar-toggler btn btn-outline-secondary" ref="hamburger" v-if="loggedIn" type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarText" v-if="loggedIn">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link active" aria-current="page" href="#">Home</a>-->
          <!--          </li>-->


<!--          <li class="nav-item" v-if="user_type == 1 || user_type == 2">-->
<!--            <RouterLink class="nav-link handLink text-white" style="font-size: 18px" :to="'/board'"-->
<!--                        @click="hamburger.click()">-->
<!--              Board<sup>🔥</sup>-->
<!--            </RouterLink>-->
<!--          </li>-->

          <li class="nav-item mt-3 mt-md-0">
            <RouterLink @click="hamburger.click()" class="nav-link handLink ms-2 text-white" :to="'/bookings'">

              <img src="/img/icons/arrow_right.svg" width="18" alt="arrow">
              {{languages[selected_language].inquires}}
            </RouterLink>
          </li>

          <li class="nav-item">
            <RouterLink class="nav-link handLink text-white ms-2" style="font-size: 18px" to="/list-cars"
                        @click="hamburger.click()">
              <img src="/img/icons/arrow_right.svg" width="18" alt="arrow">
              {{languages[selected_language].my_cars}}
            </RouterLink>
          </li>


          <!--          <li class="nav-item">-->
          <!--            <RouterLink @click="hamburger.click()" class="nav-link handLink ms-2 text-white" :to="'/bookings'">-->
          <!--              Inquires-->
          <!--            </RouterLink>-->
          <!--          </li>-->

          <li class="nav-item">
            <RouterLink @click="hamburger.click()" class="nav-link handLink ms-2 text-white" to="/cars-wanted">

              <img src="/img/icons/arrow_right.svg" width="18" alt="arrow">
              {{languages[selected_language].searching}}

            </RouterLink>
          </li>


          <li class="nav-item">
            <RouterLink @click="hamburger.click()" class="nav-link handLink ms-2 text-white" to="/chats">
              <img src="/img/icons/arrow_right.svg" width="18" alt="arrow">
              {{languages[selected_language].chat}}
            </RouterLink>
          </li>

          <li class="nav-item">
            <RouterLink class="nav-link handLink text-white ms-2" to="/profile"
                        @click="hamburger.click()">


              <img src="/img/icons/arrow_right.svg" width="18" alt="arrow">

              <img v-if="user_pic == 1" :src="'https://hotautomarket.com/storage/photo/' + user_id + '.jpg'"
                   width="25" class="rounded-circle img_profile ms-1">
              <span class="d-sm-block d-md-none ms-2">
                {{languages[selected_language].profile}}
              </span>

            </RouterLink>


          </li>


          <li class="nav-item" v-if="user_type == '-1'">
            <RouterLink @click="hamburger.click()" class="nav-link handLink ms-2 text-white" to="/ambassador/school">
              <img src="/img/icons/arrow_right.svg" width="18" alt="arrow">
              My school
            </RouterLink>
          </li>


          <li class="nav-item">

            <img src="/img/icons/arrow_right.svg" width="18" alt="arrow" class="ms-2">
            <RouterLink @click="hamburger.click()" class="nav-link handLink text-uppercase oswald mt-2 ms-2 badge rounded-pill text-bg-light" to="/off-market">
              {{languages[selected_language].off_market}}
            </RouterLink>
          </li>


          <!--          <li class="nav-item">-->
          <!--            <span @click="activateBuyCarModal()" class="nav-link handLink ms-2 text-white">-->
          <!--                  -> Buy a car-->
          <!--                </span>-->
          <!--          </li>-->


          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link" href="#">Pricing</a>-->
          <!--          </li>-->


        </ul>


        <span class="navbar-text handLink text-muted" @click="LogOut()">

              <span class="d-md-none me-2 text-white mt-3 d-inline-block">Sign out</span>

            <img src="/img/icons/logout.svg" width="16" class="me-2">
          </span>
      </div>

      <div class="collapse navbar-collapse" id="navbarText" v-else>
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link active" aria-current="page" href="#">Home</a>-->
          <!--          </li>-->

          <!--          <RouterLink class="nav-link handLink" :to="'/'">-->

          <!--              <span data-bs-toggle="modal" data-bs-target="#carRequestModal">-->
          <!--                  Request-->
          <!--                </span>-->
          <!--          </RouterLink>-->


          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link" href="#">Pricing</a>-->
          <!--          </li>-->


        </ul>


      </div>

      <!--      <span v-if="!loggedIn" class="navbar-text h5 d-inline-block me-3 me-md-5 ms-2 pt-0 handLink" @click="focusOnSearch()">-->
      <!--        <img src="/img/icons/magnifier.svg" width="23" height="23" alt="search in market" class="img-fluid d-inline-block">-->
      <!--      </span>-->

      <span v-if="!loggedIn" class="navbar-text handLink badge rounded-pill animate__animated animate__fadeInDown delay-4">
        <img src="/img/icons/magnifier.svg" width="23" height="23" alt="search in market" class="img-fluid me-2" @click="focusOnSearch()">
      </span>

      <span v-if="!loggedIn" id="login_modal" class="navbar-text handLink badge rounded-pill text-bg-light animate__animated animate__fadeIn delay-2"
            data-bs-toggle="modal" data-bs-target="#loginModal">LogIn</span>


    </div>
  </nav>

  <modal-login/>
  <car-buy-modal/>
  <car-sell-modal/>

  <notification-modal/>

  <add-car-with-modal/>

</template>

<script setup>
import {onBeforeMount, ref} from "vue";
import {userState} from "@/composables/login";
import {Languages} from "@/composables/Languages";
import {useRouter} from 'vue-router'
import {Helpers} from '@/composables/helpers'

import ModalLogin from '@/components/login/ModalLogin.vue'
import NotificationModal from '@/components/inc/NotificationModal.vue'
import {BuySellCar} from '@/composables/BuySellCar'
import {Notifications} from '@/composables/profile/notification'
import CarBuyModal from '@/components/profile/cars/CarBuyModal.vue'
import CarSellModal from '@/components/profile/cars/CarSellModal.vue'
import AddCarWithModal from '@/components/profile/cars/AddCarWithModal.vue'

const {checkNotification, notification_count, chat_count} = Notifications()

const router = useRouter()

const {focusOnSearch} = Helpers()

const {languages, selected_language} = Languages()

const {loggedIn, LogOut, user_id, user_pic, user_type} = userState(); // user_type
//const {isMobApp} = AndroidUser();

// for fold out menu
const hamburger = ref();


const {activateBuyCarModal, activateSellCarModal, btn_sell} = BuySellCar()



onBeforeMount(() => {

  if (loggedIn.value) {

    checkNotification() // check instant

    checkNotifications()
  }
})

const refresh = ref()
const checkNotifications = () => {

  //checkNotification()

  refresh.value = setInterval(() => {

    //console.log('+')

      checkNotification()

  }, 30000)
}
</script>

<style scoped>
.delay-0 {
  animation-delay: 0.2s;
}

.delay-1 {
  animation-delay: 0.4s;
}

.delay-2 {
  animation-delay: 0.6s;
}

.delay-3 {
  animation-delay: 0.8s;
}

.delay-4 {
  animation-delay: 1.4s;
}


/***/

.img_profile {
  background-color: #FFFFFF;
  padding: 1px;
}
</style>


<template>



    <img v-if="you_like || active"
         @click="removeLike()"
         src="/img/icons/like_active.svg"
         class="ms-3 animate__animated animate__zoomIn handLink" width="22" height="22" :alt="'Liked ' + car.data.model.brand.name + ' ' + car.data.model.model + ' ' + count_likes + ' times'" />

    <img v-else src="/img/icons/like.svg"

         @click="active = true, like.data.user_id = user_id, like.data.comment_id = comment_id, storeLikeAsync()"
         class="ms-3 handLink animate__animated animate__zoomIn" width="22"  height="22" alt="Remove like" />

    <span class="badge rounded-pill text-bg-dark ms-2 animate__animated animate__fadeInLeft handLink"
          :key="count_likes" v-if="count_likes > 0"
          data-bs-toggle="tooltip" data-bs-placement="bottom" :data-bs-title="users_list">
      {{ count_likes }}
    </span>



<!--    {{users_list}}-->

<!--    {{users}}-->

<!--    <likes-users :users="users" />-->


</template>

<script setup>
import {ref, computed} from "vue";
import {Comments} from '@/composables/comment';
import {userState} from '@/composables/login';
import {Cars} from "@/composables/profile/cars";
//import LikesUsers from '@/components/offer/comment/LikesUsers.vue'


const active = ref(false)

const {car, getPrivateCarOffer, getCarOffer} = Cars() // get offer_id

const {user_id, user_type} = userState()

const {like, storeLike, deleteLike} = Comments()

const users_list = computed(() => {
  return props.users ? props.users.map(el => {
   return  el.users[0].name + (el.users[0].surname ? ' ' + el.users[0].surname : '')
  }).join(', ') : null
})

const storeLikeAsync = async () => {

  await storeLike()

  await dependOnUserType()
}

const dependOnUserType = async () => {

  if (user_type.value > 0) {

    await getPrivateCarOffer(car.data.id)

  } else {

    await getCarOffer(car.data.id)
  }
}

const removeLike = async () => {

  active.value = false

  if (props.you_like && props.you_like.id > 0) {

    await deleteLike(props.you_like.id);

    await dependOnUserType()
  }

}

const props = defineProps(['you_like', 'count_likes', 'comment_id', 'users'])
</script>
import {ref, reactive} from "vue";
import axios from "axios";

const signaturePad = ref()


const sign = ref()

const canvas = ref()

const start_copy_loa = ref(false)
const start_copy_nda = ref(false)

const document = reactive({
    data: {}
});

export const Sign = () => {

    const storeDocument = async () => {

        try {

            await axios.post('/api/sign-document', document.data)

        } catch (e: any) {

            console.log(e)
        }
    }

    const storeImgSign = async () => {

        //loading.value = true

        const form = new FormData();

        await signaturePad.value.toBlob( (blob: any) => {

            form.append('file', blob);

            //console.log(blob)

            axios.post('/api/sign-image',
                form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            ).then(response => {

                //console.log(response.data)

                sign.value = response.data

                //canvas.value.clear()
                //signaturePad.value = null

            });

        }, 'image/png'); // image/png - heavier

    }



    return {sign, storeDocument, document, signaturePad, storeImgSign, canvas, start_copy_loa, start_copy_nda}
}
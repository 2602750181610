<template>

  <!-- HTML or parse to <p> </p> -->

  <div v-if="hasHTMLTags()" v-html="description" class="mt-3" />

  <div v-else v-html="content" class="mt-3" />


</template>

<script setup>
import {computed} from "vue";

const props = defineProps(['description'])

const content = computed(() => {

  let  c = props.description.replace(/\n/g, '</p><p class="mt-3">')

  let parser = new DOMParser();
  let doc = parser.parseFromString(c, 'text/html');


  doc.querySelectorAll('p').forEach(paragraph => {
    // Check if the <p> tag is empty or contains only whitespace
    if (!paragraph.textContent.trim()) {
      // Remove the <p> tag if it's empty or whitespace-only
      paragraph.remove();
    }
  })

  return '<p class="fw-bold">' + doc.body.innerHTML
})

/**
 * Detect html: true / false
 *
 * @returns {boolean}
 */
const hasHTMLTags = () => {
  const regex = /<\/?[^>]+>/;
  return regex.test(props.description);
}
</script>
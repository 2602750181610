<template>

  <span v-if="!message.data.file" class="btn btn-outline-primary rounded-pill btn-sm mt-3" @click="file.click()">Add file</span>

  <a :href="'https://hotautomarket.com/storage/chat/' + message.data.file" target="_blank"
     v-if="message.data.file" class="text-primary fw-bold text-decoration-underline animate__animated animate__fadeInDown d-inline-block">
    <img src="/img/icons/attachment.svg" width="17" height="17">Added</a>
  <span v-if="message.data.file" @click="message.data.file = null; file.value = null" class="btn btn-outline-danger rounded-pill btn-sm fw-bold ms-3">X</span>


  <input type="file" class="d-none" ref="file" accept=".png, .jpg, .jpeg, .doc, .docx, .pdf" id="formFileImage" @change="storeFile()">

  <load-line v-if="loading" />

</template>

<script setup>
import {ref} from 'vue';
import {Chat} from '@/composables/chat';
import axios from "axios";
import LoadLine from '@/components/inc/LoadLine.vue'

const {message} = Chat()

const file = ref()
const loading = ref(false)

const storeFile = async () => {

  loading.value = true

  //let files = document.getElementById('formFilePof').files;

  const form = new FormData();
  form.append('file', file.value.files[0]); //files[0]

  await axios.post('/api/chat-image',
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
  ).then(response => {

    //console.log(response.data)

    message.data.file = response.data

    //canvas.value.clear()
    //signaturePad.value = null

  });

  loading.value = false

}
</script>
<template>

  <!-- Modal --> <!-- fade -->
  <div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">

    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-header">

          <div class="row m-0 p-0 container">

            <div class="col-10">

              <span class="modal-title fs-5 h1 float-start" id="loginModalLabel">Welcome</span>
<!--              <img src="/img/welcome.jpg" class="img-fluid ms-3" :key="login_tab" :class="!login_tab ? 'animate__animated animate__zoomIn flipHorizontal' : ''" width="200">-->

            </div>

            <div class="col-2 text-end">

            <span @click="join_tab = true, login_tab=false, forgotten_tab = false"
                  class="badge rounded-pill text-bg-primary handLink h3 animate__animated animate__zoomIn" v-if="login_tab">
              Join
            </span>
<!--              <small @click="join_tab = false, login_tab=true, forgotten_tab = false" class="text-primary handLink" v-else>-->
<!--                LogIn-->
<!--              </small>-->

              <!-- btn_close (hidden) -->
              <button type="button" ref="btn_close" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>

            </div>


          </div>


        </div>

        <div class="modal-body">

          <card-login/>
          <card-signup/>
          <forgotten-tab/>

        </div>

<!--        <div class="modal-footer">-->
<!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
<!--          <button type="button" class="btn btn-primary">Save changes</button>-->
<!--        </div>-->

      </div>

    </div>
  </div>


</template>

<script setup>
import {userState} from "@/composables/login";

import CardLogin from "@/components/login/CardLogin.vue";
import CardSignup from "@/components/login/CardSignup.vue";
import ForgottenTab from "@/components/login/forgotten/ForgottenTab.vue";

const {login_tab, join_tab, forgotten_tab, btn_close} = userState()
</script>

<style scoped>
.flipHorizontal{

  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
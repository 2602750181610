

const translate_customer_registration = {

    en: {
        customer_registration: 'Customer registration',
        customer_registration_info: '1) Click \'Check Availability\'  <br> 2) Sign LoI and NDA, upload Proof of Funds if needed <br> 3) Transferring the customer through a contract',
        brokers_commission_fee: 'Commission fee',
        btn_check_availability: 'Check <br>Availability',
        btn_car_reserved: 'Car <br>Reserved',
        btn_the_contract: 'The <br>Contract',
    },

    es: {

        customer_registration: 'Registro de cliente',
        customer_registration_info: '1) Haga clic en \'Verificar disponibilidad\' <br> 2) Firme LoI y NDA, cargue la Prueba de Fondos si es necesario <br> 3) Transferencia del cliente a través de un contrato',
        brokers_commission_fee: 'Tarifa de comisión del corredor',
        btn_check_availability: 'Verificar <br>disponibilidad',
        btn_car_reserved: 'Coche <br>reservado',
        btn_the_contract: 'El <br>Contrato',
    },

    de: {

        customer_registration: 'Kundenregistrierung',
        customer_registration_info: '1) Klicken Sie auf \'Verfügbarkeit prüfen\'  <br> 2) Unterzeichnen Sie das LOI und die Geheimhaltungsvereinbarung, laden Sie bei Bedarf den Kapitalnachweis hoch <br> 3) Überführung des Kunden durch einen Vertrag',
        brokers_commission_fee: 'Maklerprovisionsgebühr',
        btn_check_availability: 'Verfügbarkeit <br>prüfen',
        btn_car_reserved: 'Auto <br>reserviert',
        btn_the_contract: 'Der <br>Vertrag',
    },

    kz: {

        customer_registration: 'Мүшелерді тіркеу',
        customer_registration_info: '1) \'Мүмкіндігін тексеру\' түймесіне басып <br> 2) LoI және NDA-ны қолданып, төлемді растау үшін Мүмкіндіктерді жүктеу <br> 3) Жаттығу келісімі арқылы мүшелерді ауыстыру',
        brokers_commission_fee: 'Брокердің комиссиялық төлемі',
        btn_check_availability: 'Мүмкіндігін <br>тексеру',
        btn_car_reserved: 'Машина <br>сақталды',
        btn_the_contract: 'Келісім',
    },

    cn: {

        customer_registration: '客户注册',
        customer_registration_info: '1) 点击\'检查可用性\'  <br> 2) 签署意向书和保密协议，如有需要，请上传资金证明 <br> 3) 通过合同转移客户',
        brokers_commission_fee: '经纪人佣金费',
        btn_check_availability: '检查可用性',
        btn_car_reserved: '车辆已预订',
        btn_the_contract: '合同',
    },

    ru: {

        customer_registration: 'Регистрация клиента',
        customer_registration_info: '1) Нажмите \'Проверить наличие\' <br> 2) Подпишите LoI и NDA, загрузите подтверждение финансов, если необходимо <br> 3) Передача клиента через договор',
        brokers_commission_fee: 'Комиссия брокера',
        btn_check_availability: 'Проверить <br>наличие',
        btn_car_reserved: 'Автомобиль <br>зарезервирован',
        btn_the_contract: 'Договор',
    }
}

export const TranslateCustomerRegistration = () => {

    return{
        translate_customer_registration
    }
}
<template>


  <div class="row row-cols-1 row-cols-md-3 g-4 mb-4">
    <div class="col d-none d-md-block" :key="c.id" v-for="c in cars_hot_lease.data">


      <div class="card h-100 animate__animated animate__fadeIn">


        <!-- cars -->
        <RouterLink class="oswald" :to="'/offer/' + c.id">
          <img v-if="c.photo && c.photo.id" :src="'https://hotautomarket.com/storage/cars/'+ c.photo.pfile"
               class="card-img-top" :alt="'Leasing ' + c.model.model + (c.model_addition ? ' ' + c.model_addition : '' )" loading="lazy">
        </RouterLink>
        <div class="card-body">

          <div class="row">

            <div class="col-6">

              <h5 class="card-title oswald">{{ c.model.model }} <small class="text-muted oswald ms-1" v-if="c.model_addition">{{ c.model_addition }}</small></h5>

              <span class="oswald h3 d-block" style="color: #1FA2FF">{{ c.model.brand.name }}</span>


              <small class="text-danger" v-if="c.brand_new">Brand new</small>
              <span v-if="c.provider.zipcode && c.brand_new">, </span>
              <small v-if=" c.provider.zipcode"
                     class="text-muted">ZIP: {{ c.provider.zipcode }}</small>
            </div>

            <div class="col-6 text-end">


              <price-type :car="c" />

              <!--              <span class="text-danger oswald h4"> {{ c.price ? '$' + c.price.toLocaleString('en-US') : 'Price by request' }}</span>-->

              {{ c.pre_order ? ', pre order' : '' }}

              <br v-if="c.year">
              <span class="d-inline-block mt-2 text-muted oswald">
                Mileage: {{ c.mileage }}, &nbsp;
              </span>
              <span class="oswald d-inline-block mt-2 text-muted">
                {{ c.year ? c.year : '' }}
              </span>


            </div>

          </div>


          <!-- Comment -->
          <comment-item v-if="c.comment" :comment="c.comment" />
        </div>

        <div class="card-footer text-center sss" @click="router.push('/offer/' + c.id)">

          <RouterLink :to="'/offer/' + c.id" class="text-decoration-none text-light fw-bold">VIEW HOT LEASE</RouterLink>

          <!--          <small class="text-muted">Updated {{ moment(c.updated_at).fromNow() }}</small>-->
        </div>

      </div>


    </div>

  </div>

  <br>

</template>


<script setup>
import {Cars} from "@/composables/profile/cars";
import {useRouter} from "vue-router";
import PriceType from '@/components/main_page/PriceType.vue'
import CommentItem from "@/components/main_page/CommentItem.vue";


const {cars_hot_lease} = Cars()

const router = useRouter()
</script>

<style scoped>

.sss{
  background-image: linear-gradient(to right, #D31027 0%, #EA384D  51%, #D31027  100%);

}

.sss:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.vvv{
  background: #ECE9E6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
</style>
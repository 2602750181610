<template>

  <!--  {{car.data.documents}}-->

  <!-- Button trigger modal -->
  <button type="button" @click="start_copy_loa = true" :disabled="car.data.car_requested.document && car.data.car_requested.document.letter_of_intent_sign"
          class="btn rounded-pill animate__animated animate__zoomIn"
          :class=" car.data.car_requested.document && car.data.car_requested.document.letter_of_intent_sign ? 'btn-outline-primary' : 'btn-primary'"
          data-bs-toggle="modal" data-bs-target="#letterModal">
    Letter of Intent

    <span v-if="car.data.car_requested.document && car.data.car_requested.document.letter_of_intent_sign">
      ✅
    </span>

  </button>


  <!-- Letter of intent Modal -->
  <div class="modal fade" id="letterModal" tabindex="-1" aria-labelledby="letterModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="letterModalLabel">Letter of intent</h1>
          <button type="button" @click="start_copy_loa = false" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">

          <h5>Dear {{ car.data.provider.name }} {{ car.data.provider.surname }} {{ car.data.provider.business_name ? '(' + car.data.provider.business_name + ')' : '' }},</h5>


          <p>
            I intend to purchase the car from you
            <strong>
              {{ car.data.model.brand.name }} {{ car.data.model.model }} {{ car.data.model_addition }} {{ car.data.year }}
            </strong>.

          </p>

          <p>
            The agreed purchase price is ${{ car.data.price }}.
          </p>

          <p>
            I request the opportunity to inspect the vehicle before finalizing the purchase. (Inspection via video call is possible)
          </p>

          <p>
            Please provide all necessary documentation including clear title and vin number.
          </p>

          <p>
            Please confirm these terms and give me the necessary information at your earliest convenience.
          </p>
          <canvas-sign-letter/>

          <!--            <img v-if="sign" :src="sign" width="400" height="200">-->

        </div>

        <div class="modal-footer">
          <!--          <button type="button" class="btn btn-outline-secondary rounded-pill" data-bs-dismiss="modal">Close</button>-->


          <div class="container">

            <div class="d-grid gap-2">
              <button @click="save()"
                      type="button"
                      class="btn btn-primary rounded-pill btn-lg" data-bs-dismiss="modal">
                SIGN
              </button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>


</template>

<script setup>
import {Sign} from '@/composables/profile/sign';
//import SignCanvas from '@/components/sign/SignCanvas.vue';
import CanvasSignLetter from '@/components/offer/documents/CanvasSignLetter.vue';
import {userState} from "@/composables/login";
import {Cars} from "@/composables/profile/cars";


const {user_id, user_type} = userState()

const {sign, storeDocument, document, storeImgSign, start_copy_loa} = Sign()

const {car, getPrivateCarOffer, getCarOffer} = Cars()

const save = async () => {


  await storeImgSign()


  document.data.user_id = user_id.value
  document.data.user_seller_id = car.data.user_id
  document.data.car_id = car.data.id

  // document.data.car_id = car.data.id
  document.data.letter_of_intent_sign = sign.value
  document.data.bid_id = car.data.car_requested.id

  // console.log('document.data.bid_id')
  // console.log(document.data.bid_id)
  // console.log('sign.value')
  //console.log(sign.value)


  //await storeDocument()

  setTimeout(async () => {

    document.data.letter_of_intent_sign = await sign.value

    await storeDocument()

    // !
    await dependOnUserType()

    document.data.letter_of_intent_sign = null // clear!

    start_copy_loa.value = false

    sign.value = null

  }, 550)


}


const dependOnUserType = async () => {


  if (user_type.value > 0) {

    await getPrivateCarOffer(car.data.id)

  } else {

    await getCarOffer(car.data.id)
  }
}


// const saveToPng = () => {
//
//   sign.value = signaturePad.value.toDataURL('image/png');
// }
</script>
<template>

  <!--  Check user for profile filled-->

  <h5 class="text-danger mb-3 animate__animated animate__fadeInUp animate__delay-2s" v-if="!user.data.surname && user_id">
    To be eligible for a contract, you must complete your
    <RouterLink to="/profile">profile</RouterLink>
    properly.
  </h5>


</template>

<script setup>
import {onBeforeMount} from "vue";
import {User} from '@/composables/profile/user';
import {userState} from "@/composables/login";


//const profile_ready = ref(false)

const {user, getUser} = User()

const {user_id} = userState()

// const profile_ready = computed(() => {
//   return user.data.surname ? true : false
// })


onBeforeMount(() => {

  if (user_id.value) {
    getUser(user_id.value)
  }

})

</script>
import axios from "axios";
import {ref} from "vue";

const main = ref({})

export const MainPage = () => {

    const getMain = async () => {

        try {

            await axios.post('/api/main').then((res) => {

                main.value = res.data
            })


        } catch (e: any) {

            console.log(e.response.data.errors)
        }
    }

    return{
        getMain, main
    }
}
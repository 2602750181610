<template>

  <div class="row" v-if="login_tab">

    <div class="col-12 animate__animated animate__fadeIn">

      <div class="card">

        <div class="card-body">

          <div class="form-floating mt-2 mb-3">
            <input v-model.trim="input_login" type="email" class="form-control" placeholder="name@example.com">
            <label>Email</label>
          </div>
          <div class="form-floating mt-4">
            <input v-model.trim="input_password" type="password" class="form-control" placeholder="Password">
            <label>Password</label>
          </div>
        </div>


        <!--    errorLoginMessage -->
        <div class="d-grid mt-1 text-center animate__animated animate__fadeInDown" v-if="errorLoginMessage">

          <span class="text-danger d-block"> Error: check email or password </span>
          <small class="text-muted"> or reset password by Forgotten account </small>
        </div>

        <div class="d-grid m-3">


          <button class="btn btn-primary btn-lg rounded-pill" :disabled="checking_login" type="button" @click="checkLogin()">
            LogIn

            <div v-if="checking_login" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>


          </button>
        </div>

      </div>


      <div class="row mt-4">

        <div class="col-4">

          <button type="button" class="btn btn-outline-secondary rounded-pill" data-bs-dismiss="modal">Close</button>

        </div>

        <div class="col-4 text-center">

          <span class="btn btn-secondary rounded-pill handLink " @click="login_tab=false; join_tab=true">Sign Up</span>
          <br><small class="text-muted  d-inline-block float-start">for creating an account</small>

        </div>

        <div class="col-4 text-end text-muted">

          <small class="handLink" @click="login_tab=false; join_tab = false; forgotten_tab = true">
            Forgotten<br>
            account ?&nbsp;
          </small>


        </div>

      </div>


    </div>

  </div>


</template>

<script setup>
import {onMounted, ref} from "vue";
import {userState} from "@/composables/login";


const input_login = ref()
const input_password = ref()


const {login_tab, join_tab, checking_login, LogIn, loggedIn, forgotten_tab} = userState()


const errorLoginMessage = ref(false);

const checkLogin = async () => {

  errorLoginMessage.value = false

  await LogIn(input_login.value, input_password.value)

  if (loggedIn.value) {

    return;

  } else {

    setTimeout(() => {

      errorLoginMessage.value = true
    }, 500)

    setTimeout(() => {

      errorLoginMessage.value = false
    }, 6000)
  }

};


onMounted(() => {

  activateRegistration()

})

/**
 *  if invite exists
 */
const activateRegistration = () => {

  let hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

  if (hash) {

    //console.log(hash)

    login_tab.value = false
    join_tab.value = true
    forgotten_tab.value = false

    document.getElementById('login_modal').click()
  }
}


</script>
<template>

  <!-- Chat -->
  <span v-if="loggedIn && provider && provider.id && provider.id != user_id || loggedIn && provider != user_id" @click="openChat(provider)"
        class="btn rounded-pill animate__animated animate__zoomIn" :class="rename_chat ? 'btn-danger btn-sm animate__delay-1s' : 'btn-outline-danger'"
        data-bs-toggle="modal" data-bs-target="#staticBackdropChat">

          <img src="/img/icons/chat.svg" width="16" height="16" class="mb-1" v-if="!rename_chat">

        {{rename_chat ? rename_chat : 'CHAT'}}
      </span>
  <span v-else-if="!loggedIn" class="btn btn-outline-secondary rounded-pill mt-2"
        data-bs-toggle="modal" data-bs-target="#loginModal">
     <img src="/img/icons/chat.svg" width="16" height="16" class="mb-1">
        CHAT
      </span>


</template>

<script setup>
import {Chat} from '@/composables/chat';
import {userState} from '@/composables/login';
import {UserPublic} from "@/composables/profile/user_public";

const {user_public, getUser} = UserPublic()

const {from_id, getChat, scrollToBottom, chat_with} = Chat()

const {loggedIn, user_id} = userState()

const openChat = async (provider) => {



      if(provider && !provider.id){

        from_id.value = provider
        await getUser(provider)

        chat_with.data = user_public.data[0]

      } else {

        chat_with.data = provider
        from_id.value = provider.id
      }

  await getChat()

  scrollToBottom()

  setTimeout( () => {

    document.getElementById('chatControlTextarea').focus()
  }, 400)

}

defineProps(['provider', 'rename_chat'])
</script>
<template>

<!--  <teleport to="head">-->

<!--    <component :is="'script'" defer src="https://cdn.jsdelivr.net/npm/signature_pad@4.1.6/dist/signature_pad.umd.min.js"/>-->

<!--  </teleport>-->


  <div>



    Use the cursor to draw your signature <img src="/img/icons/sign.png" class="mb-1" width="25">

    <!--  <load-line v-if="!active" /> <br v-if="!active">-->

    <div class="wrapper">


      <!--      @mousemove="sign = signaturePad.toDataURL('image/png')"-->
      <canvas id="signature_pad" ref="sPad" @mousemove="signaturePad = sPad"
              width=400 height=200
              class="signature-pad handLink rounded border border-success"></canvas>
    </div>

    <small class="text-muted">Sign here</small>

    <span @click="canvas.clear();"
          class="badge rounded-pill text-bg-light handLink" style="margin-left: 300px">clear</span>


    <!--    <br>-->
    <!--    <button class="btn btn-primary rounded-pill mt-3" @click="saveToPng()" v-if="!sign">SIGN</button>-->


  </div>

  <!--  <img v-if="sign" :src="sign" width="400" height="200">-->



</template>

<script setup>

// https://jsfiddle.net/UziTech/xa91e4Lp/
import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import {Sign} from '@/composables/profile/sign'
//import LoadLine from '@/components/inc/LoadLine.vue'

const {signaturePad, start_copy_loa} = Sign() //, canvas



const sPad = ref(false)
const canvas = ref(false)
const copy_pad = ref()


// const saveToPng = () => {
//
//   sign.value = signaturePad.value ? signaturePad.value.toDataURL('image/png') : null;
// }


onMounted(() => {

  canvas.value = new SignaturePad(sPad.value, {
    //backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
    penColor: "rgb(66, 133, 244)"
  });

})

const copyPad = () => {

  copy_pad.value = setInterval(() => {

    signaturePad.value = sPad.value

    //console.log('+++ 8')

  }, 50)
}

onBeforeUnmount( () => {

  clearInterval(copy_pad.value)
})


watch(start_copy_loa, () => { // newVal, oldVal
      //console.log('Router:', newVal, oldVal)

      if (start_copy_loa.value){

        copyPad()

      } else {

        clearInterval(copy_pad.value)
      }

    } //, {deep: true, immediate: true}
);

</script>

<style scoped>

.wrapper {
  position: relative;
  width: 400px;
  height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  width: 400px;
  height: 200px;
  background-color: white;
}

</style>
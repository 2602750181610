import {ref} from "vue";
import axios from "axios";

const cart = ref([])
const inspection_order = ref({})
const request_sent = ref(false)

const stages = ref(0)

export const InspectionCart = () => {

    const storeOrder = async () => {

        request_sent.value = true

        try {

            await axios.post('/api/inspection-order', inspection_order.value)



            setTimeout( () => {

                request_sent.value = false

            }, 15000)

        } catch (e: any) {

            console.log(e)
        }


    }

    const items = [

        {
            id: 0,
            name: 'Base',
            price: 299
        },

        {
            id: 1,
            name: 'CarFax',
            price: 35
        },

        {
            id: 2,
            name: 'Negotiating on your behalf with the seller. 50% of the discount amount goes to us',
            price: 0
        }
    ]

    return {

        cart, items, inspection_order, request_sent, stages,

        storeOrder
    }

}
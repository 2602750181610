import {reactive, ref} from "vue";
import axios from "axios";

import {userState} from '@/composables/login';


const {createAccount, user_signup} = userState()

const subscribe_user = reactive({
    data: {}
});

const car_looking = reactive({
    data: {}
});


export const Subscribe = () => {

    const storeUser = async () => {

        try {

            await createAccount()


        } catch (e: any) {

            console.log(e)
        }
    }

    const storeCar = async () => {

        try {

            await axios.post('/api/subscribe', car_looking.data)

        } catch (e: any) {

            console.log(e)
        }
    }

    return {storeUser, subscribe_user, car_looking, storeCar}

}
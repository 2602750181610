<template>

  <!--Video Modal -->
  <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
    <div class="modal-dialog">

      <div class="modal-content">
        <div class="modal-header">

        <div class="row container m-0 p-0">

          <div class="col-11 p-0">


            <h1 v-if="!video_description" class="modal-title fs-5" id="videoModalLabel">How does it work:</h1>
            <h1 v-else :key="video" class="modal-title fs-5 oswald animate__animated animate__fadeInUp" id="videoModalLabel">{{video_description}}</h1>


          </div>

          <div class="col-1 text-end">

            <button @click="video=0; video_description=''" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
          </div>

        </div>





        </div>

        <div class="modal-body">

          <!-- Change video -->
          <select-video/>




<!--          width="320" height="240"-->
<!--          -->
          <video class="img-fluid mt-2 animate__animated animate__fadeIn" controls autoplay="true" v-if="video && !video_off">
            <source :src="'/img/video/'+video+'.mp4'" type="video/mp4">
<!--            <source src="movie.ogg" type="video/ogg">-->
            Your browser does not support the video tag.
          </video>



        </div>

        <div class="modal-footer">
          <button type="button"  @click="video=0; video_description=''" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
<!--          <button type="button" class="btn btn-primary">Save changes</button>-->
        </div>

      </div>
    </div>
  </div>

</template>

<script setup>
import {Video} from '@/composables/video';
import SelectVideo from '@/components/how_it_works/SelectVideo.vue';

const {video, video_description, video_off} = Video()

</script>
import {ref} from "vue";

const languages = {

    en: {
        login: 'LogIn',
        signup: 'SignUp',
        email: 'Email',
        password: 'Password',

        buy: 'Buy',
        sell: 'Sell',
        wanted: {
            title: 'Wanted',
            prefix: 'Distribution is not allowed in public media',
        },

        inquires: 'Inquires',
        my_cars: 'My cars',
        searching: 'Searching',
        chat: 'Chat',
        profile: 'Profile',

        off_market: 'Off Market',

        sign_out: 'Sign Out',

        subscribe: {
            title: 'Subscribe',
            prefix: 'never miss the best offer again!',
        },

        about_us: 'About Us',
        support: 'Support',
        credit_app: 'Credit App',

        year: 'Year',
        edit: 'Edit',
        update: 'Update',
        submit: 'Submit',
        save: 'Save',
        photo: 'Photo',
        video: 'Video',
        sold: 'Sold',
        on_hold: 'On hold',
        public_link: 'public link',
        private_listing: 'private listing',

        add_car_for_sale: 'Add Car For Sale',
        cars_inventory: 'Cars inventory',

        view_details: 'VIEW DETAILS',
        view_hot_deal: 'VIEW HOT DEAL',
        verified: 'Verified',
    },

    es: {
        login: 'Iniciar sesión',
        signup: 'Registrarse',
        email: 'Correo electrónico',
        password: 'Contraseña',

        buy: 'Comprar',
        sell: 'Vender',
        wanted: {
            title: 'Buscado',
            prefix: 'La distribución no está permitida en medios públicos',
        },

        inquires: 'Consultas',
        my_cars: 'Mis autos',
        searching: 'Buscando',
        chat: 'Chat',
        profile: 'Perfil',

        off_market: 'Fuera del mercado',

        sign_out: 'Cerrar sesión',

        subscribe: {
            title: 'Suscribirse',
            prefix: '¡nunca te pierdas la mejor oferta de nuevo!',
        },

        about_us: 'Sobre nosotros',
        support: 'Soporte',
        credit_app: 'Solicitud de crédito',

        year: 'Año',
        edit: 'Editar',
        update: 'Actualizar',
        submit: 'Enviar',
        save: 'Guardar',
        photo: 'Foto',
        video: 'Video',
        sold: 'Vendido',
        on_hold: 'En espera',
        public_link: 'Enlace público',
        private_listing: 'Listado privado',

        add_car_for_sale: 'Agregar Auto en Venta',
        cars_inventory: 'Inventario de autos',

        view_details: 'VER DETALLES',
        view_hot_deal: 'VER OFERTA CALIENTE',
        verified: 'Verificada',
    },

    de: {
        login: 'Anmelden',
        signup: 'Registrieren',
        email: 'E-Mail',
        password: 'Passwort',

        buy: 'Kaufen',
        sell: 'Verkaufen',
        wanted: {
            title: 'Gesucht',
            prefix: 'Verteilung ist in öffentlichen Medien nicht erlaubt',
        },

        inquires: 'Anfragen',
        my_cars: 'Meine Autos',
        searching: 'Suchen',
        chat: 'Chat',
        profile: 'Profil',

        off_market: 'Nicht auf dem Markt',

        sign_out: 'Abmelden',

        subscribe: {
            title: 'Abonnieren',
            prefix: 'Nie wieder das beste Angebot verpassen!',
        },

        about_us: 'Über uns',
        support: 'Support',
        credit_app: 'Kreditantrag',

        year: 'Jahr',
        edit: 'Bearbeiten',
        update: 'Aktualisieren',
        submit: 'Einreichen',
        save: 'Speichern',
        photo: 'Foto',
        video: 'Video',
        sold: 'Verkauft',
        on_hold: 'In Warteschleife',
        public_link: 'Öffentlicher Link',
        private_listing: 'Privates Inserat',

        add_car_for_sale: 'Auto zum Verkauf hinzufügen',
        cars_inventory: 'Fahrzeugbestand',

        view_details: 'DETAILS ANZEIGEN',
        view_hot_deal: 'HEIßES ANGEBOT ANZEIGEN',
        verified: 'Verifiziert',
    },

    kz: {
        login: 'Кіру',
        signup: 'Тіркелу',
        email: 'Электронды пошта',
        password: 'Құпия сөз',

        buy: 'Сатып алу',
        sell: 'Сату',
        wanted: {
            title: 'Көмек көтілетін',
            prefix: 'Қоғамдық медиада бөлісу қатынауланбайды',
        },

        inquires: 'Сұраулар',
        my_cars: 'Менің машиналарым',
        searching: 'Іздеу',
        chat: 'Сөйлесу',
        profile: 'Профиль',

        off_market: 'Сатылымдан шықты',

        sign_out: 'Шығу',

        subscribe: {
            title: 'Жазылу',
            prefix: 'Ең жақсы ұсынымдарды қамсыздандыру үшін!',
        },

        about_us: 'Біз туралы',
        support: 'Көмек',
        credit_app: 'Кредитті өтінім',

        year: 'Жыл',
        edit: 'Өңдеу',
        update: 'Жаңарту',
        submit: 'Жіберу',
        save: 'Сақтау',
        photo: 'Фото',
        video: 'Бейне',
        sold: 'Сатылды',
        on_hold: 'Тығыздан жатты',
        public_link: 'Қоғамдық сілтеме',
        private_listing: 'Жеке тізім',

        add_car_for_sale: 'Машина сату үшін қосу',
        cars_inventory: 'Машиналар инвентаризациясы',

        view_details: 'МӨЛШЕРІНІ КӨРУ',
        view_hot_deal: 'ЖАНГЫ ДЕГЕМІНІ КӨРУ',
        verified: 'Тексерілді',
    },

    cn: {

        login: '登录',
        signup: '注册',
        email: '电子邮件',
        password: '密码',

        buy: '购买',
        sell: '出售',
        wanted: {
            title: '求购',
            prefix: '不允许在公共媒体上分发',
        },

        inquires: '查询',
        my_cars: '我的车辆',
        searching: '搜索',
        chat: '聊天',
        profile: '个人资料',

        off_market: '下架',

        sign_out: '退出',

        subscribe: {
            title: '订阅',
            prefix: '再也不错过最佳优惠！',
        },

        about_us: '关于我们',
        support: '支持',
        credit_app: '信用申请',

        year: '年份',
        edit: '编辑',
        update: '更新',
        submit: '提交',
        save: '保存',
        photo: '照片',
        video: '视频',
        sold: '已售',
        on_hold: '暂停',
        public_link: '公共链接',
        private_listing: '私密列表',

        add_car_for_sale: '添加车辆出售',
        cars_inventory: '车辆库存',

        view_details: '查看详情',
        view_hot_deal: '查看热门交易',
        verified: '已验证',
    },

    ru: {
        login: 'Войти',
        signup: 'Регистрация',
        email: 'E-mail',
        password: 'Пароль',

        buy: 'Купить',
        sell: 'Продать',
        wanted: {
            title: 'Розыск',
            prefix: 'Размещение запрещено в публичных СМИ',
        },

        inquires: 'Запросы',
        my_cars: 'Мои автомобили',
        searching: 'Поиск',
        chat: 'Чат',
        profile: 'Профаил',

        off_market: 'Закрытый маркет',

        sign_out: 'Выйти',

        subscribe: {
            title: 'Подписаться',
            prefix: 'Уведомления на лучшее предложения!',
        },

        about_us: 'О нас',
        support: 'Поддержка',
        credit_app: 'Заявка на кредит',

        year: 'Год',
        edit: 'Редактировать',
        update: 'Обновить',
        submit: 'Отправить',
        save: 'Сохранить',
        photo: 'Фото',
        video: 'Видео',
        sold: 'Продано',
        on_hold: 'скрыть',
        public_link: 'линк',
        private_listing: 'Частное объявление',

        add_car_for_sale: 'Добавить автомобиль на продажу',
        cars_inventory: 'Инвентарь автомобилей',

        view_details: 'ПОДРОБНЕЕ',
        view_hot_deal: 'СУПЕР ПРЕДЛОЖЕНИЯ',
        verified: 'Верифицирован',
    }

}

const car_languages = {
    en : {
        add_car: {
            add_car: 'Add Car',
            header: 'Your request will be evaluated by market professionals',
        },
        sell_car: 'Sell Car',
        private_listing: 'Private listing (off market), only for business users',
        select_brand: 'Select brand',
        select_model: 'Select model',
        model_additional: 'Addition about model (optional)',
        year: 'Year',
        color_exterior: 'Color exterior',
        color_interior: 'Color interior',
        mileage: 'Mileage',
        price_up_to: 'Price up to',
        price: 'Price',
        brand_new: 'Brand New',
        requirements: 'Some requirements for the car should be clarified',
        cash: 'Cash',
        leasing: 'Leasing',
        finance: 'Finance',
        trade_in: 'Trade in',
        price_by_request: 'Price by request',
        pre_order: 'Pre order',
        located: 'Located',
        documents_required: 'Documents required',
        proof_of_funds: 'Proof of Funds',
        letter_of_intent: 'Letter of intent',
        provide_information: 'provide information about commissions and conditions for our sellers team',
        brokers_fee: 'Brokers Fee',
        optional: 'Optional: enter special condition',
        description: 'Description',
        how_would_you_like_to_sell: 'How would you like to sell this car',
        private_addition: 'Private addition (visible only for you)',
        price_MSRP_is_must: 'Price or MSRP price is must',
        download_pdf: 'Download PDF',
        build_sheet: 'Build sheet',
    },

    es: {
        add_car: {
            add_car: 'Agregar coche',
            header: 'Envía un coche y más de 1000 concesionarios te ayudarán a encontrar la mejor oferta',
        },
        sell_car: 'Vender coche',
        private_listing: 'Listado privado (fuera del mercado), solo para usuarios comerciales',
        select_brand: 'Seleccionar marca',
        select_model: 'Seleccionar modelo',
        model_additional: 'Información adicional sobre el modelo (opcional)',
        year: 'Año',
        color_exterior: 'Color exterior',
        color_interior: 'Color interior',
        mileage: 'Kilometraje',
        price_up_to: 'Precio hasta',
        price: 'Precio',
        brand_new: 'Nuevo',
        requirements: 'Algunos requisitos para el coche deben aclararse',
        cash: 'Efectivo',
        leasing: 'Arrendamiento',
        finance: 'Financiamiento',
        trade_in: 'Intercambio',
        price_by_request: 'Precio a petición',
        pre_order: 'Preordenar',
        located: 'Ubicado',
        documents_required: 'Documentos requeridos',
        proof_of_funds: 'Prueba de fondos',
        letter_of_intent: 'Carta de intención',
        provide_information: 'proporcionar información sobre comisiones y condiciones para nuestro equipo de vendedores',
        brokers_fee: 'Tarifa de corredores',
        optional: 'Opcional: ingresar condiciones especiales',
        description: 'Descripción',
        how_would_you_like_to_sell: '¿Cómo te gustaría vender este coche?',
        private_addition: 'Adición privada (visible solo para ti)',
        price_MSRP_is_must: 'El precio o el precio de MSRP es obligatorio',
        download_pdf: 'Descargar PDF',
        build_sheet: 'Hoja de construcción',
    },

    de: {
        add_car: {
            add_car: 'Auto hinzufügen',
            header: 'Geben Sie ein Auto ein, und mehr als 1000 Händler helfen Ihnen, das beste Angebot zu finden',
        },
        sell_car: 'Auto verkaufen',
        private_listing: 'Privates Inserat (nicht öffentlich), nur für Geschäftskunden',
        select_brand: 'Marke auswählen',
        select_model: 'Modell auswählen',
        model_additional: 'Zusätzliche Informationen zum Modell (optional)',
        year: 'Jahr',
        color_exterior: 'Außenfarbe',
        color_interior: 'Innenfarbe',
        mileage: 'Kilometerstand',
        price_up_to: 'Preis bis zu',
        price: 'Preis',
        brand_new: 'Brandneu',
        requirements: 'Einige Anforderungen für das Auto sollten geklärt werden',
        cash: 'Bargeld',
        leasing: 'Leasing',
        finance: 'Finanzierung',
        trade_in: 'Inzahlungnahme',
        price_by_request: 'Preis auf Anfrage',
        pre_order: 'Vorbestellung',
        located: 'Standort',
        documents_required: 'Erforderliche Unterlagen',
        proof_of_funds: 'Nachweis der Mittel',
        letter_of_intent: 'Absichtserklärung',
        provide_information: 'Informationen zu Provisionen und Bedingungen für unser Verkäuferteam bereitstellen',
        brokers_fee: 'Maklergebühr',
        optional: 'Optional: Besondere Bedingungen eingeben',
        description: 'Beschreibung',
        how_would_you_like_to_sell: 'Wie möchten Sie dieses Auto verkaufen',
        private_addition: 'Private Ergänzung (nur für Sie sichtbar)',
        price_MSRP_is_must: 'Preis oder MSRP-Preis ist erforderlich',
        download_pdf: 'PDF Herunterladen',
        build_sheet: 'Baublatt',
    },

    kz: {
        add_car: {
            add_car: 'Автокөлікті қосу',
            header: 'Автокөлік қосылған және 1000-дан астам дилерлер сізге ең жақсы келісімді табу үшін көмек көрсетеді',
        },
        sell_car: 'Автокөлікті сату',
        private_listing: 'Жеке тізім (базардан тыс жаттығу), тек бизнес пайдаланушылар үшін',
        select_brand: 'Брендты таңдау',
        select_model: 'Модельді таңдау',
        model_additional: 'Модель туралы қосымша (міндетті емес)',
        year: 'Жылы',
        color_exterior: 'Сыртқы түсі',
        color_interior: 'Ішкі түсі',
        mileage: 'Майлж',
        price_up_to: 'Дейінгі баға',
        price: 'Баға',
        brand_new: 'Су жаңа',
        requirements: 'Автокөлік үшін кейбір талаптар жайлы',
        cash: 'Қолма-қол',
        leasing: 'Лизинг',
        finance: 'Финанс',
        trade_in: 'Айырбастау',
        price_by_request: 'Сұраныс бойынша баға',
        pre_order: 'Алдын-ала тапсырыс беру',
        located: 'Орналасқан жері',
        documents_required: 'Құжаттар бойынша талап',
        proof_of_funds: 'Қаржы анықтамасы',
        letter_of_intent: 'Сұраныс хаттамасы',
        provide_information: 'сатушы тобымыздың комиссиялар және шарттар туралы мәлімет беру',
        brokers_fee: 'Брокерлік алым',
        optional: 'Қосымша: арнайы шартты енгізу',
        description: 'Description',
        how_would_you_like_to_sell: 'Бұл автокөлікті қалай сатқыңыз келеді',
        private_addition: 'Жеке қосымша (тек сіз үшін көрінеді)',
        price_MSRP_is_must: 'Баға немесе MSRP бағасы міндетті',
        download_pdf: 'PDF файлын жүктеп алыңыз',
        build_sheet: 'Құрастыру парағы',
    },

    cn: {
        add_car: {
            add_car: '添加汽车',
            header: '提交一辆汽车，超过1000家经销商将帮助您找到最佳交易',
        },
        sell_car: '卖车',
        private_listing: '私人清单（脱销售市场），仅限商业用户',
        select_brand: '选择品牌',
        select_model: '选择型号',
        model_additional: '关于型号的附加信息（可选）',
        year: '年份',
        color_exterior: '外观颜色',
        color_interior: '内饰颜色',
        mileage: '里程',
        price_up_to: '价格最高',
        price: '价格',
        brand_new: '全新',
        requirements: '有关汽车的一些要求需要澄清',
        cash: '现金',
        leasing: '租赁',
        finance: '融资',
        trade_in: '置换',
        price_by_request: '价格待定',
        pre_order: '预定',
        located: '所在地',
        documents_required: '所需文件',
        proof_of_funds: '资金证明',
        letter_of_intent: '意向书',
        provide_information: '提供关于我们销售团队佣金和条件的信息',
        brokers_fee: '经纪费',
        optional: '可选：输入特殊条件',
        description: '描述',
        how_would_you_like_to_sell: '您想如何出售这辆车',
        private_addition: '私人附加信息（仅您可见）',
        price_MSRP_is_must: '价格或建议零售价是必须的',
        download_pdf: '下载PDF',
        build_sheet: '构建表',
    },

    ru: {
        add_car: {
            add_car: 'Добавить автомобиль',
            header: 'Подайте заявку на автомобиль, и более 1000 дилеров помогут вам найти лучшее предложение',
        },
        sell_car: 'Продать автомобиль',
        private_listing: 'Частное предложение (вне рынка), только для бизнес-пользователей',
        select_brand: 'Выберите марку',
        select_model: 'Выберите модель',
        model_additional: 'Дополнение о модели (по желанию)',
        year: 'Год',
        color_exterior: 'Цвет кузова',
        color_interior: 'Цвет салона',
        mileage: 'Пробег',
        price_up_to: 'Цена до',
        price: 'Цена',
        brand_new: 'Совершенно новый',
        requirements: 'Некоторые требования к автомобилю следует уточнить',
        cash: 'Наличные',
        leasing: 'Лизинг',
        finance: 'Финансирование',
        trade_in: 'Обмен',
        price_by_request: 'Цена по запросу',
        pre_order: 'Предзаказ',
        located: 'Местоположение',
        documents_required: 'Необходимые документы',
        proof_of_funds: 'Подтверждение финансовых средств',
        letter_of_intent: 'Письмо о намерениях',
        provide_information: 'предоставьте информацию о комиссиях и условиях для нашей команды продавцов',
        brokers_fee: 'Брокерская комиссия',
        optional: 'Дополнительно: введите специальное условие',
        description: 'Описание',
        how_would_you_like_to_sell: 'Как бы вы хотели продать этот автомобиль',
        private_addition: 'Частное дополнение (видимо только для вас)',
        price_MSRP_is_must: 'Цена или MSRP цена обязательны',
        download_pdf: 'Скачать PDF',
        build_sheet: 'Комплектация',
    }


}

const set_language = ref(localStorage.getItem('lang'));
const selected_language = ref(set_language.value ? set_language.value : 'en');

export const Languages = () => {

    const setLanguage = (l: string) => {

        selected_language.value = l
        localStorage.setItem('lang', l);

    }

    return {
        languages, setLanguage, selected_language, car_languages
    }

}





const translate_main_page = {


    en: {       // Easy to sell cars and share commissions
        title: 'Best Offers From Verified Brokers And Owners',

        how_it_works: 'How It Works',

        block_one: {
            title: 'Faster car sales',
            description: 'Within the brokers community you can find cars faster across the globe',
            btn: 'FREE BROKERS'
        },

        block_two: {
            title: 'Protection',
            description: 'Creating legit contracts is easy with our service, also ESCROW ensures safety and security in transactions',
        },


        block_three: {
            title: 'Hot Board',
            description: 'Dealers will instantly see what car you are looking for',
        },

        block_four: {
            title: 'Anywhere in the world',
            description: 'Air logistics are the best for club members from $16k',
        },

        new_brokers: 'New brokers',

        hot_offers: 'HOT OFFERS',

        search: {
            title: 'Search',
            header: 'Find The Best Offer',
            prefix: 'buy an exclusive car fast',
        },

    },

    es: {

        title: '¡Forma más rápida de encontrar autos raros!',

        how_it_works: 'Cómo Funciona',

        block_one: {
            title: 'Ventas de autos más rápidas',
            description: 'Dentro de la comunidad de corredores, puedes encontrar autos rápidamente en todo el mundo',
            btn: 'CORREDORES GRATUITOS'
        },

        block_two: {
            title: 'Protección',
            description: 'Crear contratos legítimos es fácil con nuestro servicio, además, ESCROW asegura la seguridad en las transacciones',
        },

        block_three: {
            title: 'Tablero Caliente',
            description: 'Los distribuidores verán instantáneamente qué auto estás buscando',
        },

        block_four: {
            title: 'En cualquier parte del mundo',
            description: 'La logística aérea es la mejor para los miembros del club a partir de $16,000',
        },

        new_brokers: 'Nuevos corredores',

        hot_offers: 'OFERTAS CALIENTES',

        search: {
            title: 'Buscar',
            header: 'Encuentra la Mejor Oferta',
            prefix: 'compra un auto exclusivo rápidamente',
        },

    },

    de: {

        title: 'Schnellere Möglichkeit, seltene Autos zu finden!',

        how_it_works: 'Wie es funktioniert',

        block_one: {
            title: 'Schnellere Autoverkäufe',
            description: 'Innerhalb der Maklergemeinschaft können Sie Autos weltweit schneller finden',
            btn: 'KOSTENLOSE MAKLER'
        },

        block_two: {
            title: 'Schutz',
            description: 'Die Erstellung legitimer Verträge ist einfach mit unserem Service, auch TREUHAND gewährleistet Sicherheit bei Transaktionen',
        },

        block_three: {
            title: 'Hot Board',
            description: 'Händler werden sofort sehen, welches Auto Sie suchen',
        },

        block_four: {
            title: 'Überall auf der Welt',
            description: 'Luftlogistik ist für Clubmitglieder ab 16.000 $ am besten',
        },

        new_brokers: 'Neue Makler',

        hot_offers: 'HEIßE ANGEBOTE',

        search: {
            title: 'Suche',
            header: 'Finden Sie das beste Angebot',
            prefix: 'Kaufen Sie schnell ein exklusives Auto',
        },

    },

    kz: {

        title: 'Ерекше автомобильді табу үшін тез жол!',

        how_it_works: 'Қалай жұмыс істейді',

        block_one: {
            title: 'Ерекше колесалық сату',
            description: 'Брокерлер көмекшілігімен сіз көптеген жерден тезірек автомобильдерді таба аласыз',
            btn: 'ТЕГІН БРОКЕРЛАР'
        },

        block_two: {
            title: 'Қорғау',
            description: 'Біздің қызмет арқылы законды құқықтарды жасау оңай, қамақ жасау қамтамасында еміндік және қауіпсіздік сақталады',
        },

        block_three: {
            title: 'Жылу досқа',
            description: 'Дилерлер сіздің іздеген автомобильді тездікке көре алады',
        },

        block_four: {
            title: 'Әлемдегі кезде дайын',
            description: 'Авиа логистиканың клуб мүшесі үшін $16 мыңнан бастап ең жақсы',
        },

        new_brokers: 'Жаңа брокерлар',

        hot_offers: 'ЖАНГЫ ТАҒАМДАР',

        search: {
            title: 'Іздеу',
            header: 'Ең жақсы қолжетімді пропозицияны табыңыз',
            prefix: 'тезірек ерекше автомобиль сатып алу',
        },


    },

    cn: {
        title: '寻找稀有车辆的更快方式!',

        how_it_works: '工作原理',

        block_one: {
            title: '更快的汽车销售',
            description: '在经纪人社区内，您可以更快地找到全球范围内的汽车',
            btn: '免费经纪人'
        },

        block_two: {
            title: '保护',
            description: '通过我们的服务轻松创建合法合同，ESCROW还确保交易的安全性和安全性',
        },

        block_three: {
            title: '热门车辆',
            description: '经销商将立即看到您寻找的车辆',
        },

        block_four: {
            title: '世界任何地方',
            description: '航空物流是俱乐部会员的最佳选择，价格从16,000美元起',
        },

        new_brokers: '新经纪人',

        hot_offers: '热门优惠',

        search: {
            title: '搜索',
            header: '找到最佳报价',
            prefix: '快速购买独特的汽车',
        },

    },

    ru: {

        title: 'Быстрый способ найти редкие автомобили!',

        how_it_works: 'Как это работает',

        block_one: {
            title: 'Быстрая продажа автомобилей',
            description: 'В рамках сообщества брокеров вы можете быстрее находить автомобили по всему миру',
            btn: 'БРОКЕРЫ'
        },

        block_two: {
            title: 'Защита',
            description: 'Создание законных контрактов легко с нашим сервисом, также ЭСКРОУ обеспечивает безопасность сделок',
        },

        block_three: {
            title: 'Горячая доска',
            description: 'Дилеры мгновенно увидят, какой автомобиль вы ищете',
        },

        block_four: {
            title: 'Куда угодно в мире',
            description: 'Воздушная логистика - лучший выбор для членов клуба от 16 тыс. долларов',
        },

        new_brokers: 'Новые брокеры',

        hot_offers: 'ГОРЯЧИЕ ПРЕДЛОЖЕНИЯ',

        search: {
            title: 'Поиск',
            header: 'Найдите лучшее предложение',
            prefix: 'купите эксклюзивный автомобиль быстро',
        },

    }
}

export const TranslateMainPage = () => {

    return{
        translate_main_page
    }
}
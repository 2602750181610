<template>

  <div class="row mb-3">

    <div class="col-6">

      <small>{{car_languages[selected_language].select_brand}}</small>  <sup class="text-danger">*</sup>
      <select class="form-select" @change="filterModels" v-model="selected_brand" aria-label="Default select example">

<!--        <option selected>Select brand</option>-->

        <option :value="b.id" :key="b.id" v-for="b in cars_models.data">
          {{b.name}}
        </option>

      </select>

    </div>

    <div class="col-6">

<!--      {{models}}-->

      <small v-if="models[0]">{{car_languages[selected_language].select_model}}</small>  <sup v-if="models[0]" class="text-danger">*</sup>
      <select class="form-select animate__animated animate__fadeInUp" v-model="car.data.model_id" v-if="models[0]" aria-label="Default select example">

<!--        <option selected>Select model</option>-->

        <option :value="m.id" :key="m.id" v-for="m in models[0].models">
          {{m.model}}
        </option>

      </select>

    </div>

  </div>



</template>

<script setup>
import {ref} from "vue";
import {Cars} from "@/composables/profile/cars";
import {Languages} from "@/composables/Languages";

const {car_languages, selected_language} = Languages()

const {car, cars_models} = Cars()

const selected_brand = ref()
const models = ref([])


const filterModels = e => {

  //console.log(e.target.value)

  models.value = cars_models.data.filter( some => some.id == e.target.value) //

    // models.value = cars_models.data.map( m => {
    //
    //   console.log(m)
    // }) //.filter( some => some.city == 'London' )
}
</script>
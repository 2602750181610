import {ref} from "vue";

const video = ref(0);
const video_description = ref('');
const video_off = ref(false);

export const Video = () => {


    return {video, Video, video_description, video_off}
}
<template>

  <span class="h5 text-danger  d-block">
    {{translate_main_page[selected_language].new_brokers}}</span>

  <!--  <div class="container text-center animate__animated animate__fadeInDown" v-if="activate">-->

  <!--    <div class="row justify-content-center text-center mb-4">-->

  <!--      <div class="col-12 mb-3"></div>-->

  <!--      <div class="col-2" :key="d.id" v-for="d in new_dealers.data">-->

  <!--        <RouterLink class="text-decoration-none text-secondary" :to="'/'+d.id">-->
  <!--          <img class="rounded-circle img-fluid" :alt="'Auto dealer ' + d.name" style="max-width: 55px" :src="'https://hotautomarket.com/storage/photo/' + d.id + '.jpg'">-->
  <!--        </RouterLink>-->

  <!--      </div>-->

  <!--    </div>-->
  <!--  </div>-->

  <div class="wrap">
    <div class="items-wrap">
      <div class="items marquee">
        <div class="item text-center" :key="d.id" v-for="d in new_dealers.data">

                  <RouterLink class="text-decoration-none text-secondary" :to="'/'+d.id">
                    <img class="rounded-circle img-fluid animate__animated animate__zoomIn" :key="d.id" :alt="'Auto dealer ' + d.name" style="max-width: 65px" :src="'https://hotautomarket.com/storage/photo/' + d.id + '.jpg'">
                    <!-- <br>{{d.name}}-->
                  </RouterLink>
        </div>

      </div>
      <div aria-hidden="true" class="items marquee">
        <div class="item text-center" :key="d.id" v-for="d in new_dealers.data">

          <RouterLink class="text-decoration-none text-secondary" :to="'/'+d.id">
            <img class="rounded-circle img-fluid" :alt="'Auto dealer ' + d.name" style="max-width: 65px" :src="'https://hotautomarket.com/storage/photo/' + d.id + '.jpg'">
            <!-- <br>{{d.name}}-->
          </RouterLink>
        </div>
      </div>
    </div>


  </div>

  <span class="d-block border-bottom border-dark-subtle"></span>

</template>

<script setup>
import {onMounted, ref} from "vue";
import {Cars} from "@/composables/profile/cars";
import {Languages} from '@/composables/Languages';
import {TranslateMainPage} from '@/composables/translate/main_page';

const {selected_language} = Languages()
const {translate_main_page} = TranslateMainPage()

const {new_dealers} = Cars();

const activate = ref(false)

onMounted(() => {

  setTimeout(() => {

    activate.value = true

  }, 2000)

})
</script>

<!-- https://codepen.io/fe-nix/pen/eYMPwQp -->
<style scoped>

.wrap {
  max-width: 100%;
  margin: auto;
  padding: 0px;
}
.items-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 20px;
}
.items-wrap:before,
.items-wrap:after {
  content: "";
  height: 100%;
  top: 0;
  width: 10%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.items-wrap:before {
  left: 0;

}
.items-wrap:after {
  right: 0;
}
.items {
  flex-shrink: 0;
  display: flex;
  gap: 20px;
}
.item {

  flex: 0 0 auto;
  width: 100px;
  height: 100px;

  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*font-size: 25px;
  font-weight: bold;
   */
  color: #fff;
  margin: 10px 0;
  transition: all 0.1s ease-in-out;
}
.item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.item:before {

}

.marquee {
  animation: scroll 70s linear infinite;
}
.reverce {
  animation-direction: reverse;
}
.items-wrap:hover .marquee {
  animation-play-state: paused;
}

.perfscan {
  margin: 20px 0;
  text-align: center;
  bottom: 0;
  background: #fff;
  padding: 5px;
}
.perfscan hr {
  border: solid #999;
  border-width: 1px 0 0 0;
  max-width: 50%;
  margin: 0 auto 20px;
}
.perfscan a {
  color: #000;
  font-weight: bold;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}

</style>
<template>

  <div v-if="pin == false">
    <div class="input-group input-group-lg">

                <span class="input-group-text">
                  pin
                </span>

      <input type="text" class="form-control" placeholder="enter" @input="checkPin()" v-model="pin_input">

    </div>
    <span class="float-end text-danger">Enter the pin from the email. Check junk if needed</span>
  </div>

  <!--  Pin correct, now enter new password -->
  <new-password-form v-else/>


</template>

<script setup>
import {Recovery} from "@/composables/login_forgotten";

import NewPasswordForm from '@/components/login/forgotten/NewPasswordForm.vue'

const {pin_input, pin, checkPin} = Recovery(); // getUser,

</script>
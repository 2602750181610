<template>

  <!--    {{ car_copied }}-->

  <div class="container">

    <!--    Will you buy it by-->
    <!--    <select class="form-select form-select-lg animate__animated animate__fadeIn" aria-label="Default select example">-->

    <!--      <option value="1" v-if="car_copied.data.buy_cash">Cash</option>-->
    <!--      <option value="2" v-if="car_copied.data.buy_credit">Finance</option>-->
    <!--      <option value="3" v-if="car_copied.data.buy_leasing">Leasing</option>-->
    <!--    </select>-->


    <small class="text-muted">Negotiate the price (optional)</small>
    <textarea class="form-control" id="additionFormControlTextarea"
              v-model="car_copied.data.question" rows="2"></textarea>


    <div class="row mb-4">

      <div class="col-6 mt-3" v-if="car.data.buy_cash">

        <div class="form-check form-switch">
          <input class="form-check-input" @click="car_copied.data.buy_cash = !car_copied.data.buy_cash" type="checkbox" role="switch" id="flexSwitchCheckRequestCash">
          <label class="form-check-label" for="flexSwitchCheckRequestCash">Buy cash</label>
        </div>

      </div>

      <div class="col-6 mt-3" v-if="car.data.buy_leasing">

        <div class="form-check form-switch">
          <input class="form-check-input" @click="car_copied.data.buy_leasing = !car_copied.data.buy_leasing" type="checkbox" role="switch" id="flexSwitchCheckRequestLeasing">
          <label class="form-check-label" for="flexSwitchCheckRequestLeasing">Leasing</label>
        </div>

      </div>


      <div class="col-6 mt-3" v-if="car.data.buy_credit">

        <div class="form-check form-switch">
          <input class="form-check-input" @click="car_copied.data.buy_credit = !car_copied.data.buy_credit" type="checkbox" role="switch" id="flexSwitchCheckRequestFinance">
          <label class="form-check-label" for="flexSwitchCheckRequestFinance">Finance</label>
        </div>

      </div>




      <div class="col-6 mt-3" v-if="car.data.trade_in">

        <div class="form-check form-switch">
          <input class="form-check-input" @click="car_copied.data.trade_in = !car_copied.data.trade_in" type="checkbox" role="switch" id="flexSwitchCheckRequestTradeIn">
          <label class="form-check-label" for="flexSwitchCheckRequestTradeIn">Trade in</label>
        </div>

      </div>


    </div>



    <div class="row animate__animated animate__fadeInDown" v-if="car_copied.data.trade_in">

      <div class="col-6" >

        <div class="input-group mb-3">
          <span class="input-group-text" id="customer-addon-vin">VIN</span>
          <input type="text" v-model="car_copied.data.customer_vin" class="form-control" placeholder="number" aria-label="Username" aria-describedby="customer-addon-vin">
        </div>


      </div>

      <div class="col-6">

        <div class="input-group mb-3">
          <span class="input-group-text" id="customer-addon-miles">Miles</span>
          <input type="text" v-model="car_copied.data.customer_miles" class="form-control" aria-label="Username" aria-describedby="customer-addon-miles">
        </div>

      </div>

    </div>



    <!--    <div class="form-check form-switch mt-3">-->
    <!--      <input class="form-check-input" v-model="car_copied.data.pass_all_contacts" :checked="car_copied.data.pass_all_contacts" @click="car_copied.data.pass_all_contacts = !car_copied.data.pass_all_contacts" type="checkbox" role="switch" id="flexSwitchSendFullContactDetails" checked>-->
    <!--      <label class="form-check-label" for="flexSwitchSendFullContactDetails">-->

    <!--        {{car_copied.data.pass_all_contacts ? 'Send all my contact detail' : 'Send email and name'}}-->

    <!--      </label>-->
    <!--    </div>-->


    <div class="d-grid gap-2 mt-3 text-center">
      <button class="btn btn-grad-request rounded-pill btn-lg oswald" type="button" data-bs-dismiss="modal" @click="sendRequest()">

        <span v-if="car.data.buy_leasing && car.data.lease_term_months">
          START QUOTE
        </span>
        <span v-else>
          REQUEST
        </span>


        <!--        <span v-if="car.data.lease_term_months">$500</span>-->

      </button>

      <span class="text-muted" v-if="user_type == 1 || user_type == 2"> The customer can be registered after the availability of the car has been confirmed </span>

      <!--      <span class="fw-bold" v-if="car.data.lease_term_months">-->
      <!--        An amount equal to the returnable reserves will be included in the down payment.-->
      <!--      </span>-->


    </div>

  </div>

</template>

<script setup>
import {onBeforeMount, reactive} from "vue";
import {Cars} from "@/composables/profile/cars";
import {userState} from "@/composables/login";
import {useRouter} from "vue-router";


const router = useRouter()


const car_copied = reactive({
  data: {}
});

const {user_id, user_type} = userState()

const {car, storeBid} = Cars() // , stored_bid

//const props = defineProps(['car'])


const sendRequest = async () => {

  //let car_copied = car_copied

  car_copied.data.car_id = car.data.id
  car_copied.data.user_id = user_id.value

  //car.data = car_copied
  car.data = Object.assign({}, car_copied.data)


  await storeBid(car.data.id)

  //await getCarOffer(car_copied.data.id)

  //car_copied = car_copied;

  //console.log(car_copied.data.id)

  if(car.data.lease_term_months){

    //console.log(stored_bid.value)

    await router.push('/credit-application')

    alert('The next step is to fill out the credit application.')
    //window.location.href = '/stripe/payment/' + stored_bid.value + '/500';

  } else {

    alert('Process has been started!' + "\n\n" + 'We will contact you shortly.' + "\n\n" + 'Please upload Proof of funds, also sign the letter of intent and NDA if required.');

  }
}

onBeforeMount( () => {

  car_copied.data = Object.assign({}, car.data)

  car_copied.data.buy_leasing = false
  car_copied.data.trade_in = false
  car_copied.data.buy_cash = false
  car_copied.data.buy_credit = false

})

</script>
<template>

  <teleport to="head" v-if="loadCounters">

    <!-- GA -->
    <component :is="'script'" async src="https://www.googletagmanager.com/gtag/js?id=G-5CQYF7V9G9"/>

    <component :is="'script'">
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    </component>

    <!--    https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtag-->
    <!-- Set User Id -->
    <component v-if="user_id" :is="'script'" type="text/javascript">
      gtag('config', 'G-5CQYF7V9G9', {
      'userid': {{user_id}},
      'USER_ID': {{user_id}}
      });
    </component>
    <component v-else :is="'script'" type="text/javascript">
      gtag('config', 'G-5CQYF7V9G9');
    </component>


    <component :is="'script'" type="text/javascript">
      window.smartlook||(function(d) {
      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
      var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
      c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', 'e334b53a65f5dc7cbad934700234e3e5140367ad', { region: 'us' });
    </component>

  </teleport>

</template>

<script setup>
import {ref, onMounted} from "vue";
import {userState} from '@/composables/login';

const {user_id} = userState();
const loadCounters = ref(false)

// Not load on localhost
onMounted(() => {

  //console.log(window.location.host)

  // Load counters after couple seconds
  setTimeout( () => {

    if (window.location.host != 'localhost:5173') {
      loadCounters.value = true
    }
  }, 1500) // activate after 1.5s (after DOM ready)


});
</script>
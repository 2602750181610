import {ref} from "vue";
import axios from "axios";




const blog = ref({})
const blog_list = ref({})
const redactor = ref({})
const close_modal = ref()

export const Blog = () => {

    const storeBlog = async () => {


        try {

            await axios.post('/api/blog', blog.value).then((response) => {

                blog.value = response.data
            })

        } catch (e: any) {

            console.log(e)
        }
    }

    const getBlogs = async () => {


        try {

            await axios.post('/api/blog-list').then( (response) => {

                blog_list.value = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }

    }


    const getBlog = async (id: number) => {


        try {

            await axios.post('/api/blog/' + id).then( (response) => {

                blog.value = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }

    }


    const updateBlog = async (id: number) => {

        try {

            await axios.put('/api/blog/' + id, blog.value)


        } catch (e: any) {

            console.log(e)
        }
    }

    const updateSitemap = async () => {


        try {

            await axios.post('/smap-blog')


        } catch (error) {

            console.log('>>> ' + error);
        }

    }

    return {
        blog, blog_list,
        storeBlog, getBlog, updateBlog, getBlogs,
        redactor, close_modal,

        updateSitemap
    }

}
<template>

  <span class="btn btn-danger rounded-pill"
        @click="changeVideo(1); video_description = 'The process of concluding a contract and transferring a customer'">
    1
  </span>

  <span class="btn btn-danger rounded-pill ms-2"
        @click="changeVideo(2); video_description = 'Getting a contract from a broker with a customer'">
    2
  </span>

  <span class="btn btn-danger rounded-pill ms-2"
        @click="changeVideo(3); video_description = 'Adding a car to the wanted list'">
    3
  </span>

  <!--  <select @change="changeVideo" v-model="video" class="form-select form-select-lg mb-3" aria-label="Default select example">-->
  <!--    <option value="1">The process of concluding a contract and transferring a customer</option>-->
  <!--    <option value="2">Getting a contract from a broker with a customer</option>-->

  <!--  </select>-->

</template>

<script setup>

import {Video} from "@/composables/video";

const {video, video_description, video_off} = Video()


const changeVideo = (num) => {

  //console.log(e.target.value)
  //console.log(e.target.options[e.target.selectedIndex].text)

  video_off.value = true


  setTimeout(() => {

    video.value = num

    video_off.value = false

  }, 10)


  //video_description.value = e.target.options[e.target.selectedIndex].text
}
</script>
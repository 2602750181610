<template>

  <div class="row" v-if="join_tab">

    <div class="col-12 animate__animated animate__zoomIn">


      <div class="card">

        <div class="card-body">

          <div class="form-floating mt-2 mb-3">
            <input type="text" :class="{'is-valid': validateName()}" v-model.trim="user_signup.data.name"
                   class="form-control"
                   placeholder="Name">
            <label>First name<sup class="text-danger">*</sup></label>
          </div>

          <div class="form-floating mt-2 mb-3">
            <input type="email" :class="{'is-valid': emailValidate()}" v-model.trim="user_signup.data.email"
                   class="form-control"
                   placeholder="name@example.com">
            <label>Email<sup class="text-danger">*</sup></label>
          </div>

          <div class="form-floating mt-2 mb-3">
            <input type="text" v-model.trim="user_signup.data.phone" class="form-control"
                   id="floatingInputSignUpPhone">
            <label for="floatingInputSignUpPhone">Phone<sup class="text-danger">*</sup></label>
          </div>

          <div class="form-floating mt-4">
            <input type="password" :class="{'is-valid': validatePassword()}" v-model.trim="user_signup.data.password"
                   class="form-control" placeholder="Password">
            <label>Password: 8+ symbols<sup class="text-danger">*</sup></label>
          </div>


          <div class="row mt-4 animate__animated animate__fadeInUp" v-if="validatePassword()">

            <div class="col-2 h4 pt-2 text-end text-danger mt-1">I'm</div>
            <div class="col-10">



              <select class="form-select form-control-lg" v-model="user_signup.data.type"
                      aria-label="Default select example">

                <option selected value="null">Select role:</option>
                <option selected value="0">Customer (buyer)</option>
                <option value="2">Auto broker</option>
                <option value="1">Car dealership</option>
                <option value="-1">Ambassador</option>


              </select>

              <small class="text-danger">
                roles: Car dealership, Customer, Broker, Ambassador
              </small>

            </div>

          </div>

          <!-- Invite -->
          <invite-check v-if="user_signup.data.type == 1 || user_signup.data.type == 2 || user_signup.data.type == -1"/>


          <div class="row animate__animated animate__fadeInDown">

            <div class="col-4 mt-3 text-start">

<!--              {{user_signup.data.country}}-->

              <country-list/>
            </div>

            <div class="col-4 pt-3" v-if="user_signup.data.country == 'us'">

              State
              <us-states-select/>
            </div>

            <div class="col-4 mt-3  text-start">

              <label class="form-label text-muted">ZIP</label>
              <span class="badge rounded-pill text-bg-info float-end" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-title="get/show hot offers nearby">?</span>
              <input type="text" v-model.trim="user_signup.data.zipcode" class="form-control form-control-lg"
                     placeholder="optional">
            </div>
          </div>

        </div>


        <!--        && validateEmail(input_email) && validateName()-->
        <div class="d-grid m-3 animate__animated animate__zoomIn" v-if="validatePassword()">
          <button class="btn btn-primary btn-lg rounded-pill"

                  :disabled="(!validateName() || !emailValidate() || !validatePassword() || !validateUserType())" type="button"

                  @click="createAccountCondition()">Sign Up
          </button>
        </div>

        <div class="container text-center">
          <RouterLink to="/terms" v-if="validatePassword()" target="_blank">I agree with Terms and condition</RouterLink>
        </div>

      </div>

      <div v-if="!validatePassword()">
      <span class="btn btn-secondary rounded-pill mt-4 mb-1 ms-4 handLink"
            @click="login_tab=true; join_tab=false" >
      LogIn
    </span>
      <br><small class="text-muted  d-inline-block float-start">if an account exists</small>
      </div>

    </div>

  </div>


</template>

<script setup>
import {onMounted} from "vue";
import {userState} from "@/composables/login";
import {Helpers} from "@/composables/helpers";
import InviteCheck from '@/components/login/InviteCheck.vue'

import CountryList from '@/components/login/CountryList.vue'
import UsStatesSelect from "@/components/login/UsStatesSelect.vue";

// const input_name = ref()
// const input_email = ref()
// const input_password = ref()
// const input_country = ref()
// const input_zipcode = ref()
// const input_type = ref(0)

const {login_tab, join_tab, createAccount, user_signup, invite_valid, user_id} = userState()

const {validateEmail} = Helpers()
const emailValidate = () => {

  return validateEmail(user_signup.data.email)
};

const validateUserType = () => {

  if (user_signup.data.type) {

    return true
  }

  //alert('Select role: Car dealership, Customer, Broker, Ambassador')
  return false
};

const validateName = () => {

  if (user_signup.data.name && user_signup.data.name.length > 1) {

    return true
  }
};

const validatePassword = () => {

  if (user_signup.data.password && user_signup.data.password.length > 7) {

    return true
  }
};

const createAccountCondition = async () => {

  await createAccount()

  if (user_signup.data.type == 1 || user_signup.data.type == 2) {

    if (invite_valid.value == false && user_id.value) {

      alert('Dear Friend,' + "\n\n" + 'Unfortunately, you do not yet have an invite to activate your account' + "\n\n" + 'However, you have a fantastic opportunity to add cars, and if they meet our requirements, luck may be on your side.')


      /* Stripe */
      // setTimeout(async () => {
      //   //await router.push({name: 'list-cars'})
      //   window.location.href = '/stripe/payment/0/5500';
      // }, 100)
    }
  }

}

onMounted(() => {

  user_signup.data.type = null
})


</script>
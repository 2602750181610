import {reactive, ref} from "vue";
import axios from "axios";


//const like = ref(false)
const last_comment = ref(false)


const comment = reactive({
    data: {}
});

const like = reactive({
    data: {}
});

export const Comments = () => {

    const storeComment = async () => {


        try {

            await axios.post('/api/comment', comment.data).then((response) => {

                last_comment.value = response.data
            })

        } catch (e: any) {

            console.log(e)
        }
    }


    const storeLike = async () => {


        try {

            await axios.post('/api/like', like.data)

        } catch (e: any) {

            console.log(e)
        }
    }

    const deleteLike = async (id: number) => {

        try {

            await axios.delete('/api/like/' + id)

        } catch (e: any) {

            console.log(e.response.data.errors)
        }
    }


    return {
        comment, storeComment, last_comment,
        like, storeLike, deleteLike
    }

}
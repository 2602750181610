<template>

  <!-- Modal -->
  <div class="modal fade" id="subscribeModal" tabindex="-1" aria-labelledby="subscribeLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">

          <h1 class="modal-title fs-5">
            Is there a car you would like to buy?
            </h1>
          <button type="button" ref="close_modal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

        </div>

        <div class="modal-body">


          <cars-models-select/>

          <!-- {{car.data.model_id}} -->

          <div class="row mt-4 mb-4 animate__animated animate__fadeIn" v-if="car.data.model_id">

            <div class="col-3">

              <div class="form-check form-switch">
                <input @click="car_looking.data.cash = !car_looking.data.cash" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCash">
                <label class="form-check-label" for="flexSwitchCheckCash">Cash</label>
              </div>

            </div>


            <div class="col-3">

              <div class="form-check form-switch">
                <input @click="car_looking.data.lease = !car_looking.data.lease" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckLease">
                <label class="form-check-label" for="flexSwitchCheckLease">Lease</label>
              </div>

            </div>


            <div class="col-3">

              <div class="form-check form-switch">
                <input @click="car_looking.data.finance = !car_looking.data.finance" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckFinance">
                <label class="form-check-label" for="flexSwitchCheckFinance">Finance</label>
              </div>

            </div>


            <div class="col-3">

              <div class="form-check form-switch">
                <input @click="car_looking.data.trade_in = !car_looking.data.trade_in" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckTradeIn">
                <label class="form-check-label" for="flexSwitchCheckTradeIn">TradeIn</label>
              </div>

            </div>


          </div>

<!--          {{car.data}}-->


          <div class="row mt-4">

            <div class="col-8">

              By providing your email address, you agree to our <a href="/terms" target="_blank"> Terms of Use</a> and  <br><a href="/privacy" target="_blank">Privacy Policy</a>.

              <br><small class="text-muted">You can unsubscribe at any time.</small>
            </div>

            <div class="col-4">

              <button type="button" v-if="car.data.model_id" @click="subscribe()" class="btn btn-primary btn-lg rounded-pill float-end animate__animated animate__fadeInDown">SUBSCRIBE</button>

            </div>

          </div>



        </div>

<!--        <div class="modal-footer">-->

<!--          &lt;!&ndash;          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>&ndash;&gt;-->


<!--        </div>-->
      </div>
    </div>
  </div>


</template>

<script setup>
import {onMounted, ref} from "vue";
import {Cars} from "@/composables/profile/cars";
import {userState} from "@/composables/login";
import {Subscribe} from "@/composables/subscribe";
import CarsModelsSelect from "@/components/profile/cars/CarsModelsSelect.vue";



const {car_looking, storeCar} = Subscribe()

const {user_id} = userState()
const {car} = Cars()

const subscribe = async () => {

  car_looking.data.user_id = user_id.value
  car_looking.data.model_id = car.data.model_id

  await storeCar()

  close_modal.value.click()

}

const close_modal = ref()



</script>
<template>

  <template v-if="!request_sent">

    <small>Name</small>
    <input v-model="new_user.name" class="form-control form-control-lg" type="text">

    <br>

    <small>Email</small>
    <input v-model="new_user.email" class="form-control form-control-lg" type="email">

    <br>

    <small>Phone</small>
    <input v-model="new_user.phone" class="form-control form-control-lg" type="tel">

    <br>



    <button v-if="new_user" @click="submit()" class="btn btn-outline-danger rounded-pill btn-lg mt-3 animate__animated animate__fadeIn">
      SEND
    </button>

  </template>



</template>

<script setup>
import {ref} from "vue";
import {InspectionCart} from "@/components/offer/inspection/InspectionCart";


const {cart, storeOrder, request_sent, inspection_order} = InspectionCart()

const new_user = ref({})

const submit = async () => {

  request_sent.value = true;

  inspection_order.value['cart'] = cart.value;

  inspection_order.value['user'] = new_user.value;

  await storeOrder()
}
</script>
import {reactive, ref} from "vue";
import axios from "axios";


const user_public = reactive({
    data: {}
});

const loading = ref(false)


export const UserPublic = () => {




    const getUser = async (id: number) => {


        try {

            await axios.post('/api/user-info/' + id).then( (response) => {

                user_public.data = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }
    }




    return {
        user_public, getUser, loading
    }
};



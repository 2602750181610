<template>

  <div class="bg rounded-bottom-4">

    <div class="row">

      <div class="col-12 text-center pe-3 ps-3">

        <h1 class="mt-3 fw-bold text-light comfortaa">Best Offers From Verified Brokers And Owners</h1>

      </div>

      <div class="col-6 text-center">

        <button @click="focusOnSearch()" class="btn btn-light rounded-pill shadow mt-2 fw-bold">

          SEARCH
        </button>

      </div>


      <div class="col-6 text-center">

        <button @click="router.push('/board')" class="btn btn-light rounded-pill  shadow fw-bold mt-2">

          WANTED
        </button>

      </div>

    </div>

  </div>


</template>

<script setup>
import {Helpers} from '@/composables/helpers'
import {useRouter} from 'vue-router'

const router = useRouter()
const {focusOnSearch} = Helpers()

</script>

<style scoped>

.bg {
  min-height: 230px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)),
  url('/img/alfa.jpg');
  background-position: center bottom;
  background-repeat: no-repeat; /* Optional: prevents the image from tiling */
  background-size: cover; /* Optional: scales the image to cover the element */
}

</style>

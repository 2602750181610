import {ref, reactive} from "vue"; // reactive, onMounted
import axios from "axios";
//import {useRouter} from 'vue-router';

const user_signup = reactive({
    data: {}
});

const loggedIn = ref(false)
const user_id = ref()
const user_type = ref()
const user_active = ref()
const user_pic = ref()
const token = ref()
const btn_close = ref() // for close modal
const invite = ref('')
const invite_valid = ref(false)

const login_tab = ref(true)
const join_tab = ref()
const forgotten_tab = ref()
const checking_login = ref(false)


export const userState = () => {

    //const router = useRouter()

    const setMainParams = (response: any) => {

        //console.log(response.data)

        axios.defaults.headers.common = {
            'Authorization': 'Bearer ' + response.data.token
        }

        user_id.value = response.data.id
        user_type.value = response.data.type
        user_active.value = response.data.active
        user_pic.value = response.data.pic

        // set token to local storage
        localStorage.setItem('token', response.data.token);
        token.value = response.data.token

        localStorage.setItem('user_id', response.data.id);
        localStorage.setItem('type', response.data.type);
        localStorage.setItem('active', response.data.active);
        localStorage.setItem('pic', response.data.pic);


        localStorage.setItem('cookies_proceed', 'true')

        // console.log('response.data.id')
        // console.log(response.data.id)

        loggedIn.value = true

        //btn_close.value.click(); // close modal

        //// Android user initialisation
        //initialise(response.data.id.toString())

        // setTimeout( () => {
        //
        //     window.location.reload() // important
        // }, 1000)
    }

    const LogIn = async (login: string, password: string) => {

        //console.log(login, password)

        loggedIn.value = false
        checking_login.value = true


        try {

            await axios.get('/sanctum/csrf-cookie').then(async () => {

                await axios.post('/api/login', {email: login, password: password}).then(response => {

                    if (response.data.token) {

                        setMainParams(response)


                        btn_close.value.click()

                        setTimeout(async () => {
                            //await router.push({name: 'list-cars'})
                            window.location.reload()
                        }, 150)

                        //window.location.reload()

                    }
                });
            });

        } catch (error) {
            console.log('>>> ' + error);
        }

        checking_login.value = false
    }

    // login: string, password: string, name: string, type: number, country: string, zipcode: string
    const createAccount = async () => {

        //console.log(login, password)

        //const router = useRouter()

        try {

            /*
            on localhost problem with csrf-cookie - https://laracasts.com/discuss/channels/general-discussion/laravel-sanctum-error-csrf-token-mismatchs
            env api server should add:
            SESSION_DOMAIN=http://localhost:3000
            SANCTUM_STATEFUL_DOMAINS=http://127.0.0.1:8000
             */

            await axios.get('/sanctum/csrf-cookie').then(async () => {

                await axios.post('/api/signup', user_signup.data).then(response => {


                    // account exist?
                    if (response.data == 'exist') {
                        alert('The account with this email already exists. Login please.')

                        login_tab.value = true; join_tab.value = false

                        return;
                    }

                    if (response.data.id > 0) {

                        // user_id.value = response.data.id
                        // localStorage.setItem('cookies_proceed', 'true')

                        setMainParams(response)

                        btn_close.value.click()


                        // if dealer add cars
                        if (response.data.type == 1 || response.data.type == 2) {

                            alert('Congratulation! The private club welcomes you.' + "\n\n"
                                +'1) There is an option for you to upload cars for sale and to buy'+ "\n"
                                +'2) The Board with current requests is available for you'+ "\n"
                                +'3) For each car, there is a specific condition that sellers must meet, so you can sell it with a contract. '+ "\n\n"
                                + 'Within a week, an empty account will become a customer account if there are no cars added.')

                            setTimeout(async () => {
                                //await router.push({name: 'list-cars'})
                                window.location.href = '/profile' // /chat-room/1
                            }, 150)
                        }

                    }
                });
            });


        } catch (error) {
            console.log('>>> ' + error);
        }
    }

    const loginByFacebook = (id: string, login: string, name: string, surname: string) => {

        //console.log(login, password)

        try {

            axios.get('/sanctum/csrf-cookie').then(async () => {

                await axios.post('/api/login-by-facebook', {
                    id: id,
                    email: login,
                    name: name,
                    surname: surname

                }).then(response => {

                    if (response.data.id) {

                        user_id.value = response.data.id

                        if (response.data.token) {

                            setMainParams(response)
                        }

                        //LogIn(login, password)

                        // setTimeout(() => {
                        //     router.push('/profile')
                        //     //window.location.href = '/profile'
                        // }, 100)

                    }
                });
            });


        } catch (error) {
            console.log('>>> ' + error);
        }
    }

    const loginByGoogle = (login: string, name: string, surname: string) => {

        //console.log(login, password)

        try {

            axios.get('/sanctum/csrf-cookie').then(async () => {

                await axios.post('/api/login-by-google', {
                    email: login,
                    name: name,
                    surname: surname

                }).then(response => {

                    if (response.data.id) {

                        user_id.value = response.data.id

                        if (response.data.token) {

                            setMainParams(response)
                        }

                    }
                });
            });


        } catch (error) {
            console.log('>>> ' + error);
        }
    }

    const LogOut = async () => {

        //const router = useRouter()

        const u_id = await localStorage.getItem('user_id');

        if(u_id){
            await axios.post('/api/logout')
        }

        loggedIn.value = false

        token.value = ''
        await localStorage.setItem('token', '')

        user_id.value = false
        await localStorage.setItem('user_id', '')

        //isMobApp.value = false
        await localStorage.setItem('isMobApp', 'false');


        // for clear cash/cookie

        window.location.href = '/'
        //await router.push('/') // {name: 'home'}

        // setTimeout( async () => {
        //
        //     window.location.href = "/";
        // }, 100)

        //return router.push({name: 'home'})
    }

    const createInvite = async () => {

        try {

            await axios.post('/api/invite').then((response) => {

                invite.value = response.data
            })

        } catch (e: any) {

            console.log(e)
        }
    }


    // for admin pages
    const systemLocation = async () => {

        const u_id = localStorage.getItem('user_id');

        if(!u_id){
            window.location.href = '/'
        }
    }

    // set main variables if page refreshed
    const setVariablesAfterRefresh = () => {

        if (localStorage.getItem('token') && loggedIn.value == false) {

            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }


            loggedIn.value = true
            token.value = localStorage.getItem('token')

            user_id.value = localStorage.getItem('user_id')
            user_type.value = localStorage.getItem('type')
            user_active.value = localStorage.getItem('active')
            user_pic.value = localStorage.getItem('pic')

            //isMobApp.value = localStorage.getItem('isMobApp') == 'true' ? true : false
        }
    }


    return {
        LogIn,
        loggedIn,
        LogOut,
        loginByFacebook,
        loginByGoogle,
        systemLocation,
        createAccount,
        setVariablesAfterRefresh,
        token,
        btn_close,
        user_id, user_type, user_active, user_pic,
        login_tab,
        join_tab,
        forgotten_tab,
        checking_login,
        user_signup,
        invite, invite_valid, createInvite
    }
};
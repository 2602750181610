<template>

  <div class="loader">
    <div class="bar"></div>
  </div>

</template>

<style scoped>

@keyframes loader-animation {
  0% {
    width: 0%;
  }
  49% {
    width: 100%;
    left: 0%
  }
  50% {
    left: 100%;
    width: 0;
  }
  100% {
    left: 0%;
    width: 100%
  }
}

.loader {
  height: 2px;
  width: 100%;
  position: center;
  background-color: #ffc107;

}

.loader .bar {
  /*position: absolute;*/
  height: 2px;
  background-color: dodgerblue;
  animation-name: loader-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

}

</style>
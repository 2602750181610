import {reactive, ref} from "vue";
import axios from "axios";
import {useRouter} from 'vue-router';

const bid = reactive({
    data: {}
});
const bids = reactive({
    data: {}
});

const car_description = ref() // for modal
const car_description_B2B = ref() // for modal
const verified = ref(false) //


const loading_bids = ref(false)
const allowed_submit = ref(false)


export const Bids = () => {

    const router = useRouter()

    const getBids = async () => {

        loading_bids.value = true

        try {

            await axios.post('/api/cars-bids' + '?page=' + (router.currentRoute.value.params.id ? router.currentRoute.value.params.id : 1)).then( (response) => {

                bids.data = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }

        loading_bids.value = false
    }

    const getLatestBids = async () => {

        loading_bids.value = true

        try {

            await axios.post('/api/cars-bids-list').then( (response) => {

                bids.data = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }

        loading_bids.value = false
    }

    const getBookingsCar = async () => {

        loading_bids.value = true

        try {

            await axios.post('/api/dealer-books' + '?page=' + (router.currentRoute.value.params.id ? router.currentRoute.value.params.id : 1)).then( (response) => {

                bids.data = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }

        loading_bids.value = false
    }

    const getUserBids = async () => {

        loading_bids.value = true

        try {

            await axios.post('/api/user/cars-bids' + '?page=' + (router.currentRoute.value.params.id ? router.currentRoute.value.params.id : 1)).then( (response) => {

                bids.data = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }

        loading_bids.value = false
    }

    const getBid = async (id: string) => {

        loading_bids.value = true

        try {

            await axios.post('/api/cars-bid/' + id).then( (response) => {

                bid.data = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }

        loading_bids.value = false
    }

    /**
     * Todo
     * @param id
     */
    const dialerConfirm = async (bid_id: string, car_id: string) => {

        loading_bids.value = true

        try {

            await axios.post('/api/cars-bid-confirmation', {bid_id: bid_id, car_id: car_id}).then( (response) => {

                if(response.data && response.data.verified == 0){

                    //
                    alert('Please verify your identity.')

                } else {

                    verified.value = true
                }
            })

            await getBookingsCar()




        } catch (error) {

            console.log('>>> ' + error);
        }

        loading_bids.value = false
    }


    const dialerDecline = async (id: string) => {

        loading_bids.value = true

        try {

            await axios.post('/api/cars-bid-decline', {id: id})

            await getBookingsCar()

        } catch (error) {

            console.log('>>> ' + error);
        }

        loading_bids.value = false
    }

    const hideBid = async (id: string, valid: boolean) => {

        try {

            await axios.post('/api/cars-bid-hide', {id: id, valid: valid})

        } catch (error) {

            console.log('>>> ' + error);
        }

    }

    return {
        bid, bids, getBids, getBid, loading_bids, allowed_submit, car_description, car_description_B2B,
        getUserBids, getBookingsCar, dialerConfirm, dialerDecline, getLatestBids, verified,
        hideBid
    }
};



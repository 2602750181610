<template>

  <div class="row animate__animated animate__fadeInDown" v-if="!user_id && !trade_id" >

    <div class="col-6 mt-3">

      <small>Name</small>
      <input v-model="trade_in.name" class="form-control form-control-lg" type="text" aria-label="name">
    </div>

    <div class="col-6 mt-3">

      <small>Email</small>
      <input v-model="trade_in.email" class="form-control form-control-lg" type="email" aria-label="email">
    </div>

    <div class="col-12 mt-3">

      <small>Phone</small>
      <input  v-model="trade_in.phone" class="form-control form-control-lg" type="tel" aria-label="phone">
    </div>

  </div>

</template>

<script setup>
import {userState} from '@/composables/login';
import {TradeIn} from "@/components/offer/trade_in/trade_in";


const {trade_in, trade_id} = TradeIn()

const {user_id} = userState()
</script>
<template>

<!--  {{comments}}-->

  <div class="row mb-2 border-top border-light pt-2">

    <template :key="c.id" v-for="c in comments">




      <user-preview :user="c.user" />


      <div class="col-12 animate__animated animate__fadeInUp pb-2" :id="'comment_' + c.id">

        <span v-if="c.parent" class="h5 float-start d-inline-block">&#10551;</span>
        <p class="ms-2 p_capital h5" v-html=" c.comment.replace(/\n/g, '<br>')"></p>

        <div class="row">

          <div class="col-7 ps-3 pb-2">

            <span v-if="loggedIn" class="btn btn-outline-primary btn-sm rounded-pill me-2" @click="activateComment = true, current_id=c.id">Reply</span>
            <span v-else class="btn btn-outline-secondary btn-sm rounded-pill me-2" data-bs-toggle="modal" data-bs-target="#loginModal">Reply</span>


            <like-item :you_like="c.you_like" :count_likes="c.likes_count" :comment_id="c.id" :users="c.like_users" />



          </div>

          <div class="col-5 text-end">

            <small class="text-muted ms-4">
              🕒{{ moment(c.updated_at).fromNow() }}
            </small>
            
          </div>

        </div>





        <!-- {{c}}  -->

        <post-comment v-if="activateComment && current_id == c.id" :parent="c.id"
                      @emit-activate-form="activateComment=false" />

      </div>


      <div class="col-12" v-if="c.parent_collection" style="padding-left: 15px">
        <comment-list  :comments="c.parent_collection" />
      </div>

    </template>



  </div>

</template>

<script setup>
import {ref} from "vue";
import {userState} from '@/composables/login';
import CommentList from '@/components/offer/comment/CommentList.vue';
import UserPreview from '@/components/offer/comment/UserPreview.vue';
import PostComment from '@/components/offer/comment/PostComment.vue';
import LikeItem from '@/components/offer/comment/LikeItem.vue';
import moment from "moment";

const {loggedIn} = userState()

const current_id = ref(null)

const activateComment = ref(false)

defineProps(['comments'])
</script>

<style scoped>

.p_capital:first-letter {
  text-transform: uppercase;
}
</style>
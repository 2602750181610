<template>

  <div class="form-check">
    <input @click="trade_in_active = !trade_in_active" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
    <label class="form-check-label handLink" for="flexCheckDefault">
      I have a trade in
    </label>
  </div>

  <div class="row animate__animated animate__fadeInDown" v-if="trade_in_active">

<!--    <div class="col-6 mt-3 ">-->
<!--      <small>Make</small>-->
<!--      <input v-model="trade_in.make" class="form-control form-control-lg" type="text" placeholder="Make" aria-label="Make">-->
<!--    </div>-->

    <div class="col-6 mt-3 ">
      <small>ZIP</small>
      <input v-model="trade_in.zip" class="form-control form-control-lg" type="text" placeholder="ZIP" aria-label="ZIP">
    </div>

    <div class="col-6 mt-3 ">

      <small>Mileage</small>
      <input v-model="trade_in.mileage" class="form-control form-control-lg" type="text" placeholder="Mileage" aria-label="Mileage">
    </div>

    <div class="col-12 mt-3 ">

      <div class="input-group input-group-lg mt-md-3">
        <span class="input-group-text" id="trade_iv_vin">VIN</span>
        <input type="text" v-model="trade_in.mileage" class="form-control" aria-label="VIN" aria-describedby="trade_iv_vin">
      </div>
    </div>

  </div>

</template>

<script setup>
import {ref} from "vue";
import {AvailabilityPublic} from '@/composables/availability_public'

const {trade_in} = AvailabilityPublic()

const trade_in_active = ref(false)

</script>
<template>

  <teleport v-if="car && car.id" to="head">

    <!-- https://chatgpt.com/c/6758c5ac-52c4-800b-9216-a7fac4162f63 -->

    <component :is="'script'" type="application/ld+json">

      {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "{{car.year}} {{car.model.brand.name}} {{car.model.model}} {{car.model_addition}}",
        "image": [
          {{car.photos.slice(0, 5).map(photo => `"https://hotautomarket.com/storage/cars/${photo.pfile}"`).join(', ')}}
        ],
        "description": " {{description(car.description)}} ",
        "brand": {
          "@type": "Brand",
          "name": "{{car.model.brand.name}}"
        },
        "model": "{{car.model.model}}",
        "vehicleModelDate": "{{car.year}}",
        "sku": "{{car.id}}",
        "category": "Cars",
        "offers": {
          "@type": "Offer",
          "url": "https://hotautomarket.com/offer/{{car.id}}",
          "priceCurrency": "USD",
          "price": "{{car.price}}",
          "priceValidUntil": "{{priceValidUntil(car.updated_at)}}",
          "itemCondition": "https://schema.org/UsedCondition",
          "availability": "https://schema.org/InStock",

          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "name": "30-Day Return Policy",
            "merchantReturnDays": 30,
            "returnFees": "Free",
            "returnPolicyCategory": "https://schema.org/ReturnFullRefund"
          },

          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "20000",
              "currency": "USD"
            },

            "shippingDestination": {
              "@type": "DefinedRegion",
              "name": "Air Logistics"
            },

            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {

              "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "d"
            },

            "transitTime": {
              "@type": "QuantitativeValue",
              "minValue": 1,
              "maxValue": 1,
              "unitCode": "d"
            },

            "cutoffTime": "17:00:00-05:00"
          }

      },



          "applicableCountry": ["US","EU","AE","CA","JP","CH","AU","UK","SG","HK"],

          "seller": {
            "@type": "AutoDealer",
            "name": "Hot Auto Market",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "551 S Military Trl",
              "addressLocality": "West Palm Beach",
              "addressRegion": "FL",
              "postalCode": "33415",
              "addressCountry": "US"
            },
            "telephone": "+1-754-286-1771"
          }
        }

      }

    </component>

  </teleport>

</template>

<script setup>
const props = defineProps(['car'])

const description =(text) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  return doc.body.innerText.replace(/"/g, '\\"') || ''; // Extracts text content
}

const priceValidUntil = (d) => {

  const datePart = d.split(' ')[0];

// Convert to a Date object
  const dateObject = new Date(datePart);

// Add 2 years
  dateObject.setFullYear(dateObject.getFullYear() + 2);

// Convert back to a string in YYYY-MM-DD format
  return dateObject.toISOString().split('T')[0];
}

/**
 "aggregateRating": {
 "@type": "AggregateRating",
 "ratingValue": "4.7",
 "reviewCount": "123"
 },
 "review": [
 {
 "@type": "Review",
 "author": {
 "@type": "Person",
 "name": "John Doe"
 },
 "datePublished": "2024-12-01",
 "reviewBody": "The 2024 Toyota Camry XSE is an excellent car with a smooth ride, plenty of power, and great safety features.",
 "reviewRating": {
 "@type": "Rating",
 "ratingValue": "5",
 "bestRating": "5"
 }
 },
 {
 "@type": "Review",
 "author": {
 "@type": "Person",
 "name": "Jane Smith"
 },
 "datePublished": "2024-11-15",
 "reviewBody": "Stylish design and reliable performance make this car a top choice.",
 "reviewRating": {
 "@type": "Rating",
 "ratingValue": "4",
 "bestRating": "5"
 }
 }
 ]
 */
</script>
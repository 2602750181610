<template>

  <meta-teleport v-if="car.data && car.data.model" :car="car"/>
  <meta-teleport-not-found v-else-if="car.data && !car.data.id"/>



  <div class="row mt-3" v-if="car.data && car.data.model">

    <div class="col-12" v-if="car.data.model.brand && car.data.model.brand.name">

<!--      {{car.data.photos[0].pfile}}-->

      <!--      loading="lazy"-->
      <img :src="'/img/brand/' +car.data.model.brand.id + '.svg'" style="max-height: 18px"
           class="img-fluid float-start me-1" :alt="car.data.model.brand.name">

      <h1 class="small text-muted oswald fw-light">{{ car.data.model.brand.name }}</h1>


      <!--      <h2 class="h3 text-muted oswald mb-3">{{ car.data.model.brand.name }}</h2>-->

    </div>


    <div class="col-12" v-if="car.data.model.brand && car.data.model.brand.name">


      <h2 class="animate__animated animate__fadeIn">

        <span class="comfortaa fw-bolder text-danger">
          {{ car.data.model.model }}
        </span>
        <span class="text-muted text-uppercase fw-lighter" v-if="car.data.model_addition"> ({{ car.data.model_addition }})</span>

        <span class="fw-bold ms-2" v-if="car.data.sold">(SOLD)</span>

        <button-add-car/>

      </h2>

      <small class="btn btn-outline-danger btn-sm me-3 rounded-pill mb-2" v-if="car.data.private_listing">Private listing</small>

      <span type="button" class="btn btn-outline-secondary btn-sm rounded-pill me-2  mb-2">
        {{ car_languages[selected_language].mileage }}: <span class="badge text-bg-light"> {{ convertToShortForm(car.data.mileage) }} </span>
      </span>

      <span class="fw-bold me-2">{{ car_languages[selected_language].year }}: {{ car.data.year }}</span>

      <span class="text-muted mb-2 d-inline-block" v-if="car.data.color">Color: {{ car.data.color }}</span>
      <span class="text-muted mb-2 d-inline-block" v-if="car.data.pre_order">, Pre order!</span>
      <!--      <span class="text-muted mb-2 d-inline-block">Year: {{ car.data.year }}</span>,-->
      <!--      Mileage: <span class="text-muted mb-2 d-inline-block"> {{ convertToShortForm(car.data.mileage) }}</span>-->


      <!-- Brand new -->
      <br v-if="car.data.brand_new">
      <small class="text-danger ms-2" v-if="car.data.brand_new">{{ car_languages[selected_language].brand_new }}</small>

      <h2 class="text-danger" v-if="car.data.sold">{{ car_languages[selected_language].sold }}</h2>
      <h2 class="text-danger" v-if="car.data.hold">{{ car_languages[selected_language].on_hold }}</h2>


      <br>
      <RouterLink v-if="user_type > 0" class="btn btn-primary rounded-pill mb-3 mt-3 me-3" rel="nofollow" target="_blank" :to="'/api/pdf/' + car.data.id + '/' + user_id">
        {{ car_languages[selected_language].download_pdf }}
      </RouterLink>

      <!--     <span class=""> listed </span>-->


    </div>


    <!-- Provider -->
    <!-- <div class="col-12 mb-3 mt-3 animate__animated animate__fadeIn text-end">-->

    <!-- <provider-info/>-->

    <!-- </div>-->


    <div class="col-8 mb-3">


      <form-button-request v-if="user_id"/>
      <check-availability v-else :car="car.data"/>

    </div>

    <div class="col-4 pt-2 text-end" v-if="!user_id">

      <span class="badge text-bg-primary border shadow-sm rounded-pill handLink mb-1" @click="router.push('/credit-application')">Finance</span> <!-- v-if="car.data.buy_credit" -->
      <span class="badge text-bg-primary shadow-sm rounded-pill mb-1" v-if="car.data.buy_leasing">Leasing</span>
      <span class="badge text-bg-primary border shadow-sm  handLink rounded-pill mb-1 ms-2" data-bs-toggle="modal" data-bs-target="#TradeInModal">Trade-in</span>

    </div>

    <div class="col-12">


      <form-request-modal :car_provider="car.data.provider"/>

      <start-deal v-if="user_type > 0" :car="car.data"/>

    </div>


    <!--    car.data.car_requested && car.data.car_requested.transfer && car.data.car_requested.transfer.id-->
    <div class="row mb-3 mt-3" v-if="car.data.car_requested || car.data.car_requested && car.data.car_requested.transfer && car.data.car_requested.transfer.id">

      <div class="h5">
        Upload required documents:
      </div>


      <div class="col-4">

        <letter-modal/>

      </div>

      <div class="col-4 text-center">

        <nda-modal/>

      </div>

      <div class="col-4">

        <proof-of-funds-modal/>

      </div>

    </div>


  </div>


  <!-- Photos MD -->
  <!--  row-cols-1 row-cols-md-3 g-4-->
  <md-photos :car="car"/>

  <sm-photo-slider v-if="Object.keys(car.data).length > 0" :car="car.data"/>

  <car-inspection/>

  <!--  HTML / <p> -->
  <description-block v-if="car.data.description" :description="car.data.description"/>

  <div class="row mb-2" v-if="car.data && car.data.private_listing == 0 && Object.keys(car.data).length > 0">

    <div class="col-12 text-end">

      <share-offer :car="car.data"/>

    </div>

  </div>

  <car-equipment :car="car"/>


  <!--  v-if="car.data.price || car.data.msrp_price"-->
  <div class="row mt-3" v-if="car.data.price">

    <div class="col-6 text-center">

      <div class="card rounded-4 bg-light h-100">

        <div class="card-body" v-if="car.data.price">

          <span class="text-muted fw-lighter">
<!--              {{ car_languages[selected_language].price }}-->

            PRICE FOR SALE

          </span><br>
          <span class="h3 card-title mt-2 oswald text-danger fw-bold">${{ car.data.price.toLocaleString('en-US') }}</span>
        </div>
        <div class="card-body" v-else>
          {{ car_languages[selected_language].price_by_request }}
        </div>


      </div>

    </div>

    <span v-if="checkBTwoB && car.data.commission_amount" class="h5 ms-2 d-block mt-3">B2B: partner conditions</span>

    <div v-if="checkBTwoB && car.data.commission_amount"
         class="row bg-light border rounded border-danger text-center me-1 ms-1  mb-3">

      <div class="col-6 pt-2 pb-2" v-if="car.data.commission_amount">

        <h5 class="text-muted">Commission</h5>

        <span class="h3 oswald text-danger">
          ${{ car.data.commission_amount.toLocaleString('en-US') }}
      </span>
      </div>

      <div class="col-6 mt-3 h5" v-if="car.data.commission_not_included">
        Commission not included
      </div>

      <div class="col-6 mt-3 text-start" v-if="car.data.description_btwob">
        <p v-html="car.data.description_btwob.replace(/\n/g, '<br>')"></p>
      </div>

    </div>


    <div class="row mt-3" v-if="car.data.buy_credit && car.data.finance_interest">

      <div class="col-12">

        <finance-info/>

      </div>

    </div>


    <leasing-info v-if="car.data.buy_leasing && car.data.lease_term_months"/>


    <div class="col-6 text-center" v-if="checkBTwoB && car.data.msrp_price">

      <div class="card rounded-4 bg_price h-100">

        <div class="card-body">

          MSRP<br>
          <span class="h3 card-title oswald mt-2">${{ car.data.msrp_price.toLocaleString('en-US') }}</span>
        </div>
      </div>

    </div>


    <div class="mt-3 col-12" v-if="user_id && car.data.vin">

      VIN: {{ car.data.vin }}
    </div>

    <div class="mt-3 col-12" v-if="!user_id && car.data && car.data.vin">

      To view all details, please login.
    </div>

  </div>


  <span class="text-danger ms-3 mt-3 d-inline-block" v-if="car.data.private_listing">Off market: private listing. Only for verified brokers.</span>


  <!-- Comments -->
  <div class="container mt-3 h-auto" v-if="car.data && Object.keys(car.data).length > 0">


    <comment-list :comments="car.data.comments"/>

    <!--    <img class="ms-2 animate__animated animated_footer" src="/img/icons/disquss_1.png " width="70" :alt="'Discussions ' + car.data.model.brand.name +' '+ car.data.model.model ">-->

    <post-comment :offer_id="id"/>
  </div>


  <div class="container mt-3 text-danger" v-if="!car.data && Object.keys(car.data).length == 0 ">
    {{ car_languages[selected_language].private_listing }}, only for verified brokers.
  </div>


  <!-- Same offer by model -->
  <div class="container mt-3" v-if="car.data && Object.keys(car.data).length > 0">
    <same-offers/>
  </div>

  <!-- HOT OFFERS -->
  <div class="container mt-5">
    <suggestion-offers v-if="car.data && Object.keys(car.data).length > 0"/>
  </div>

  <!-- Trade-in Modal-->
  <trade-in-modal/>

  <json-ld v-if="!user_id" :car="car.data" />

</template>

<script setup>
import {onBeforeMount, onMounted, ref, onUnmounted, watch, onUpdated} from "vue";
import {userState} from '@/composables/login';
import {Cars} from '@/composables/profile/cars';
import {Helpers} from '@/composables/helpers';
//import {Views} from '@/composables/views';
//import {TranslateCustomerRegistration} from '@/composables/translate/customer_registration';
import {Languages} from '@/composables/Languages';
import {useRouter} from "vue-router";
//import {User} from "@/composables/profile/user";
//import moment from 'moment'

import MetaTeleport from '@/components/offer/MetaTeleport.vue';
import ShareOffer from '@/components/offer/ShareOffer.vue';
import FormButtonRequest from '@/components/offer/request/FormButtonRequest.vue';
import FormRequestModal from '@/components/offer/request/FormRequestModal.vue';
import FinanceInfo from '@/components/offer/FinanceInfo.vue';
import LeasingInfo from '@/components/offer/LeasingInfo.vue';

import MetaTeleportNotFound from '@/components/inc/MetaTeleportNotFound.vue';
import SmPhotoSlider from '@/components/offer/SmPhotoSlider.vue';
//import ProviderInfo from '@/components/offer/ProviderInfo.vue';
import SameOffers from '@/components/offer/SameOffers.vue';
import SuggestionOffers from '@/components/offer/SuggestionOffers.vue';
import LetterModal from '@/components/offer/documents/LetterModal.vue';
import NdaModal from '@/components/offer/documents/NdaModal.vue';
import ProofOfFundsModal from '@/components/offer/documents/ProofOfFundsModal.vue';
import ButtonAddCar from '@/components/offer/selected_car/ButtonAddCar.vue';
import TradeInModal from '@/components/offer/trade_in/TradeInModal.vue';


import CommentList from '@/components/offer/comment/CommentList.vue';
import StartDeal from '@/components/offer/broker_to_broker/StartDeal.vue';
import PostComment from '@/components/offer/comment/PostComment.vue';
import CarInspection from "@/components/offer/inspection/CarInspection.vue";
import DescriptionBlock from "@/components/offer/DescriptionBlock.vue";
import CheckAvailability from "@/components/offer/CheckAvailability.vue";
import MdPhotos from '@/components/offer/MdPhotos.vue';
import CarEquipment from '@/components/offer/CarEquipment.vue';
import JsonLd from '@/components/offer/JsonLd.vue';




//import HotOffersMd from '@/components/main_page/HotOffersMd.vue';
//import HotLeaseMd from '@/components/main_page/HotLeaseMd.vue';
//import ContactForm from "@/components/offer/request/ContactForm.vue";

//const {translate_customer_registration} = TranslateCustomerRegistration()
const {selected_language, car_languages} = Languages()

const {user_id, user_type} = userState()

const router = useRouter()

const {checkBTwoB, convertToShortForm, activateMasonry} = Helpers()

//const {view, storeView} = Views()

const {getCarOffer, car, getPrivateCarOffer, getHotOffers, cars_hot_offers} = Cars() // getHotLease, cars_hot_offers, cars_hot_lease

onBeforeMount(() => {


  dependOnUserType()

  refreshOffer()

  if(Object.keys(cars_hot_offers.data).length == 0){

    getHotOffers()
  }


  // setTimeout(async () => {
  //
  //   if (car.data && !car.data.lease_term_months) {
  //
  //     if (Object.keys(cars_hot_offers.data).length == 0) {
  //
  //       await getHotOffers()
  //     }
  //
  //   } else {
  //
  //     if (Object.keys(cars_hot_lease.data).length == 0) {
  //       await getHotLease()
  //     }
  //   }
  //
  //
  //   view.data.car_id = props.id
  //   await storeView()
  //
  // }, 1000)


})

onUnmounted(() => {

  clearInterval(refresh.value)
})


onMounted(() => {

  window.scrollTo(0, 0)

})

const dependOnUserType = () => {


  if (user_type.value > 0) {

    getPrivateCarOffer(props.id)

  } else {

    getCarOffer(props.id)
  }
}

const refresh = ref()
const refreshOffer = () => {

  refresh.value = setInterval(() => {

    //console.log('+')
    dependOnUserType()


  }, 30000)
}

onUpdated(() => {

  activateMasonry()
})

const props = defineProps(['id'])

watch(props, () => { // newVal, oldVal


      //scroll to top
      window.scrollTo(0, 0)

      dependOnUserType()

    } //, {deep: true, immediate: true}
);


</script>

<style scoped>
.bg_price {

  background-color: #8EC5FC;
  background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);

}

.maxHeight {
  max-height: 100px;
}
</style>


import {reactive, ref} from "vue";
import axios from "axios";
import {useRouter} from 'vue-router';


const loading_car = ref(false)

const stored_bid = ref(0)

const img = ref()
const img_id = ref()

const cars_hot_offers = reactive({
    data: {}
});

const cars_hot_lease = reactive({
    data: {}
});


const cars = reactive({
    data: {}
});

const car = reactive({
    data: {}
});

const cars_models = reactive({
    data: {}
});

const search = reactive({
    data: {}
});

const new_dealers = reactive({
    data: {}
});


const search_model = ref();

export const Cars = () => {

    const router = useRouter()

    const getCarsModels = async () => {

        loading_car.value = true

        try {

            await axios.get('/api/cars-models').then((response) => {

                cars_models.data = response.data
            })

            loading_car.value = false


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    /* */

    const storeCar = async () => {

        try {

            await axios.post('/api/cars', car.data).then((response) => {

                car.data = response.data
            })

        } catch (e: any) {

            console.log(e)
        }
    }

    const storeBid = async (id: number) => {

        try {

            await axios.post('/api/cars-bid', car.data).then((response) => {

                stored_bid.value = response.data
            })

            if (id) {
                await getCarOffer(id)
            }
            //car.data = {} // clear

        } catch (e: any) {

            console.log(e)
        }
    }

    const updateCar = async (id: number) => {

        try {

            await axios.put('/api/cars/' + id, car.data)


        } catch (e: any) {

            console.log(e)
        }
    }
    const getCars = async () => {

        loading_car.value = true

        try {

            await axios.get('/api/cars' + '?page=' + (router.currentRoute.value.params.id ? router.currentRoute.value.params.id : 1)).then((response) => {

                cars.data = response.data
            })

            loading_car.value = false


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const getCarsListAll = async () => {

        loading_car.value = true

        try {

            await axios.post('/api/cars-dealer-list').then((response) => {

                cars.data = response.data
            })

            loading_car.value = false


        } catch (error) {

            console.log('>>> ' + error);
        }
    }


    const getCar = async (id: number) => {

        loading_car.value = true

        try {

            await axios.get('/api/cars/' + id).then((response) => {

                car.data = response.data
            })

            loading_car.value = false


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const getCarOffer = async (id: number) => {

        loading_car.value = true

        try {

            await axios.post('/api/car-offer/' + id).then((response) => {

                car.data = response.data
            })

            loading_car.value = false


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const downloadOffer = async (id: number, dealer_id: number) => {

        loading_car.value = true

        try {

            await axios.post('/api/pdf/' + id + '/' + dealer_id)

            loading_car.value = false


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const getPrivateCarOffer = async (id: number) => {

        loading_car.value = true

        try {

            await axios.post('/api/car-private-offer/' + id).then((response) => {

                car.data = response.data
            })

            loading_car.value = false


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const getListCars = async (search: string) => {

        loading_car.value = true

        try {

            await axios.post('/api/cars-latest' + '?page=' + (router.currentRoute.value.params.id ? router.currentRoute.value.params.id : 1) + (search ? '&search=' + search + (search_model.value ? '&search_model='+search_model.value : '') : '')).then((response) => {


                if(response.data && Object.keys(response.data.data).length > 0 && cars.data !== response.data) {

                    cars.data = response.data
                }

            })

            loading_car.value = false


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const getHotOffers = async () => {

        loading_car.value = true

        try {

            await axios.post('/api/cars-hot-offers' ).then((response) => {

                cars_hot_offers.data = response.data
            })

            loading_car.value = false


        } catch (error) {

            console.log('>>> ' + error);
        }
    }


    const getHotLease = async () => {

        loading_car.value = true

        try {

            await axios.post('/api/cars-hot-lease' ).then((response) => {

                cars_hot_lease.data = response.data
            })

            loading_car.value = false


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const getNewDealers = async () => {

        try {

            await axios.post('/api/new-dealers' ).then((response) => {

                new_dealers.data = response.data
            })


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const deleteCar = async (id: number) => {

        try {

            await axios.delete('/api/cars/' + id)

            await getCars()

        } catch (e: any) {

            console.log(e.response.data.errors)
        }
    }

    const deleteCarPhoto = async (id: number) => {

        try {

            await axios.delete('/api/car-photo/' + id)

            await getCars()

        } catch (e: any) {

            console.log(e.response.data.errors)
        }
    }

    const updatePhotoSort = async (photoList: any) => {

        await axios.post('/api/update-photo/sort', {
            photo_list: photoList
        })

    }


    return {
        car,
        cars,
        loading_car,
        cars_models,
        img,
        img_id,
        search,
        search_model,
        getCars,
        getCar,
        getCarsModels,
        storeCar,
        updateCar,
        deleteCar,
        deleteCarPhoto,
        getCarOffer,
        getPrivateCarOffer,
        getListCars,
        storeBid, stored_bid,
        getCarsListAll,
        getHotOffers, cars_hot_offers,
        updatePhotoSort,
        downloadOffer,
        getNewDealers, new_dealers,
        getHotLease, cars_hot_lease
    }
};



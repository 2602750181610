<template>

  <!-- user_signup.data -->

  <select v-model="user_signup.data.state" class="form-select form-control-lg mt-2 animate__animated animate__fadeInDown" aria-label="Default select example">

    <option :key="i" v-for="(s, i) in states">{{i}}</option>

  </select>


</template>

<script setup>
import {onMounted} from "vue";
import {userState} from "@/composables/login";
import {Helpers} from "@/composables/helpers";

const {states} = Helpers()

const {user_signup} = userState()

onMounted( () => {

  user_signup.data.state = 'AL'
})
</script>
import {reactive} from "vue";
import axios from "axios";

const transfer = reactive({
    data: {}
});

export const Transfer = () => {

    const storeTransfer = async () => {

        try {

            await axios.post('/api/transfer', transfer.data)


        } catch (e: any) {

            console.log(e)
        }
    }

    return {transfer, storeTransfer}
}
<template>

  <div class="container" v-if="!car.data.lease_term_months">

    <h3 class="mt-3 fw-bold text-danger comfortaa">
      HOT OFFERS
    </h3>
    <!-- Hot offers -->
    <hot-offers-sm v-if="Object.keys(car.data).length > 0"/>

    <hot-offers-md v-if="Object.keys(car.data).length > 0"/>

  </div>

  <div class="container" v-else>

    <h3 class="mt-3 fw-bold text-danger">
      LEASE OFFERS
    </h3>
    <!-- Hot offers -->
    <hot-lease-sm/>

    <hot-lease-md/>

  </div>

</template>
<script setup>

import {Cars} from "@/composables/profile/cars";

const {car} = Cars()

import HotOffersMd from '@/components/main_page/HotOffersMd.vue';
import HotOffersSm from '@/components/main_page/HotOffersSm.vue';

import HotLeaseSm from '@/components/main_page/HotLeaseSm.vue';
import HotLeaseMd from '@/components/main_page/HotLeaseMd.vue';
</script>
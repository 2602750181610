<template>

  <!--  {{car.data.car_requested}}-->

  <transfer-customer v-if="car.data && car.data.car_requested && car.data.car_requested.confirmed_availability && user_type > 0" :request="car.data"/>


  <div v-if="!user_type || user_type == 0">



  <span class="d-inline-block me-3"
        v-if="car.data && car.data.car_requested && car.data.car_requested.confirmed_availability == 0  && user_type > 0">
    Availability of the car requested. After confirmation you can transfer the customer.
  </span>

  <!-- Button trigger modal -->
  <button v-if="car.data && !car.data.car_requested && car.data.hold == 0" :disabled="car.data.sold || car.data.hold" type="button"
          class="btn btn-outline-danger rounded-pill btn-lg mt-4 mb-4 animate__animated animate__backInLeft"
          :aria-label="'Booking request: ' + car.data.model.brand.name + ' ' + car.data.model.model + ' ' + car.data.model_addition"
          data-bs-toggle="modal" data-bs-target="#selectedCarRequestModal">

    <span v-if="car.data.buy_leasing && car.data.lease_term_months">
      Quick Free Quote:
    </span>
    <span v-else>
      Check Availability
    </span>

<!--    {{ car.data.model.model }} <span v-if="car.data.model_addition">({{ car.data.model_addition }})</span>-->
  </button>

  <button v-else-if="car.data && car.data.car_requested" type="button" :disabled="true" class="btn btn-primary btn-lg rounded-pill mt-3 mb-3">
    Requested!
  </button>

  </div>

  <check-user/>

</template>


<script setup>
import {userState} from "@/composables/login";
import {Cars} from "@/composables/profile/cars";

import TransferCustomer from "@/components/offer/request/TransferCustomer.vue";
import CheckUser from "@/components/offer/request/CheckUser.vue";


const {car} = Cars()

const {user_id, user_type} = userState()


</script>
<template>

  <div v-if="request.car_requested.transfer == null" class="pt-3 me-3 float-start text-center">
    <span class="btn btn-primary btn-lg rounded-pill "
          data-bs-toggle="modal" data-bs-target="#transferCustomerModal">
      TRANSFER CUSTOMER
    </span>

    <br>
    <span class="d-inline-block mb-3">
    dealer reserved the car for you and <br>is waiting for a contract
    </span>


  </div>

  <!-- Modal -->
  <div class="modal fade" id="transferCustomerModal" tabindex="-1" aria-labelledby="transferCustomerModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <div class="modal-header">

          <div class="container row m-0 p-0">

            <div class="col-11">

              <h1 class="modal-title fs-5" id="transferCustomerModalLabel">TRANSFER CUSTOMER for {{ request.model.brand.name }} {{ request.model.model }}</h1>

            </div>

            <div class="col-1 text-end">

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

            </div>

            <div class="col-12 text-success fw-bold" v-if="request.vin">VIN: {{ request.vin }}</div>

            <div class="col-12 fw-bold h5 mt-3" v-if="request.price">Price: ${{ request.price }}</div>

            <div class="col-12 text-danger fw-bold" v-if="request.commission_amount">
              Commission: ${{ request.commission_amount }}
            </div>

            <div class="col-12 text-danger fw-bold" v-if="request.commission_percent">Commission {{ request.commission_percent }}%</div>

            <div class="col-12 text-danger fw-bold" v-if="request.commission_not_included">Commission not included</div>
            <div class="col-12 text-danger fw-bold" v-if="request.description_btwob">{{ request.description_btwob }}</div>


          </div>


        </div>

        <div class="modal-body">
          <!--          {{request}}-->

          <div class="row">


            <!-- Name-->
            <div class="col-md-6 mt-3">

              <div class="form-floating mb-3">

                <div class="form-floating">
                  <input type="text" v-model.trim="transfer.data.name" class="form-control" id="inputTransferName" placeholder="Name">
                  <label for="inputTransferName">Customer full name</label>
                </div>

              </div>

            </div>


            <!-- Phone -->
            <div class="col-md-6 mt-3">

              <div class="form-floating mb-3">

                <div class="form-floating">
                  <input type="number" v-model.trim="transfer.data.phone" class="form-control" id="inputTransferPhone" placeholder="Phone">
                  <label for="inputTransferPhone">Customer phone</label>
                </div>

              </div>

            </div>


            <!-- Address -->
            <div class="col-md-12 mt-3">

              <div class="form-floating mb-3">

                <div class="form-floating">
                  <input type="text" v-model.trim="transfer.data.address" class="form-control" id="inputTransferAddress" placeholder="Address">
                  <label for="inputTransferAddress">Customer address</label>
                </div>

              </div>

            </div>


            <!-- City-->
            <div class="col-6 mt-3">

              <div class="form-floating mb-3">

                <div class="form-floating">
                  <input type="text" v-model.trim="transfer.data.city" class="form-control" id="inputTransferCity" placeholder="City">
                  <label for="inputTransferCity">City</label>
                </div>

              </div>

            </div>


            <!--            &lt;!&ndash; State &ndash;&gt;-->
            <!--            <div class="col-2">-->

            <!--              <div class="form-floating mb-3">-->

            <!--                <div class="form-floating">-->
            <!--                  <input type="text" class="form-control" id="inputTransferState" placeholder="State">-->
            <!--                  <label for="inputTransferState">State</label>-->
            <!--                </div>-->

            <!--              </div>-->

            <!--            </div>-->


            <!-- Zip Code -->
            <div class="col-6 mt-3">

              <div class="form-floating mb-3">

                <div class="form-floating">
                  <input type="text" v-model.trim="transfer.data.zip_code" class="form-control" id="inputTransferZipCode" placeholder="Zip Code">
                  <label for="inputTransferZipCode">ZIP Code</label>
                </div>

              </div>

            </div>


          </div>

        </div>

        <div class="modal-footer">

          <!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
          <button type="button" class="btn btn-primary rounded-pill btn-lg"
                  :disabled="!transfer.data.name || !transfer.data.phone"
                  data-bs-dismiss="modal" @click="store()">
            CREATE CONTRACT
          </button>

        </div>

      </div>
    </div>
  </div>

</template>

<script setup>
import {Transfer} from "@/composables/Transfer";
import {userState} from "@/composables/login";
import {Cars} from "@/composables/profile/cars";

const {user_id} = userState()

const {storeTransfer, transfer} = Transfer()

const {getPrivateCarOffer} = Cars()

const store = async () => {

  transfer.data.user_id = user_id.value
  transfer.data.car_bid_id = props.request.car_requested.id

  await storeTransfer()

  await getPrivateCarOffer(props.request.id)

  alert('Customer transferred! Contract sent to you and dealer by email.' + "\n\n" + 'Please upload Proof Of Funds and also sign Letter of Intent and NDA if required.')

}

const props = defineProps(['request'])

</script>
import {reactive} from "vue";
import axios from "axios";

const selected_car = reactive({
    data: {}
});

export const SelectedCar = () => {

    const storeSelectedCar = async () => {

        try {

            await axios.post('/api/selected-car', selected_car.data)


        } catch (e: any) {

            console.log(e)
        }
    }

    const deleteSelectedCar = async (id: number) => {

        try {

            await axios.delete('/api/selected-car/' + id)


        } catch (e: any) {

            console.log(e.response.data.errors)
        }
    }

    return {
        selected_car, storeSelectedCar, deleteSelectedCar
    }
}
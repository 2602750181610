import {reactive, ref} from "vue";
import axios from "axios";


const user = reactive({
    data: {}
});

const loading = ref(false)
const check_link = ref(false)


export const User = () => {




    const getUser = async (id: number) => {


        try {

            await axios.get('/api/user/' + id).then( (response) => {

                user.data = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const updateUser = async (id: number) => {

        loading.value = true

        try {

            await axios.put('/api/user/' + id, user.data)


        } catch (e: any) {

            console.log(e)
        }

        loading.value = false
    }


    //

    const checkProfileLink = async (id: string, slink: string) => {



        try {

            await axios.post('/api/user-check-link', {id: id, link: slink} ).then( (response) => {

                check_link.value = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    return {
        user, getUser, updateUser, loading, checkProfileLink, check_link
    }
};



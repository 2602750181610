<template>

  <div class="row">
<!--    <div class="col-3" v-if="!parent">-->

<!--      <img src="/img/icons/evaluate.png" class="mb-3 img-fluid" width="150">-->


<!--    </div>-->

    <div class="col-12 h3 text-muted"  v-if="!parent">
      Is the offer nice?
    </div>

    <div class="col-12">

      <!--  If you had to rate this car, what would you say?-->

      <div class="form-floating animate__animated animate__fadeIn animated_footer mb-2">
        <textarea v-model="post_comment" class="form-control" placeholder="Leave a comment here" id="floatingTextareaComment" style="height: 100px"></textarea>
        <label v-if="parent" for="floatingTextareaComment">Reply</label>
        <label v-else for="floatingTextareaComment">evaluate this offer</label>
      </div>

      <span class="btn btn-primary rounded-pill mt-2 mb-2 btn-lg animate__animated animate__fadeInDown" v-if="post_comment && user_id" @click="postComment(), emit('emitActivateForm')">
      POST
    <svg class="mb-1" height="18px" viewBox="0 0 24 24" width="20px"><title>Press Enter to send</title><path
        d="M16.6915026,12.4744748 L3.50612381,13.2599618 C3.19218622,13.2599618 3.03521743,13.4170592 3.03521743,13.5741566 L1.15159189,20.0151496 C0.8376543,20.8006365 0.99,21.89 1.77946707,22.52 C2.41,22.99 3.50612381,23.1 4.13399899,22.8429026 L21.714504,14.0454487 C22.6563168,13.5741566 23.1272231,12.6315722 22.9702544,11.6889879 C22.8132856,11.0605983 22.3423792,10.4322088 21.714504,10.118014 L4.13399899,1.16346272 C3.34915502,0.9 2.40734225,1.00636533 1.77946707,1.4776575 C0.994623095,2.10604706 0.8376543,3.0486314 1.15159189,3.99121575 L3.03521743,10.4322088 C3.03521743,10.5893061 3.34915502,10.7464035 3.50612381,10.7464035 L16.6915026,11.5318905 C16.6915026,11.5318905 17.1624089,11.5318905 17.1624089,12.0031827 C17.1624089,12.4744748 16.6915026,12.4744748 16.6915026,12.4744748 Z"
        fill="#ffffff"></path></svg>

  </span>

      <span v-else-if="post_comment"
            data-bs-toggle="modal" data-bs-target="#loginModal"
            class="btn btn-primary rounded-pill mt-2 mb-2 btn-lg animate__animated animate__fadeInDown">
    LogIn for discussions

  </span>

    </div>

  </div>

</template>

<script setup>
import {ref} from "vue";
import {userState} from '@/composables/login';
import {Comments} from '@/composables/comment';
import {Cars} from "@/composables/profile/cars";


const {getPrivateCarOffer, getCarOffer, car} = Cars()

const {storeComment, comment, last_comment} = Comments()

const {user_id, user_type} = userState()

const post_comment = ref()

const postComment = async () => {

  comment.data.offer_id = props.parent ? null : props.offer_id
  comment.data.user_id = user_id.value
  comment.data.parent = props.parent
  comment.data.comment = post_comment.value

  await storeComment()

  post_comment.value = null

  await dependOnUserType() // refresh depends on user type


  let c = document.getElementById('comment_' + last_comment.value);

  if (c) {
    c.scrollIntoView({behavior: 'smooth', block: 'center'})
    //window.scrollBy(0, -3);
  }

}

const dependOnUserType = async () => {

  let id = props.offer_id ? props.offer_id : car.data.id

  if (user_type.value > 0) {

    await getPrivateCarOffer(id)

  } else {

    await getCarOffer(id)
  }
}

const props = defineProps(['offer_id', 'parent'])

const emit = defineEmits(['emitActivateForm']);
</script>
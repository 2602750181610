<template>

  <hr>

  <h5 class="mt-3">Leasing brokers must fill in these fields:</h5>
  <div class="row animate__animated animate__fadeIn">

<!--    <div class="col-4">-->

<!--      <label for="leasingInputFirst" class="form-label text-muted">First<br> payment</label>-->
<!--      <input type="number" v-model="car.data.leasing_first_payment" class="form-control" id="leasingInputFirst">-->

<!--    </div>-->

    <div class="col-4">

      <label for="leasingInputDownPayment" class="form-label text-muted">Down<br> payment</label>
      <input type="number" v-model="car.data.leasing_down_payment" class="form-control" id="leasingInputDownPayment">

    </div>

    <div class="col-4">

      <label for="leasingInputMonthly" class="form-label text-muted">Monthly<br> payment</label>
      <input type="number" v-model="car.data.leasing_monthly_payment" class="form-control" id="leasingInputMonthly">

    </div>

    <div class="col-4">

      <label for="leasingInputTermMonths" class="form-label text-muted">Lease term: <br> months</label>
      <input type="number" v-model="car.data.lease_term_months" class="form-control" id="leasingInputTermMonths">

    </div>

    <div class="col-4 mt-3">

      <label for="leasingInputTotalMiles" class="form-label text-muted">Total lease<br> miles</label>
      <input type="number" v-model="car.data.total_lease_miles" class="form-control" id="leasingInputTotalMiles">

    </div>

    <div class="col-4 mt-3">

      <label for="leasingInputMilesMonth" class="form-label text-muted">Miles per<br> year</label>
      <input type="number" v-model="car.data.miles_per_year" class="form-control" id="leasingInputMilesMonth">

    </div>

    <div class="col-12 mt-3">

      <label for="leasingInputDescription" class="form-label text-muted">Addition</label>
      <textarea class="form-control" v-model="car.data.leasing_description" id="leasingInputDescription" rows="2"></textarea>

    </div>

  </div>

</template>

<script setup>

import {Cars} from "@/composables/profile/cars";

const {car} = Cars()
</script>
<template>


  <label for="country_list" class="form-label text-muted">Country</label>

  <select class="form-select form-control-lg text-uppercase" id="country_list"  @change="user_signup.data.country = $event.target.value, user_signup.data.state = ''" aria-label="Default select example">

    <option :key="i" :selected="c.selected" :value="c.code" v-for="(c, i) in countries">
      {{ c.code }}
    </option>

  </select>

</template>

<script setup>
import {onMounted} from "vue";

import {userState} from "@/composables/login";
import {Countries} from "@/composables/countries";
//import {} from ''

const {user_signup} = userState()

const {countries} = Countries()


onMounted( () => {

  let v = Object.entries(countries)[0];

  //console.log(v[1].code)

  user_signup.data.country = v[1].code
})
</script>
<template>

  <template v-if="car.data && car.data.model && car.data.model.brand && car.data.model.brand.name ">


    <button class="btn btn-secondary rounded-pill btn-lg mt-3 mb-3 animate__animated animate__fadeIn animate__delay-1s"
            data-bs-toggle="modal" data-bs-target="#carInspectionModal"
            :aria-label="'Buy inspection for ' + car.data.model.brand.name + ' ' + car.data.model.model + ' ' + car.data.model_addition">
      REQUEST INSPECTION
    </button>

    <!-- Modal -->
    <div class="modal fade" id="carInspectionModal" tabindex="-1" aria-labelledby="carInspectionModalLabel" aria-hidden="true">
      <div class="modal-dialog">

        <div class="modal-content rounded-4">
          <div class="modal-header">

            <div class="row w-100">

              <div class="col-11">

                <span class="h1 modal-title fs-5" id="carInspectionModalLabel">INDEPENDENT CAR INSPECTION</span><br>
                <a href="/storage/auto_inspection.pdf" target="_blank" class="oswald">Download an Example Report</a>
                <!--              <span class="text-danger">refundable</span>-->

              </div>

              <div class="col-1 ">

                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

            </div>


          </div>

          <div class="modal-body">


            <order-select v-if="stages == 0"/>

            <customer-not-logged v-if="stages == 1"/>


          </div>

          <div class="modal-footer">
            <!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
            <!--          <button type="button" class="btn btn-primary">Save changes</button>-->


            <!--          <button v-if="user_id" class="btn btn-primary rounded-pill btn-lg mt-3 animate__animated animate__fadeIn">-->
            <!--            ORDER CAR INSPECTION FOR ${{ amount }}-->
            <!--          </button>-->

            <template v-if="!request_sent">

              <submit-customer-logged :amount="amount" v-if="user_id"/>

              <button @click="stages = 1" v-if="!user_id && stages != 1" class="btn btn-outline-danger rounded-pill btn-lg mt-3 animate__animated animate__fadeIn">
                NEXT
              </button>

            </template>

            <span v-else>
            Request Sent!
          </span>


          </div>
        </div>
      </div>
    </div>

  </template>

</template>


<script setup>
import {computed, onMounted} from "vue";
import {userState} from '@/composables/login';
import {InspectionCart} from '@/components/offer/inspection/InspectionCart'
import SubmitCustomerLogged from "@/components/offer/inspection/SubmitCustomerLogged.vue";
import OrderSelect from "@/components/offer/inspection/OrderSelect.vue";
import CustomerNotLogged from "@/components/offer/inspection/CustomerNotLogged.vue";
import {Cars} from "@/composables/profile/cars";


const {user_id} = userState()

const {cart, request_sent, stages, inspection_order} = InspectionCart()


const {car} = Cars()

onMounted(() => {

  inspection_order.value['car_id'] = car.data.id

  //console.log( inspection_order.value)
})

const amount = computed(() => {

  return cart.value.reduce((previous, key) => {

    //const price = parseInt(cart.value[key]?.price, 10);
    //console.log(previous, key)
    //console.log(previous)
    //console.log(key)

    return previous + parseInt(key.price) //- & for simple array

  }, 0)

})


</script>
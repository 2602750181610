<template>


  <cookies-proceed/>


  <!--  Brake for firefox/Safari -->
  <div class="container mt-3">
    &nbsp;
  </div>

  <!-- ? style="clear: both"-->
  <footer class="footer clearfix bg-gradient animate__animated animate__fadeIn animate__delay-1s" style="background: rgba(0,0,0,0.4);">


    <!--    <div class="row border-bottom bg-white">-->
    <!--      <div class="col">-->

    <!--        <div class="container p-0"> &nbsp; © {{ new Date().getFullYear() }} HOT AUTO MARKET </div>-->

    <!--      </div>-->
    <!--    </div>-->


    <div class="container animate__animated animated_footer text-white">

      <div class="row">

        <div class="col-6 pt-2 oswald">
          © {{ new Date().getFullYear() }} HOT AUTO MARKET
        </div>


        <div class="col-6 pt-2 text-end">

            <span class="handLink text-decoration-underline" data-bs-toggle="modal" data-bs-target="#supportModal">
            <img src="/img/icons/support.svg" width="32" height="32" alt="support">
              {{languages[selected_language].support}}
            </span>

          <br><RouterLink v-if="loggedIn" to="/credit-application" class="text-light ms-3 mt-2">
                {{languages[selected_language].credit_app}}
              </RouterLink>

          <span v-else @click="join_tab = true, login_tab=false, forgotten_tab = false"  data-bs-toggle="modal" data-bs-target="#loginModal"
                class="handLink d-inline-block animate__animated animate__zoomIn">
              Join
            </span>

        </div>


        <div class="col-6">


          <subscribe-form/>

        </div>

        <div class="col-6 mt-3 text-end" :class="!loggedIn ? 'text-end' : ''">


          <img src="/img/icons/payment/visa.svg" width="50" height="32" alt="Visa" loading="lazy">
          <img src="/img/icons/payment/mastercard.svg" width="50" height="32" alt="MasterCard" loading="lazy">
          <img src="/img/icons/payment/paypal.svg" width="60" height="38" alt="PayPal" loading="lazy">

        </div>



      </div>


      <div class="row">

        <div class="col-9">

          <div class="row">

            <div class="col-12 mt-4 mt-md-5" :class="!loggedIn ? '' : ''">

              <RouterLink to="/mission" class="text-light mb-3 d-inline-block">
                {{languages[selected_language].about_us}}</RouterLink>


              <br><span class="handLink badge" :class="selected_language == 'en' ? 'text-bg-danger' : 'text-bg-light'" @click="setLanguage('en')">EN</span> &nbsp;
<!--              <span class="handLink badge" :class="selected_language == 'de' ? 'text-bg-danger' : 'text-bg-light'" @click="setLanguage('de')">DE</span> &nbsp;-->
<!--              <span class="handLink badge" :class="selected_language == 'es' ? 'text-bg-danger' : 'text-bg-light'" @click="setLanguage('es')">ES</span> &nbsp;-->
<!--              <span class="handLink badge" :class="selected_language == 'kz' ? 'text-bg-danger' : 'text-bg-light'" @click="setLanguage('kz')">KZ</span> &nbsp;-->
<!--              <span class="handLink badge" :class="selected_language == 'ru' ? 'text-bg-danger' : 'text-bg-light'" @click="setLanguage('ru')">RU</span> &nbsp;-->
<!--              <span class="handLink badge" :class="selected_language == 'cn' ? 'text-bg-danger' : 'text-bg-light'" @click="setLanguage('cn')">CN</span> &nbsp;-->

              <!--          &nbsp; <RouterLink to="/terms" class="text-light">Terms of Service</RouterLink>-->


            </div>

          </div>

        </div>

        <div class="col-3 text-end">
          <img src="/img/icons/ssl_secured_wite.svg" width="120" height="90" alt="SSL" loading="lazy" class="float-end d-sm-none">
          <img src="/img/icons/ssl_secured_wite.svg" width="150" height="113" alt="SSL" loading="lazy" class="float-end d-none d-md-block">
        </div>

      </div>

    </div>

  </footer>

  <!--  <footer-iphone-modal />-->

  <subscribe-for-offer-modal/>

</template>

<script setup>
import {onMounted, nextTick} from "vue";

import {Helpers} from "@/composables/helpers";
import CookiesProceed from '@/components/inc/CookiesProceed.vue'
import SubscribeForm from '@/components/main_page/SubscribeForm.vue'
import SubscribeForOfferModal from '@/components/main_page/SubscribeForOfferModal.vue'
import {userState} from '@/composables/login';
import {Languages} from "@/composables/Languages";

//import FooterIphoneModal from '@/components/inc/FooterIphoneModal.vue'
//import {AndroidUser} from "@/composables/AndroidUser";

const {setLanguage, languages, selected_language} = Languages()

const {login_tab, join_tab, forgotten_tab, loggedIn} = userState()

//const footerVisible = ref(false)

const {onVisible} = Helpers();

//const {isMobApp} = AndroidUser();

onMounted(() => {


  setTimeout(() => {

    animateFooter()

  }, 1000)


})

const animateFooter = () => {

  //footerVisible.value = true

  nextTick(() => {

    let animate_cards = document.querySelectorAll('.animated_footer')

    animate_cards.forEach((v) => {

      //console.log(v)


      onVisible(v, (e) => {

        //console.log(e)

        //e.classList.toggle('animate__fadeIn')
        e.classList.add('animate__fadeIn')
      });

    })

  })


}
</script>
<template>

  <!-- Photos MD -->
  <!--  row-cols-1 row-cols-md-3 g-4-->
  <div class="row row_masonry">

    <div class="col-sm-6 col-md-4 mb-4 h-auto" v-if="car.data.video && car.data.video.video">

      <video class="img-fluid rounded-4 mb-3 animate__animated animate__fadeIn float-start" controls v-if="car.data.video && car.data.video.video">
        <source :src="'https://hotautomarket.com/storage/proof/video/' + car.data.video.video">
        Your browser does not support the video tag.
      </video>

    </div>

    <template :key="c.id" v-for="(c) in car.data.photos">

      <div class="col-sm-6 col-lg-4 mb-4 h-auto d-none d-md-block">

        <div class="card rounded-4 shadow bg-transparent">

          <img :src="'https://hotautomarket.com/storage/cars/'+ c.pfile" loading="lazy" @click="copyTo(c.pfile)"
               class="card-img-top rounded-4 animate__animated animate__fadeIn rounded h-auto" :alt="car.data.model.model +' '+ car.data.model.brand.name">

        </div>

      </div>
    </template>

  </div>

</template>

<script setup>

defineProps(['car'])

const copyTo = async (img) => {
  try {

    await navigator.clipboard.writeText('https://hotautomarket.com/storage/cars/' + img);

  } catch (err) {
    //console.error('Failed to copy: ', err);
  }
}
</script>
<template>

  <div class="card rounded-4 mb-3 animate__animated animate__fadeInDown">

    <div class="card-header" v-if="car.commission_amount">
      <span class="text-danger h5 me-3">
        {{translate_customer_registration[selected_language].brokers_commission_fee}}:
          ${{car.commission_amount}}
      </span>

      <btn-activate-chat :provider="car.provider" :rename_chat="'DISCUSS'" />

    </div>


    <div class="card-body">


      <div class="steps d-flex pt-1 mt-3">

        <div class="step" :class="car.car_requested && car.car_requested.id  ? 'completed' : null">

          <div class="step-icon-wrap">
            <div class="step-icon oswald">1</div>
          </div>

          <h4  class="step-title"
               :class="!car.car_requested ? 'badge rounded-pill text-bg-primary handLink' : null"
               data-bs-toggle="modal" data-bs-target="#selectedCarRequestModal"
                v-html="translate_customer_registration[selected_language].btn_check_availability">

          </h4>
        </div>


        <!--    :class="car.confirmed_availability ? 'completed' : null"-->
        <div class="step" :class="car.car_requested && car.car_requested.confirmed_availability == 1 ? 'completed' : null">
          <div class="step-icon-wrap">
            <div class="step-icon oswald">2</div>
          </div>
          <h4 class="step-title" v-html="translate_customer_registration[selected_language].btn_car_reserved"></h4>
        </div>

        <!--    :class="car.transfer ? 'completed' : null" v-if="car.type > 0"-->
        <div class="step" :class="car.car_requested && car.car_requested.transfer ? 'completed' : null">
          <div class="step-icon-wrap">
            <div class="step-icon oswald">3</div>
          </div>

          <h4 class="step-title" data-bs-toggle="modal" data-bs-target="#transferCustomerModal"
                                :class="car.car_requested && car.car_requested.transfer == 0 ? 'badge rounded-pill text-bg-primary handLink' : null"
                                v-html="translate_customer_registration[selected_language].btn_the_contract">

          </h4>

        </div>

      </div>




    </div>
  </div>
</template>

<script setup>
import {TranslateCustomerRegistration} from '@/composables/translate/customer_registration';
import {Languages} from '@/composables/Languages';

const {translate_customer_registration} = TranslateCustomerRegistration()
const {languages, selected_language} = Languages()

import BtnActivateChat from '@/components/chat/BtnActivateChat.vue';

defineProps(['car'])

</script>

<style scoped lang="scss">
@import '/scss/stepper';
</style>
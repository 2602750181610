<template>


  <!-- Modal -->
  <div v-if="car && car.data && car.data.model" class="modal fade" id="TradeInModal" tabindex="-1" aria-labelledby="TradeInModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">

      <div class="modal-content rounded-4">
        <div class="modal-header pe-3 pt-1 ps-0">

          <div class="row container p-0 m-0">

            <div class="col-11">

              <span class="modal-title fs-4 oswald">Trade-In & Car Evaluation</span><br>

              <small class="text-muted">for {{car.data.model.brand.name}} {{car.data.model.model}} {{car.data.model_addition}}</small>
            </div>

            <div class="col-1 text-end">


              <button type="button" class="btn-close small" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

          </div>



        </div>

        <div class="modal-body">

          <div class="row" v-if="!trade_id">

<!--            <div class="col-6">-->

<!--              <span class="text-muted">Make</span>-->
<!--              <input v-model="trade_in.make" class="form-control form-control-lg" type="text" aria-label="make">-->
<!--            </div>-->

<!--            <div class="col-6">-->

<!--              <span class="text-muted">Model</span>-->
<!--              <input  v-model="trade_in.model"  class="form-control form-control-lg" type="text" aria-label="model">-->
<!--            </div>-->

            <div class="col-12">

              <span class="text-muted">VIN</span>
              <input  v-model="trade_in.vin"  class="form-control form-control-lg" type="text" aria-label="VIN">
            </div>

            <div class="col-6 mt-3">

              <span class="text-muted  ms-1">Mileage</span>
              <input  v-model="trade_in.mileage"  class="form-control form-control-lg" type="number" inputmode="numeric" aria-label="mileage">
            </div>

            <div class="col-6 mt-3">

              <span class="text-muted ms-1">ZIP</span>
              <input  v-model="trade_in.zip"  class="form-control form-control-lg" type="number" inputmode="numeric" aria-label="ZIP">
            </div>


          </div>

          <form-public-user v-if="trade_in.mileage"/>

          <evaluate-my-car v-if="trade_in.vin && trade_in.mileage"/>

          <attach-images v-if="price_evaluation" />

<!--          {{trade_in.name}} - -->
<!--          {{trade_in.email}}-->

        </div>

<!--        <div class="modal-footer">-->
<!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
<!--          <button type="button" class="btn btn-primary">Save changes</button>-->
<!--        </div>-->

      </div>
    </div>
  </div>



</template>

<script setup>
import {Cars} from '@/composables/profile/cars';
import {TradeIn} from "@/components/offer/trade_in/trade_in";

import AttachImages from "@/components/offer/trade_in/AttachImages.vue";
import FormPublicUser from "@/components/offer/trade_in/FormPublicUser.vue";
import EvaluateMyCar from "@/components/offer/EvaluateMyCar.vue";



const {trade_in, trade_id, price_evaluation} = TradeIn()


const {car} = Cars()


</script>
import {reactive, ref} from "vue";
import axios from "axios";

import {Helpers} from "@/composables/helpers";



const notification_count = ref(0)
const chat_count = ref(0)

const notification = reactive({
    data: {}
});

const notifications = reactive({
    data: {}
});


export const Notifications = () => {

    const {notifySound} = Helpers();

    const checkNotification = async () => {

        try {

            await axios.post('/api/notification-check').then((response) => {

                if (response.data && response.data.notifications > notification_count.value) {

                    getNotifications()

                    notification_count.value = response.data.notifications

                    notifySound()


                } else  {

                    notification_count.value = 0
                }

                if (response.data && response.data.chat > chat_count.value) {

                    //getNotifications()

                    chat_count.value = response.data.chat

                    notifySound()

                }


            })


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const markAsRead = async () => {

        try {

            await axios.post('/api/notification-mark');


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const getNotifications = async () => {

        try {

            await axios.post('/api/notification-list').then((response) => {

                notifications.data = response.data
            })


        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const storeNotification = async () => {

        try {

            await axios.post('/api/notification', notification.data)


        } catch (e: any) {

            console.log(e)
        }
    }

    return {
        notification, notifications, storeNotification, checkNotification, notification_count, getNotifications, markAsRead,
        chat_count
    }

}
<template>

  <!-- Button trigger modal -->
  <button type="button"
          @click="start_copy_nda = true"
          :disabled=" car.data.car_requested.document && car.data.car_requested.document.nda_sign"
          class="btn rounded-pill btn-lg animate__animated animate__zoomIn"
          :class=" car.data.car_requested.document && car.data.car_requested.document.nda_sign ? 'btn-outline-primary' : 'btn-primary'"
          data-bs-toggle="modal" data-bs-target="#ndaModal">
    NDA

    <span v-if="car.data.car_requested.document && car.data.car_requested.document.nda_sign">
      ✅
    </span>

  </button>



  <!-- Letter of intent Modal -->
  <div class="modal fade" id="ndaModal" tabindex="-1" aria-labelledby="ndaModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-start">
          <h1 class="modal-title fs-5" id="ndaModalLabel">CAR PURCHASE NON-DISCLOSURE AGREEMENT</h1>
          <button type="button" @click="start_copy_nda = false" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body text-start">




          <p>
          Effective Date: {{new Date().toLocaleDateString()}}
          </p>

          <p>
            <strong>Parties:</strong>

          {{car.data.car_requested.user.name}} {{car.data.car_requested.user.surname}} <br>
          Seller: {{car.data.provider.name}} {{car.data.provider.surname}} {{car.data.provider.business_name ? '(' + car.data.provider.business_name + ')' : ''}} <br>
          </p>

          <p>
            <strong> Purpose: </strong> This Agreement protects confidential information exchanged during the car purchase transaction.
          </p>

          <p>
            <strong>Confidential Information:</strong> Any information related to the vehicle or transaction shared between the Parties.
          </p>

          <p>
            <strong>Non-Disclosure:</strong> Parties agree not to disclose Confidential Information to third parties, except as required by law, and to use it only for the Transaction.
          </p>

          <p>
            <strong>Duration:</strong> Effective for one year from the signed NDA.
<!--            Effective until [Specify Duration] or until a written agreement is reached, whichever is earlier.-->
          </p>

          <p>
            <strong>Return of Information:</strong> After the Transaction or Agreement termination, both Parties must promptly return or destroy all Confidential Information.
          </p>

<!--          <p>-->
<!--          Governing Law: This Agreement follows the laws of [Specify Jurisdiction].-->
<!--          </p>-->

          <p>
          Entire Agreement: This Agreement is the full understanding between the Parties and replaces all prior agreements.
          </p>

          <p>
          Signatures: {{car.data.car_requested.user.name}} {{car.data.car_requested.user.surname}}
<!--          Seller: [Seller's Signature] [Date]-->
          </p>

          <canvas-sign-nda/>

          <!--            <img v-if="sign" :src="sign" width="400" height="200">-->

        </div>

        <div class="modal-footer">
          <!--          <button type="button" class="btn btn-outline-secondary rounded-pill" data-bs-dismiss="modal">Close</button>-->


          <div class="container">

            <div class="d-grid gap-2">
              <button @click="save()"
                      type="button"
                      class="btn btn-primary rounded-pill btn-lg" data-bs-dismiss="modal">
                SIGN
              </button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>


</template>

<script setup>
import {Sign} from '@/composables/profile/sign';
import CanvasSignNda from '@/components/offer/documents/CanvasSignNda.vue';
import {userState} from "@/composables/login";
import {Cars} from "@/composables/profile/cars";


const {user_id, user_type} = userState()

const {sign, storeDocument, document, storeImgSign, start_copy_nda} = Sign()

const {car, getPrivateCarOffer, getCarOffer} = Cars()

const save = async () => {


  await storeImgSign()


  document.data.user_id = user_id.value
  document.data.user_seller_id = car.data.user_id
  document.data.car_id = car.data.id

  document.data.bid_id = car.data.car_requested.id

  // console.log('document.data.bid_id')
  // console.log(document.data.bid_id)



  //await storeDocument()

  setTimeout(async () => {

    document.data.nda_sign = await sign.value

    await storeDocument()

    // !
    await dependOnUserType()

    document.data.nda_sign = null // clear!

    start_copy_nda.value = false

    sign.value = null

  }, 350)




}


const dependOnUserType = async () => {


  if (user_type.value > 0) {

    await getPrivateCarOffer(car.data.id)

  } else {

    await getCarOffer(car.data.id)
  }
}


</script>
<template>

  <small class="form-label">{{car_languages[selected_language].color_interior}}</small>
  <input type="search" class="form-control" list="color_interior_list" v-model="car.data.color_interior">

  <datalist id="color_interior_list" class="w-100">
    <option :key="'color_' + i" v-for="(c, i) in colors">{{c}}</option>
  </datalist>


</template>

<script setup>
import {Cars} from "@/composables/profile/cars";

import {Languages} from '@/composables/Languages';

const {car_languages, selected_language} = Languages()


const {car} = Cars()
const colors = [
  'Black',
  'White',
  'Blue',
  'Red',
  'Gray',
  'Silver',
  'Orange',
  'Yellow',
  'Green',
  'Indigo',
  'Violet',
  'Purple',
  'Pink',
  'Magenta',
  'Brown',
  'Cyan',
  'Turquoise',
  'Teal',
  'Aquamarine',
  'Lime',
  'Olive',
  'Gold',
  'Beige',
  'Maroon',
  'Navy',
  'Coral',
  'Salmon',
  'Lavender',
  'Orchid',
  'Plum'
];

</script>
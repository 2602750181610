<template>

  <ul v-if="car.data.equipment" class="mt-3" @click="copyTo()" v-html="car.data.equipment"></ul>

</template>

<script setup>

const props = defineProps(['car'])

const copyTo = async () => {
  try {

    const tempElement = document.createElement('div');

    tempElement.innerHTML = 'Fill out the form to calculate trade-in value  - https://hotautomarket.com/offer/' + props.car.data.id + "\n\n" + props.car.data.description + "\n\n" + props.car.data.equipment;


    ['li', 'p', 'div'].forEach(tag => {
      tempElement.querySelectorAll(tag).forEach(el => {
        const lineBreak = document.createTextNode("\n");
        el.appendChild(lineBreak);
      });
    });

    await navigator.clipboard.writeText( tempElement.textContent || tempElement.innerText || '');

  } catch (err) {
    //console.error('Failed to copy: ', err);
  }
}
</script>
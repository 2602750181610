<template>

  <!-- Support Modal -->
  <div class="modal fade" id="supportModal" tabindex="-1" aria-labelledby="supportModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title fs-5 h1 d-inline-block" id="supportModalLabel">How can I help?</span>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">

          <div class="row">

            <div class="col-6 mb-3" v-if="!user_id">

                <label for="supportFormControlName" class="form-label">Name</label>
                <input type="text" v-model.trim="support.data.name" class="form-control" id="supportFormControlName" placeholder="name">

            </div>

            <div class="col-6 mb-3" v-if="!user_id">

              <label for="supportFormControlEmail" class="form-label">Email address</label>
              <input type="email" v-model.trim="support.data.email" class="form-control" id="supportFormControlEmail" placeholder="name@example.com">

            </div>

          </div>



          <div class="mb-3">
            <label for="supportFormControlTextarea" class="form-label">Question</label>
            <textarea class="form-control" v-model.trim="support.data.question" id="supportFormControlTextarea" rows="3"></textarea>
          </div>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-primary rounded-pill btn-lg" data-bs-dismiss="modal" :disabled="!support.data.question" @click="submit()">SUBMIT</button>
        </div>

      </div>
    </div>
  </div>


</template>

<script setup>
import {userState} from "@/composables/login";
import {Support} from "@/composables/support";

const {support, storeSupport} = Support()

const {user_id} = userState()

const submit = async () => {

  support.data.user_id = user_id.value ? user_id.value : null

  await storeSupport()

  support.data.question = null

  alert('The question has been submitted! '+"\n\n"+'We\'ll get in touch with you shortly')

}
</script>
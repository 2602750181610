<template>

  <img src="/img/car_inspection_.jpg" :alt="'car inspection'" loading="lazy" style="max-width: 170px" class="img-fluid rounded-4 float-end">



  <h5>Exteriors:</h5>

  <ul>
    <li>Inspect the car's paint coating</li>
    <li>Checking for dents on the body</li>
    <li>Checking the windscreen for chipping</li>
    <li>Car body scratch inspection</li>

  </ul>

  <h5>Interior:</h5>
  <ul>
    <li>Checking the condition of seats</li>
    <li>Checking steering wheel condition</li>
    <li>Checking multimedia</li>
    <li>Checking the air-conditioning system</li>
    <li>Checking for odour in the cabin</li>
  </ul>

  ROAD TEST & TIRE CONDITION & WHEEL CONDITION
  <br>

  <span class="float-end oswald h5 text-danger">$299</span><br>

  <hr>

  <span class="h5 oswald">CARFAX</span> - <span class="h5 oswald text-danger"> $35 </span>

  <span class="float-end">
            <input type="checkbox" @click="addToCart(1)" class="btn-check" id="btn-check-outlined_1" autocomplete="off">
            <label class="btn btn-outline-danger rounded-pill" for="btn-check-outlined_1">
              ADD{{ cart[1] ? 'ED' : '' }}
            </label><br>
          </span>

  <hr>

  <div class="row">

    <div class="col-8">

      <h5 class="oswald fw-lighter">
        Negotiating on your behalf with the seller. 50% of the discount amount goes to us.
      </h5>
    </div>

    <div class="col-4 text-end">

      <input type="checkbox" @click="addToCart(2)" class="btn-check" id="btn-check-outlined_2" autocomplete="off">
      <label class="btn btn-outline-danger rounded-pill" for="btn-check-outlined_2">
        ADD{{ cart[2] ? 'ED' : '' }}
      </label><br>

    </div>

  </div>

</template>

<script setup>
import {InspectionCart} from "@/components/offer/inspection/InspectionCart";
import {onMounted} from "vue";


const {cart, items} = InspectionCart()

const addToCart = (id) => {

  if (cart.value[id]) {

    delete cart.value[id];

  } else {

    cart.value[id] = items[id];
  }

  //console.log(id)
  //console.log(cart.value)
  // console.log(items)

  //updateCartDisplay();
}

onMounted(() => {

  cart.value[0] = items[0]
})


</script>
<template>

  <!-- Button trigger modal -->
  <button type="button"
          :disabled=" car.data.car_requested.document && car.data.car_requested.document.proof_of_funds"
          :class=" car.data.car_requested.document && car.data.car_requested.document.proof_of_funds ? 'btn-outline-primary' : 'btn-primary'"
            class="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#pofModal">
            Proof of Funds

    <span v-if="car.data.car_requested.document && car.data.car_requested.document.proof_of_funds">
      ✅
    </span>

  </button>

  <!-- Modal -->
  <div class="modal fade" id="pofModal" tabindex="-1" aria-labelledby="pofModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-header">
          <h1 class="modal-title fs-5" id="pofModalLabel">Proof of funds</h1>
          <button type="button" ref="close_modal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">

          <div class="mb-3">
            <label for="formFilePof" class="form-label">Select file: pdf or image</label>
            <input class="form-control" ref="file" @change="upload()" accept="application/pdf,image/jpeg,image/png" type="file" id="formFilePof">
          </div>


          <load-line v-if="file_loading" />

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary float-start rounded-pill" data-bs-dismiss="modal">Close</button>
<!--          <button type="button" class="btn btn-primary">Save changes</button>-->
        </div>

      </div>
    </div>
  </div>


</template>

<script setup>
import {ref} from "vue";
import axios from "axios";
import {Sign} from '@/composables/profile/sign';
import {Cars} from '@/composables/profile/cars';
import {userState} from '@/composables/login';

import LoadLine from '@/components/inc/LoadLine.vue'

const {user_id, user_type} = userState()

const {car, getPrivateCarOffer, getCarOffer} = Cars()

const {sign, storeDocument, document} = Sign()

const file = ref()
const close_modal = ref()
const file_loading = ref(false)

const upload = async () => {

  file_loading.value = true

  await storeFile()


  document.data.user_id = user_id.value
  document.data.user_seller_id = car.data.user_id
  document.data.car_id = car.data.id

  document.data.proof_of_funds = await sign.value
  document.data.bid_id = car.data.car_requested.id

  // console.log('document.data.bid_id')
  // console.log(document.data.bid_id)

  await storeDocument()

  // !
  await dependOnUserType()

  document.data.proof_of_funds = null // clear!

  close_modal.value.click()

  file_loading.value = false
}

const storeFile = async () => {

  //loading.value = true

  //let files = document.getElementById('formFilePof').files;

  const form = new FormData();
  form.append('file', file.value.files[0]); //files[0]

  await axios.post('/api/sign-prof',
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
  ).then(response => {

    //console.log(response.data)

    sign.value = response.data

    //canvas.value.clear()
    //signaturePad.value = null

  });

}


const dependOnUserType = async () => {


  if (user_type.value > 0) {

    await getPrivateCarOffer(car.data.id)

  } else {

    await getCarOffer(car.data.id)
  }
}
</script>
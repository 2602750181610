<template>

  <!-- Sell Car Modal -->
  <div class="modal fade" id="sellCarModal" tabindex="-1" aria-labelledby="sellCarModalLabel" aria-hidden="true">
    <div class="modal-dialog">

      <div class="modal-content">

        <div class="modal-header">

          <div class="row container p-0 m-0">
            <div class="col-11">

              <span class="modal-title fs-5 h1 d-inline-block" id="sellCarModalLabel">
                Submit a car and dealers can offer to you
              </span>

              <span class="text-muted" v-if="!user_id">Before start please LogIn or Create account</span>

            </div>
            <div class="col-1 text-end">
              <button type="button" ref="btn_close" class="btn-close" data-bs-dismiss="modal"
                      aria-label="Close"></button>
            </div>


          </div>


        </div>

        <div class="modal-body" v-if="user_id">

          <!--          <add-car-with-modal/>-->


        </div>

        <div class="modal-body" v-else>

          <card-login/>
          <card-signup/>

        </div>


      </div>
    </div>
  </div>


  <button type="button" class="btn-close d-none" id="closeLoginModal" data-bs-dismiss="modal"
          data-bs-target="#sellCarModal" aria-label="Close"></button>

  <button class="btn btn-primary d-none" id="changeModalToSell"
          data-bs-target="#addCarModal" data-bs-toggle="modal" href="#addCarModal" role="button">
    Open sale modal
  </button>

</template>

<script setup>
import {watch} from "vue";
// import {Cars} from "@/composables/profile/cars";
import {userState} from "@/composables/login";
import {BuySellCar} from "@/composables/BuySellCar";


//import AddCarWithModal from "@/components/profile/cars/AddCarWithModal.vue";
//import ColorList from "@/components/profile/cars/ColorList.vue";
//import ColorInteriorList from "@/components/profile/cars/ColorInteriorList.vue";

import CardLogin from "@/components/login/CardLogin.vue";
import CardSignup from "@/components/login/CardSignup.vue";


const {btn_sell} = BuySellCar()

const {user_id} = userState()


watch(user_id, () => { // newVal, oldVal
      //console.log('Router:', newVal, oldVal)
      //console.log('modal changed')

      if (btn_sell.value) {

        document.getElementById('closeLoginModal').click()


        setTimeout(() => {

          document.getElementById('changeModalToSell').click()

        }, 500)

        btn_sell.value = false
      }

    } //, {deep: true, immediate: true}
);
</script>
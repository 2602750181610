<template>

  <span class="text-muted">
                Located: <span class="text-uppercase fw-bold" v-if="car.data.country">
                        {{car.data.country}}
                        </span>
              </span>


  <select class="form-select form-control-lg text-uppercase" v-model="car.data.country" aria-label="Default select">

    <option :key="i" :value="c.code" :selected="c.selected" v-for="(c, i) in countries">
      {{c.country}}
    </option>

  </select>

</template>

<script setup>
import {Countries} from "@/composables/countries";
import {Cars} from "@/composables/profile/cars";

const {car} = Cars()

const {countries} = Countries()
</script>
<template>

  <!--  {{car.data.other_offers}}-->


  <hr v-if="car.data && car.data.other_offers && Object.keys(car.data.other_offers).length > 1">
  <span class="ms-2" v-if="car.data && car.data.other_offers && Object.keys(car.data.other_offers).length > 1"> Also </span>
  <div class="row row-cols-2 row-cols-md-6 g-4" v-if="car.data.other_offers">

    <template :key="c.id" v-for="c in car.data.other_offers">

      <!-- Remove current offer -->
      <div class="col" v-if="car.data.id != c.id">

        <div class="card bg-transparent border-0">


          <div class="card-body p-0 m-0">

            <RouterLink class="handLink" :to="'/offer/' + c.id">
              <img v-if="c.photo.pfile"
                   data-bs-toggle="tooltip" data-bs-placement="top" :data-bs-title="'Y: '+c.year+ '; M: ' + convertToShortForm(c.mileage)"
                   :src="'https://hotautomarket.com/storage/cars/' + c.photo.pfile" class="rounded-4 img-fluid h-auto" loading="lazy" :alt="c.model.model + ( c.model_addition ? ' ' + c.model_addition : '')">
            </RouterLink>

            <div class="row oswald">

              <div class="col-8 small">

                <small class="text-muted ms-1">
                  {{c.model.model}}
                  {{(c.model_addition != c.model.model ? c.model_addition : '')}}
                </small>
              </div>
              <div class="col-4 small text-end">

                <small class="text-muted">
                  ${{ (c.price / 1000).toFixed(0)}}k
                </small>
                </div>

            </div>



<!--            {{ moment(c.updated_at).fromNow() }}-->

            <!--          <h5 class="card-title">Card title</h5>-->
            <!--          <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>-->
          </div>

        </div>
      </div>
    </template>


  </div>


</template>

<script setup>
import {Cars} from '@/composables/profile/cars';
import {Helpers} from '@/composables/helpers';

const {convertToShortForm} = Helpers()
const {car} = Cars()


</script>
<template>

  <teleport to="head">

    <title>Hot Auto Market</title>
    <!-- <link rel="canonical" href="https://hotautomarket.com/"/> -->
    <meta name="robots" content="noindex,follow">

  </teleport>

</template>
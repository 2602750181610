import {ref} from "vue";
import axios from "axios";

const trade_in = ref({})
const trade_id = ref(0)
const sent= ref(false)

const evaluation= ref({})
const price_evaluation= ref(0)


export const TradeIn = () => {

    const sendRequest = async () => {

        try {

            //await axios.get('/sanctum/csrf-cookie').then(async () => {

                await axios.post('/api/trade', trade_in.value).then( response => {

                    //console.log(response.data)

                    trade_id.value = response.data.id
                })

                //trade_in.value = {}

                setTimeout(() => {

                    sent.value = false

                }, 30000)

            //});

        } catch (error) {

            console.log('>>> ' + error);
        }

    }


    const getEvaluation = async () => {

        try {

            //await axios.get('/sanctum/csrf-cookie').then(async () => {

            await axios.post('/api/evaluation', trade_in.value).then( response => {

                //console.log(response.data)

               evaluation.value = response.data
            })

            //});

        } catch (error) {

            console.log('>>> ' + error);
        }

    }


    return{
        trade_in, sendRequest, trade_id,

        evaluation, getEvaluation, price_evaluation
    }
}
<template>

  <hr>

  <h5 class="mt-3">Finance options:</h5>
  <div class="row animate__animated animate__fadeIn">

    <div class="col-6">

      <span class="text-muted">Interest Rate %</span>
      <div class="input-group input-group-lg mb-3">
        <span class="input-group-text" id="inputGroupInterestPercent"> %</span>
        <input type="text" @keyup="replaceComma" v-model.trim="car.data.finance_interest"  class="form-control" aria-label="Sizing example input" aria-describedby="inputGroupInterestPercent">
      </div>


    </div>

    <div class="col-6">

      <span class="text-muted">Payment monthly</span>
      <div class="input-group input-group-lg mb-3">
        <span class="input-group-text" id="inputGroupInterest">$</span>
        <input type="number" v-model="car.data.finance_monthly"  class="form-control" aria-label="Sizing example input" aria-describedby="inputGroupInterest">
      </div>

    </div>

    <div class="col-6">

      <label for="financeInputFirstPayment" class="form-label text-muted">First payment</label>
      <input type="number" v-model="car.data.finance_first_payment" class="form-control" id="financeInputFirstPayment">

    </div>

    <div class="col-6">

      <label for="financeInputTerm" class="form-label text-muted">Loan Term</label>
      <input type="number" v-model="car.data.finance_for_months" class="form-control" id="financeInputTerm">

    </div>

    <div class="col-12 mt-3">

      <label for="financeInputAddition" class="form-label text-muted">Addition</label>
      <textarea class="form-control" v-model="car.data.finance_addition" id="financeInputAddition" rows="2"></textarea>

    </div>

  </div>

</template>

<script setup>
import {Cars} from "@/composables/profile/cars";

const {car} = Cars()

const replaceComma = e => {

  //console.log(e)

  let s =  e.target.value.replace(/,/g, '.')
  s =  s.replace(/[^0-9.]/g, '')

  // s.replace('.', ',')
  //
  car.data.finance_interest = s
  //
  //console.log(s)

  //return e.target.value.replace(',', '.')
}
</script>
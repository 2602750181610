<template>


  <!-- Button trigger modal -->
<!--  <button type="button" class="btn btn-primary mt-3 rounded-pill" data-bs-toggle="modal" data-bs-target="#addCarModal"-->
<!--          @click="car.data = {}">-->
<!--    Sell a Car-->
<!--  </button>-->

  <!-- Modal add car (admin) --> <!-- fade -->
  <div class="modal fade animate__animated animate__zoomInUp" id="addCarModal"
       data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="addCarModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content rounded-4">

        <div class="modal-header">

          <div class="row container p-0 m-0">

            <div class="col-8">

              <h1 class="modal-title fs-5" id="addCarModalLabel" v-if="!car.data.id">{{car_languages[selected_language].sell_car}}</h1>
              <span class="h5" v-else-if="car.data && car.data.model && car.data.model.brand && car.data.model.brand.name"> {{
                  car.data.model.brand.name
                }}: {{ car.data.model.model }}</span>

            </div>

            <div class="col-4 text-end">

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      @click="car.data = {}"></button>

            </div>

            <div class="col-12 mt-3"  v-if="user_type > 0">


              <div class="form-check form-switch">
                <input class="form-check-input" v-model="car.data.private_listing" :checked="car.data.private_listing" @click="car.data.private_listing = !car.data.private_listing"  type="checkbox" role="switch" id="flexSwitchCheckPrivateListing">
                <label class="form-check-label" for="flexSwitchCheckPrivateListing">
                  {{car_languages[selected_language].private_listing}}
                </label>
              </div>

              <p v-if="car.data.private_listing" class="text-muted animate__animated animate__fadeInDown">
                Images and other content cannot be published on the internet by other dealers. Presentation exclusively for direct customers. Transferring to a third party is not allowed.
              </p>

            </div>

          </div>


        </div>

        <div class="modal-body">

          <cars-models-select v-if="!car.data.id"/>


          <div class="row">

            <div class="col-8 mb-3 animate__animated animate__fadeInDown" v-if="car.data.model_id">


              <small class="form-label text-muted">{{car_languages[selected_language].model_additional}}</small>
              <input type="text" :maxlength="75" class="form-control" v-model="car.data.model_addition">

            </div>

            <div class="col-4 mb-3">

              <small class="form-label">{{car_languages[selected_language].year}}</small> <sup class="text-danger">*</sup>
              <input type="number" inputmode="numeric" :maxlength="4" class="form-control" v-model.number="car.data.year" @input="numbersOnly">

            </div>


<!--            <div class="col-4 mb-3">-->

<!--              <color-list/>-->

<!--            </div>-->


<!--            <div class="col-4 mb-3">-->

<!--              <color-interior-list/>-->

<!--            </div>-->


            <div class="col-4 ">

              <small class="form-label">{{car_languages[selected_language].mileage}}</small>
              <input type="number" inputmode="numeric" maxlength="6" class="form-control" v-model.number="car.data.mileage" @input="numbersOnly">

            </div>


            <div class="col-4 ">

              <small class="form-label">{{car_languages[selected_language].price}}</small>
              <input type="number" inputmode="numeric" class="form-control" v-model.number="car.data.price" @input="numbersOnly">

            </div>

            <div class="col-4 ">

              <small>MSRP</small>
              <div class="input-group mb-3">
                <!--                    <span class="input-group-text">MSRP</span>-->
                <div class="form-floating">
                  <input type="number" inputmode="numeric" v-model.trim="car.data.msrp_price" @input="numbersOnly"
                         :class="car.data.brand_new == true && !car.data.msrp_price ? 'is-invalid' : ''"
                         class="form-control" id="floatingInputPercent" placeholder="MSRP">
                  <label for="floatingInputPercent">$</label>
                </div>
              </div>

            </div>

            <div class="col-4">

              <div class="form-check form-switch mt-2 pt-3 mb-3">
                <input class="form-check-input" :checked="car.data.price_request"
                       @click="car.data.price_request = !car.data.price_request" type="checkbox" role="switch"
                       id="flexSwitchCheckPriceByRequest">
                <label class="form-check-label" for="flexSwitchCheckPriceByRequest">
                  {{car_languages[selected_language].price_by_request}}</label>
              </div>

            </div>

            <div class="col-4">

              <div class="form-check form-switch mt-2 pt-3 mb-3">
                <input class="form-check-input" :checked="car.data.pre_order"
                       @click="car.data.pre_order = !car.data.pre_order" type="checkbox" role="switch"
                       id="flexSwitchCheckPreOrder">
                <label class="form-check-label" for="flexSwitchCheckPreOrder">
                  {{car_languages[selected_language].pre_order}}</label>
              </div>

            </div>



            <div class="col-4 pt-3">

              <div class="form-check form-switch mt-2 mb-2">
                <input class="form-check-input" :checked="car.data.brand_new"
                       @click="car.data.brand_new = !car.data.brand_new" type="checkbox" role="switch"
                       id="flexSwitchCheckBrandNew">
                <label class="form-check-label" for="flexSwitchCheckBrandNew">
                  {{car_languages[selected_language].brand_new}}</label>
              </div>

            </div>

            <div class="col-8 pt-3 ps-4">

              <located-list/>

            </div>


            <div class="col-12 border-top border-bottom mb-3 mt-3">

              <h5 class="mt-2">{{car_languages[selected_language].documents_required}}:</h5>


              <div class="row">

                <div class="col-4">

                  <div class="form-check form-switch">
                    <input class="form-check-input" @click="car.data.required_pof = !car.data.required_pof" :checked="car.data.required_pof" type="checkbox" role="switch" id="flexSwitchCheckNda">
                    <label class="form-check-label" for="flexSwitchCheckNda">
                      {{car_languages[selected_language].proof_of_funds}}</label>
                  </div>

                </div>

                <div class="col-4">

                  <div class="form-check form-switch">
                    <input class="form-check-input" @click="car.data.required_loi = !car.data.required_loi" :checked="car.data.required_loi" type="checkbox" role="switch" id="flexSwitchCheckLoi">
                    <label class="form-check-label" for="flexSwitchCheckLoi">
                      {{car_languages[selected_language].letter_of_intent}}</label>
                  </div>

                </div>

                <div class="col-4">

                  <div class="form-check form-switch">
                    <input class="form-check-input" @click="car.data.required_nda = !car.data.required_nda" :checked="car.data.required_nda" type="checkbox" role="switch" id="flexSwitchCheckPof">
                    <label class="form-check-label" for="flexSwitchCheckPof">NDA</label>
                  </div>

                </div>

              </div>


            </div>


            <div class="col-7" v-if="user_type == 1">

              <small>VIN</small>
              <div class="input-group mb-3">
                <!--                    <span class="input-group-text">MSRP</span>-->

                  <input type="text" v-model.trim="car.data.vin"
                         class="form-control form-control-lg" id="floatingInputVin" placeholder="VIN">


              </div>

            </div>




            <div class="col-12 mb-3 text-muted" v-if="car.data.brand_new">
              Visible only to dealers. If both prices are attractive, then the car will be marked as a hot offer.
            </div>


            <div class="col-12  mb-3">


              <small>{{car_languages[selected_language].description}} / {{car_languages[selected_language].build_sheet}}</small>
              <textarea class="form-control" rows="3" v-model.trim="car.data.description"></textarea>


            </div>


<!--            <div class="form-check form-switch" :class="b2b_condition ? 'mb-1' : 'mb-3 ms-2'">-->
<!--              <input class="form-check-input" type="checkbox" role="switch" @click="b2b_condition = !b2b_condition" :checked="b2b_condition" id="flexSwitchCheckB2bCondition">-->
<!--              <label class="form-check-label h5 handLink" for="flexSwitchCheckB2bCondition">Sell through brokers</label>-->
<!--            </div>-->


            <span>Direct customers can request the car, also</span>
            <div class="col-12  mb-3 card border-danger pb-3 animate__animated animate__fadeIn">

              <div class="row">

                <div class="col-12 mb-2 mt-2 mb-3 h5">
                  {{car_languages[selected_language].provide_information}}:

                </div>

                <div class="col-7">

                  <small>{{car_languages[selected_language].brokers_fee}}</small>
                  <div class="input-group mb-3">
<!--                    <span class="input-group-text">$</span>-->
                    <div class="form-floating">
                      <input type="number" inputmode="numeric" v-model.trim="car.data.commission_amount" @input="numbersOnly"
                             class="form-control is-invalid" id="floatingInputAmount" placeholder="amount">
                      <label for="floatingInputAmount">$</label>
                    </div>
                  </div>

                </div>



<!--                <div class="col-7 mb-3 mt-3">-->

<!--                  <div class="form-check form-switch">-->
<!--                    <input class="form-check-input" :checked="car.data.commission_not_included" @click="car.data.commission_not_included = !car.data.commission_not_included" type="checkbox" role="switch" id="flexSwitchCheckCommissionOff">-->
<!--                    <label class="form-check-label" for="flexSwitchCheckCommissionOff">Brokers add their own commissions; there are no commissions included.</label>-->
<!--                  </div>-->



<!--                </div>-->




              </div>



              <textarea class="form-control" rows="3" v-model="car.data.description_btwob" :placeholder="car_languages[selected_language].optional"></textarea>


            </div>

            <div class="col-12 border-top">

                <h5 class="mt-2">{{car_languages[selected_language].how_would_you_like_to_sell}}:</h5>

            </div>

            <div class="col-6">

              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" @click="car.data.buy_cash = !car.data.buy_cash"
                       :checked="car.data.buy_cash" role="switch" id="flexSwitchCheckBuyCash">
                <label class="form-check-label" for="flexSwitchCheckBuyCash">
                  {{car_languages[selected_language].cash}}</label>
              </div>


            </div>

            <div class="col-6">

              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" @click="car.data.buy_credit = !car.data.buy_credit"
                       :checked="car.data.buy_credit" role="switch" id="flexSwitchCheckBuyCredit">
                <label class="form-check-label" for="flexSwitchCheckBuyCredit">
                  {{car_languages[selected_language].finance}}</label>
              </div>

            </div>

            <div class="col-6 mt-3">

              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" @click="car.data.buy_leasing = !car.data.buy_leasing"
                       :checked="car.data.buy_leasing" role="switch" id="flexSwitchCheckBuyLeasing">
                <label class="form-check-label" for="flexSwitchCheckBuyLeasing">
                  {{car_languages[selected_language].leasing}}</label>
              </div>

            </div>

            <div class="col-6 mt-3">

              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" @click="car.data.trade_in = !car.data.trade_in"
                       :checked="car.data.trade_in" role="switch" id="flexSwitchCheckTradeInCar">
                <label class="form-check-label" for="flexSwitchCheckTradeInCar">
                  {{car_languages[selected_language].trade_in}}</label>
              </div>

            </div>


          </div>

          <finance-form v-if="car.data.buy_credit"/>

          <leasing-form v-if="car.data.buy_leasing"/>

          <!--        {{car.data}}-->


          <div class="col-12 mt-3 border-top pt-3">

            <label for="privateFormControlTextarea" class="form-label">{{car_languages[selected_language].private_addition}}</label>
            <textarea class="form-control" v-model.trim="car.data.private_info" id="privateFormControlTextarea" rows="2" placeholder="Optional"></textarea>
          </div>

        </div>

        <div class="modal-footer">

          <div class="row container">

            <div class="col-6 pt-2">

              <small class="text-muted">{{car_languages[selected_language].price_MSRP_is_must}}</small>
            </div>

            <div class="col-6 text-end">

              <button type="button" class="btn btn-primary rounded-pill"
                      :disabled="checkTextForm" data-bs-dismiss="modal"
                      @click="saveOrUpdate()">SAVE
              </button>

            </div>

          </div>

          <!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->

        </div>

      </div>
    </div>
  </div>


</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {Cars} from '@/composables/profile/cars'
import {userState} from '@/composables/login'
import CarsModelsSelect from '@/components/profile/cars/CarsModelsSelect.vue'
import {Languages} from '@/composables/Languages';
//import ColorList from '@/components/profile/cars/ColorList.vue'
//import ColorInteriorList from '@/components/profile/cars/ColorInteriorList.vue'
import FinanceForm from '@/components/profile/cars/FinanceForm.vue'
import LeasingForm from '@/components/profile/cars/LeasingForm.vue'
import LocatedList from '@/components/profile/cars/LocatedList.vue'
import {useRouter} from 'vue-router'
import {Helpers} from '@/composables/helpers';


const {car_languages, selected_language} = Languages()

const {numbersOnly} = Helpers()

const route = useRouter()

const {car, storeCar, getCars, getCar, updateCar, getCarsModels, cars_models} = Cars()

const {user_id, user_type} = userState()

const b2b_condition = ref(false)

const checkTextForm = computed(() => {

  if (!car.data.msrp_price && car.data.price_request == true && !car.data.pre_order) {

    return true
  }

  if (!car.data.price && !car.data.price_request && !car.data.msrp_price && !car.data.pre_order) {

    return true
  }

  if (car.data.brand_new == true && !car.data.msrp_price) {

    return true
  }

  if (!car.data.model_id || !car.data.year) {

    return true
  }

  return false
})
const saveOrUpdate = async () => {

  car.data.user_id = user_id.value

  if (car.data.id) {

    await updateCar(car.data.id)


  } else {

    await storeCar()

  }

  await getCars()

  await route.push({path: '/list-cars'})

  await getCar(car.data.id);

  const m = document.getElementById('photoToCar' + car.data.id)

  if (m) {
    m.click()
  }


  window.scrollTo(0, 0)


}

onMounted( () =>{

  //console.log('cars_models.data')
  //console.log(Object.keys(cars_models.data).length)

  if (user_id.value && Object.keys(cars_models.data).length == 0) {
    getCarsModels()
  }

  b2b_condition.value = true
})

defineProps(['id'])
</script>
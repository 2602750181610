<template>


  <div class="card">

    <h5 class="card-header oswald">Finance</h5>


    <div class="card-body">


      <div class="row text-center">

        <div class="col-sm-12 col-md-3 mb-3" v-if="car.data.finance_interest">
          <span class="text-muted">Interest</span>
          <h5 class="mt-2">{{car.data.finance_interest}}%</h5>
        </div>

        <div class="col-sm-12 col-md-3 mb-3" v-if="car.data.finance_monthly">
          <span class="text-muted">Payment monthly</span>
          <h5 class="mt-2">${{car.data.finance_monthly}}</h5>
        </div>

        <div class="col-sm-12 col-md-3 mb-3" v-if="car.data.finance_first_payment">
          <span class="text-muted">First payment</span>
          <h5 class="mt-2">${{car.data.finance_first_payment}}</h5>
        </div>

        <div class="col-sm-12 col-md-3 mb-3" v-if="car.data.finance_for_months">
          <span class="text-muted">Contract for</span>
          <h5 class="mt-2">{{car.data.finance_for_months}} months</h5>
        </div>


        <div class="col-12 text-start mt-3 border-top pt-2 text-muted">

          {{car.data.finance_addition}}
        </div>



      </div>


    </div>
  </div>



</template>

<script setup>

import {Cars} from "@/composables/profile/cars";

const {car} = Cars()
</script>
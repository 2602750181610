<template>



  <!-- Modal -->
  <div class="modal fade" id="selectedCarRequestModal" tabindex="-1" aria-labelledby="selectedCarRequestModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content rounded-4">

        <div class="modal-header">

          <div class="container row p-0 m-0">

            <div class="col-11">

              <img v-if="car_provider.pic" :src="'https://hotautomarket.com/storage/photo/'+ car_provider.id +'.jpg'" width="70"
                   class="rounded-circle img_provider float-start me-2 shadow" style="margin-top: -5px; margin-left: -15px">

              <span class="oswald me-1"> {{ car_provider.name }} </span>
              <span class="text-muted oswald fw-light" v-if="car_provider.business_name">[{{ car_provider.business_name }}]</span> &nbsp;


              <br>
<!--              <small class="text-muted">Model:</small>-->
              <span v-if="car.data && car.data.model && car.data.model.model" class="modal-title fs-5 text-danger" id="selectedCarRequestModalLabel">

                {{ car.data.model.model }} <small class="oswald fw-light" v-if="car.data.model_addition">({{ car.data.model_addition }})</small>
              </span>

            </div>

            <div class="col-1 text-end">

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>


            </div>

          </div>


        </div>

        <div class="modal-body">


          <div class="row">

            <div class="col-12" v-if="!user_id">

              <card-login/>

              <card-signup/>

              <forgotten-tab/>


            </div>

            <!-- if customer -->
            <div class="col-12" v-else>

              <customer-form/>

            </div>

          </div>


        </div>

        <!--        <div class="modal-footer">-->
        <!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
        <!--          <button type="button" class="btn btn-primary">Save changes</button>-->
        <!--        </div>-->

      </div>
    </div>
  </div>


</template>

<script setup>
import {userState} from "@/composables/login";
import {Cars} from "@/composables/profile/cars";


import CardLogin from "@/components/login/CardLogin.vue";
import CardSignup from "@/components/login/CardSignup.vue";
import ForgottenTab from "@/components/login/forgotten/ForgottenTab.vue";

import CustomerForm from '@/components/offer/request/CustomerForm.vue'
import TransferCustomer from '@/components/offer/request/TransferCustomer.vue'

import CheckUser from '@/components/offer/request/CheckUser.vue';

const {car} = Cars()

const {user_id, user_type} = userState()

defineProps(['car_provider'])
</script>
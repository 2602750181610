<template>

  <!-- Invite -->
  <div class="row animate__animated animate__fadeInDown mt-3">

    <!-- company name -->
    <div class="col-12">

      <div class="input-group input-group-lg mb-3" v-if="user_signup.data.type == 1">
        <span class="input-group-text" id="company-addon">Company name</span>
        <input type="text" v-model.trim="user_signup.data.business_name" class="form-control" aria-label="Company name"
               aria-describedby="company-addon">
      </div>

    </div>

    <div class="col-12">

      <div class="input-group input-group-lg" v-if="!trial">
        <span class="input-group-text" id="invite-addon">Invite code:</span>
        <input type="text" v-model.trim="invite" @input="checkInvite()"
               :class="invite_valid ? 'is-valid' : ''" class="form-control" aria-label="Username"
               placeholder="abcd-1234"
               aria-describedby="invite-addon">
      </div>


<!--      <small class="d-inline-block mt-2" v-if="!invite_valid && !trial">-->
<!--        Membership in the club costs <span class="text-danger">$5500</span> per year or you can search for an invitation.-->
<!--      </small>-->

<!--      <div class="form-check form-switch h5" v-if="!invite_valid" :class="trial ? '' : 'mt-1'">-->
<!--        <input class="form-check-input" @click="trial = !trial" type="checkbox" role="switch" id="flexSwitchCheckTrial">-->
<!--        <label class="form-check-label" for="flexSwitchCheckTrial">-->

<!--         <span class="handLink d-inline-block mt-1" v-if="!trial" :class="!trial ? 'text-danger' : ''"> Start Free Trial for 6 months</span>-->
<!--         <span v-else class="handLink text-primary animate__animated animate__fadeInRight d-inline-block">A six-month trial has been activated.<br>-->
<!--           <small class="text-muted">No card is required.</small> </span>-->

<!--        </label>-->
<!--      </div>-->


      <h5 class="mt-3">You will get</h5>
      <ol>
        <li>NDAs, letters of intent, and proof of funds can be signed immediately by your customers within the platform.</li>
        <li>The "Wanted" section is the place to post requests for cars.</li>
        <li>Car placement.</li>
      </ol>



    </div>
  </div>

</template>


<script setup>
import {onMounted, ref} from "vue";
import {userState} from "@/composables/login";
import axios from "axios";



const {user_signup, invite, invite_valid} = userState()

const trial = ref(false)

const checkInvite = async () => {

  try {

    axios.get('/sanctum/csrf-cookie').then(async () => {

      await axios.post('/api/check-invite', {code: invite.value}).then((response) => {

        if (response.data > 0) {

          user_signup.data.invite_id = response.data

          invite_valid.value = true
        } else {

          user_signup.data.invite_id = ''
          invite_valid.value = false
        }

      })
    })

  } catch (e) {

    console.log(e)
  }
}

onMounted(async () => {

  let hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

  invite.value = hash

  await checkInvite()

})

//const checkInviteForm = computed(() => {return checkInvite()})
</script>
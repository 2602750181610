<template>

  <div class="container d-none d-md-block mb-5">

  <h3 class="text-muted">News</h3>

  <div class="row row-cols-1 row-cols-md-3 g-4 mb-3">

    <div class="col" :key="b.id" v-for="b in blog_list">

      <div class="card h-100 rounded-4">

        <RouterLink
            aria-current="news"
            :aria-label="'View news '+ b.title"
            class="oswald rounded-top-4 imgPhotoFixed" @click="router.push('/blog/' + b.id)" :to="'https://hotautomarket.com/blog/' + b.id"

            :style="'background-image: url(https://hotautomarket.com/storage/blog/'+ b.photo.file +')'"> &nbsp;

        </RouterLink>

<!--        <img v-if="b.photo && b.photo.file"  @click="router.push('/blog/' + b.id)"-->
<!--            :src="'https://hotautomarket.com/storage/blog/' + b.photo.file"-->
<!--              class="card-img-top rounded-top-4 imgPhotoFixed" alt="...">-->

        <div class="card-body">

          <h5 class="card-title">{{b.title}}</h5>



        </div>

        <div class="card-footer bg-transparent border-0">
          <div class="d-grid">
            <RouterLink :to="'/blog/' + b.id" class="btn btn-primary rounded-pill" type="view">VIEW</RouterLink>
          </div>
        </div>



      </div>
    </div>
  </div>

  </div>

  <!-- d-SM NEWS-->
  <div class="container d-md-none mb-4">

        <carousel-main/>
  </div>

</template>

<script setup>
//import {onBeforeMount} from "vue";
import {Blog} from '@/composables/blog';
import router from "@/router";
import CarouselMain from "@/components/blog/CarouselMain.vue";

const {blog_list} = Blog() // , getBlogs

</script>
<template>

  <button @click="submit()" class="btn btn-primary rounded-pill btn-lg mt-3 animate__animated animate__fadeIn">
    ORDER CAR INSPECTION FOR ${{ amount }}
  </button>

</template>

<script setup>
import {InspectionCart} from "@/components/offer/inspection/InspectionCart";


const {cart, storeOrder, inspection_order, items, request_sent} = InspectionCart()

defineProps(['amount']);

const submit = async () => {

  inspection_order.value['cart'] = cart.value;

  await storeOrder()


  cart.value = []

  setTimeout( () => {

    request_sent.value = false


    cart.value[0] = items[0]

  }, 10000)
}

</script>
<template>

  <!-- Car Request Modal -->
  <div class="modal fade" id="carRequestModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="carRequestModalLabel" aria-hidden="true">
    <div class="modal-dialog">

      <div class="modal-content">

        <div class="modal-header">

          <div class="row container p-0 m-0">
            <div class="col-11">

              <span class="modal-title fs-5 h1 d-inline-block" id="carRequestModalLabel">
                {{car_languages[selected_language].add_car.header}}
              </span>

              <span class="text-muted" v-if="!user_id">Before start please LogIn or Create account</span>

            </div>
            <div class="col-1 text-end">
              <button type="button" ref="btn_close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>


          </div>




        </div>

        <div class="modal-body" v-if="user_id">

          <cars-models-select v-if="!car.data.id"/>


          <div class="row">

            <div class="col-12 mb-3 animate__animated animate__fadeInDown" v-if="car.data.model_id">


              <small class="form-label text-muted">Addition about model (optional)</small>
              <input type="text" :maxlength="75" class="form-control" v-model="car.data.model_addition">

            </div>

            <div class="col-4 mb-3">

              <small class="form-label">{{car_languages[selected_language].year}}</small>
              <input type="number" :maxlength="4" class="form-control" v-model.number="car.data.year">

            </div>


            <div class="col-4 mb-3">

              <color-list/>

            </div>


            <div class="col-4 mb-3">

              <color-interior-list/>

            </div>


            <div class="col-4 mb-3">

              <small class="form-label">{{car_languages[selected_language].mileage}}</small>
              <input type="number" maxlength="5" class="form-control" v-model.number="car.data.mileage">

            </div>


            <div class="col-4 mb-3">

              <small class="form-label">{{car_languages[selected_language].price_up_to}}</small>
              <input type="number" class="form-control" v-model.number="car.data.price">

            </div>

            <div class="col-4 mb-3">

              <div class="form-check form-switch mt-4">
                <input class="form-check-input" :checked="car.data.brand_new"
                       @click="car.data.brand_new = !car.data.brand_new" type="checkbox" role="switch"
                       id="flexSwitchCheckBrandNew">
                <label class="form-check-label" for="flexSwitchCheckBrandNew">{{car_languages[selected_language].brand_new}}</label>
              </div>


            </div>


            <div class="col-12  mb-3">


              <small>{{car_languages[selected_language].requirements}}</small>
              <textarea class="form-control" rows="3" v-model="car.data.description"></textarea>


            </div>


            <!--            <div class="col-12  mb-3">-->


            <!--              <small>Description B2B</small>-->
            <!--              <textarea class="form-control" rows="3" v-model="car.data.description_btwob"></textarea>-->


            <!--            </div>-->


            <div class="col-6 mb-2">

              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" @click="car.data.buy_cash = !car.data.buy_cash"
                       :checked="car.data.buy_cash" role="switch" id="flexSwitchCheckBuyCash">
                <label class="form-check-label" for="flexSwitchCheckBuyCash">{{car_languages[selected_language].cash}}</label>
              </div>


            </div>

            <div class="col-6 mb-2">

              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" @click="car.data.buy_credit = !car.data.buy_credit"
                       :checked="car.data.buy_credit" role="switch" id="flexSwitchCheckBuyCredit">
                <label class="form-check-label" for="flexSwitchCheckBuyCredit">{{car_languages[selected_language].finance}}</label>
              </div>

            </div>

            <div class="col-6">

              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" @click="car.data.buy_leasing = !car.data.buy_leasing"
                       :checked="car.data.buy_leasing" role="switch" id="flexSwitchCheckBuyLeasing">
                <label class="form-check-label" for="flexSwitchCheckBuyLeasing">{{car_languages[selected_language].leasing}}</label>
              </div>

<!--                  <us-states-select v-if="car.data.buy_leasing" />-->

            </div>


            <div class="col-6">

              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" @click="car.data.trade_in = !car.data.trade_in"
                       :checked="car.data.trade_in" role="switch" id="flexSwitchCheckBuyTradeIn">
                <label class="form-check-label" for="flexSwitchCheckBuyTradeIn">{{car_languages[selected_language].trade_in}}*</label>
              </div>

            </div>

            <div class="col-12" v-if="car.data.trade_in">

              <div class="row mt-3 animate__animated animate__fadeInDown">

                <div class="col-6">

                  <div class="input-group mb-3">
                    <span class="input-group-text" id="customer-addon-vin">VIN</span>
                    <input type="text" v-model="car.data.customer_vin" class="form-control" placeholder="number"
                           aria-label="Username" aria-describedby="customer-addon-vin">
                  </div>


                </div>

                <div class="col-6">

                  <div class="input-group mb-3">
                    <span class="input-group-text" id="customer-addon-miles">Miles</span>
                    <input type="text" v-model="car.data.customer_miles" class="form-control" aria-label="Username"
                           aria-describedby="customer-addon-miles">
                  </div>

                </div>

              </div>


            </div>


          </div>

        </div>
        <div class="modal-body" v-else>

          <card-login/>
          <card-signup/>

        </div>

        <div class="modal-footer">
          <!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->

          <div class="container d-grid gap-2" v-if="user_id">

            <button type="button" class="btn btn-primary rounded-pill btn-lg" @click="saveBid()">
              SUBMIT THE CAR
            </button>
          </div>


        </div>

      </div>
    </div>
  </div>


</template>

<script setup>
import {onMounted, ref} from "vue";
import {Cars} from '@/composables/profile/cars';
import {userState} from '@/composables/login';
import {Languages} from '@/composables/Languages';
import CarsModelsSelect from '@/components/profile/cars/CarsModelsSelect.vue';
import ColorList from '@/components/profile/cars/ColorList.vue';
import ColorInteriorList from '@/components/profile/cars/ColorInteriorList.vue';
import CardLogin from '@/components/login/CardLogin.vue';
import CardSignup from '@/components/login/CardSignup.vue';


const {car_languages, selected_language} = Languages()

const btn_close = ref()

const {user_id} = userState()

const {car, storeBid, getCarsModels, cars_models} = Cars()

const saveBid = async () => {

  btn_close.value.click()

  car.data.user_id = user_id.value

  await storeBid(null)

  car.data = {}

  alert('The request has been posted to the dealer list!'+"\n\n"+'As soon as the dealer finds an offer that suits your needs, it will be sent to you.' + "\n\n" + 'Thank you')

}



onMounted( () => {

    setTimeout( () => {

      if(Object.keys(cars_models.data).length === 0){

        getCarsModels()
      }

    }, 1000)

})

</script>
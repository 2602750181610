<template>

  <button v-if="!price_evaluation" @click="run = true, evaluateMyCar()" :disabled="run" class="btn btn-primary rounded-4 mt-3 btn-lg comfortaa">
    Evaluate My Car
  </button>

  <div class="mt-3 mb-3 animate__animated animate__fadeInDown" v-if="evaluation.prices && evaluation.prices.below">
    <h5 class="oswald">{{ evaluation.vehicle }}</h5>

    <span class="comfortaa fw-light">
    Approximately the car will cost

      <span>${{ Math.floor(evaluation.prices.below + 1000).toLocaleString() }}</span>

    </span>


  </div>

</template>

<script setup>
import {ref} from "vue";
import {TradeIn} from '@/components/offer/trade_in/trade_in';
import {Cars} from '@/composables/profile/cars';
import {userState} from '@/composables/login';

const {user_id} = userState()

const {car} = Cars()

const {
  getEvaluation, evaluation, price_evaluation,
  trade_in, sendRequest
} = TradeIn()

const run = ref(false)

const evaluateMyCar = async () => {

  await getEvaluation();

  console.log(evaluation.value)

  price_evaluation.value = evaluation.value.prices.average;

  await sendData();
}

const sendData = async () => {

  trade_in.value.car_id = car.data.id
  trade_in.value.user_id = user_id.value

  //console.log(car.data.id)

  //if (!trade_id.value) {

    await sendRequest()
  //}
}
</script>
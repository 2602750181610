<template>

  <div id="carouselExampleControlsMdMd" class="carousel slide mb-4 d-none d-md-block" data-bs-ride="carousel">

    <div class="carousel-inner d-none d-md-block">


      <template :key="'a' + i" v-for="(three, i) in byThree">


        <div class="carousel-item" :class="i == 0 ? 'active' : ''">

          <div class="row row-cols-1 row-cols-md-3 g-4">


            <template :key="c.id" v-for="c in three">
              <div class="col">

                <div class="card card_size h-100 rounded-4 animate__animated animate__fadeIn">


                  <!-- cars -->
                  <RouterLink class="oswald rounded-top-4 imgPhotoFixed" :to="'/offer/' + c.id" :style="'background-image: url(https://hotautomarket.com/storage/cars/'+ c.photo.pfile +')'">
                    <!--              <img v-if="c.photo && c.photo.id" :src="'https://hotautomarket.com/storage/cars/'+ c.photo.pfile"-->
                    <!--                   class="card-img-top img-fluid" :alt="'Hot offer ' + c.model.model + (c.model_addition ? ' ' + c.model_addition : '' )" loading="lazy">-->
                  </RouterLink>
                  <div class="card-body ">

                    <div class="row">

                      <div class="col-6">

                        <h5 class="card-title oswald">{{ c.model.model }}</h5>

                        <!--                  <small class="text-muted oswald ms-1" v-if="c.model_addition">{{ c.model_addition }}</small>-->

                        <span class="oswald h3 d-block text-primary fw-light">{{ c.model.brand.name }}</span>

                        <br v-if=" c.provider.zipcode">
                        <small class="text-danger" v-if="c.brand_new">Brand new</small>
                        <span v-if="c.provider.zipcode && c.brand_new">, </span>
                        <small v-if=" c.provider.zipcode"
                               class="text-muted">ZIP: {{ c.provider.zipcode }}</small>
                      </div>

                      <div class="col-6 text-end">

                        <price-type :car="c"/>

                        <br v-if="c.year">
                        <span class="d-inline-block mt-2 text-muted oswald">
                    Mileage: {{ c.mileage }}, &nbsp;
                  </span>
                        <span class="oswald d-inline-block mt-2 text-muted">
                {{ c.year ? c.year : '' }}
              </span>


                      </div>

                    </div>


                    <!-- Comment -->
                    <!--              <comment-item v-if="c.comment" :comment="c.comment" />-->

                  </div>

                  <div class="card-footer text-center sss rounded-bottom-4" @click="router.push('/offer/' + c.id)">

                    <RouterLink :to="'/offer/' + c.id" class="text-decoration-none text-light fw-bold">
                      {{ languages[selected_language].view_hot_deal }}
                    </RouterLink>

                    <!--          <small class="text-muted">Updated {{ moment(c.updated_at).fromNow() }}</small>-->
                  </div>

                </div>
              </div>
            </template>


          </div>
        </div>


      </template>

    </div>

    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsMdMd" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsMdMd" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>

  </div>


  <!--   {{byThree}}-->

</template>

<script setup>
import {onMounted, computed} from "vue";
import {Cars} from "@/composables/profile/cars";
import {useRouter} from 'vue-router';
import {Languages} from '@/composables/Languages';
import PriceType from "@/components/main_page/PriceType.vue";
//import CommentItem from "@/components/main_page/CommentItem.vue";


const {languages, selected_language} = Languages()

const router = useRouter()

const {cars_hot_offers, getHotOffers} = Cars()

//const byThree = ref([])


const byThree = computed(() => {

  if( Object.keys(cars_hot_offers.data).length == 0){

    return [];
  }

  let a = []

  for (let i = 0; i < cars_hot_offers.data.length; i += 3) {
    a.push(cars_hot_offers.data.slice(i, i + 3));
  }

  //console.log('+++')

  return a
})

// const chunkArray = (array, size) => {
//
//   //const result = [];
//
//   for (let i = 0; i < array.length; i += size) {
//     byThree.value.push(array.slice(i, i + size));
//   }
//
//   //return result;
// }

// watchEffect(() => {
//
//   if ( Object.keys(cars_hot_offers.data).length > 0 && !byThree.value) {
//
//     chunkArray(cars_hot_offers.data, 3)
//
//     console.log('+');
//
//   }
//
//   console.log('-');
// });

// onMounted(async () => {
//
//   //if(isLoaded.value){
//
//   setTimeout(() => {
//
//     chunkArray(cars_hot_offers.data, 3)
//
//     console.log('-');
//
//   }, 800)
//
//
// })

// if (isLoaded.value) {
//
//   chunkArray(cars_hot_offers.data, 3)
//
//   console.log(isLoaded.value)
// }


</script>

<style scoped>

.sss {
  background-image: linear-gradient(to right, #D31027 0%, #EA384D 51%, #D31027 100%);

}

.sss:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.vvv {
  background: #ECE9E6; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.card_size {

  min-height: 480px;
}
</style>
<template>

  <br>
  &nbsp; NEWS

  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="true">
    <!--    <div class="carousel-indicators">-->
    <!--      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>-->
    <!--      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>-->
    <!--      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>-->
    <!--    </div>-->
    <div class="carousel-inner">

      <div class="carousel-item rounded-4"

           :class="i == 0 ? 'active' : ''" :key="b.id" v-for="(b, i) in blog_list">


        <div class="card rounded-4" @click="router.push('/blog/' + b.id)">
          <img :src="'https://hotautomarket.com/storage/blog/' +  b.photo.file" class="card-img-top rounded-top-4 imgPhotoFixed" alt="...">
          <div class="card-body">

            <RouterLink class="card-text oswald" :to="'/blog/' + b.id">{{ b.title }}</RouterLink>
<!--            <a href="#" class="btn btn-primary">Go somewhere</a>-->
          </div>
        </div>


        <div class="carousel-caption">

          <!--          <RouterLink class="h5  oswald text-decoration-none" :to="'/blog/' + b.id">  </RouterLink>-->

        </div>


<!--        <div class="pe-1 ps-2 pt-1">-->
<!--          <RouterLink class="h5 mt-2 oswald text-decoration-none" :to="'/blog/' + b.id">{{ b.title }}</RouterLink>-->
<!--        </div>-->


      </div>

    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

</template>

<script setup>
import {Blog} from '@/composables/blog';
import router from "@/router";
//import {Helpers} from '@/composables/helpers';

const {blog_list} = Blog()

//const {getFirstWords} = Helpers()

</script>

<style scoped>

.imgPhotoFixed{
  height: 200px;
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center;
}

</style>
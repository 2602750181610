<template>

  <div class="row" v-if="forgotten_tab">

    <div class="col-12" v-if="!email_exists">

      <!-- main form -->

      <div class="input-group input-group-lg mb-4">

                <span class="input-group-text text-danger">
<!--                  <img src="/img/icons/5/email.svg" alt="email sign">-->
                  @
                </span>

        <input type="email" class="form-control" placeholder="enter@email" v-model="email">


      </div>

      <!--    errorLoginMessage -->
      <!--    <div class="d-grid mt-4 text-danger text-center" v-if="errorLoginMessage">-->
      <!--      Error: check email or password-->
      <!--    </div>-->

      <div class="d-grid mt-4">
        <button type="submit" class="btn btn-primary btn-lg" @click="sendPin()">Reset</button>

      </div>

      <div class="row mt-4 border-top pt-3">

        <div class="col-12">

          <span class="btn btn-outline-secondary btn-sm me-3" @click="login_tab=true; join_tab=false; forgotten_tab=false">LogIn</span>

          <span class="btn btn-outline-secondary btn-sm" @click="login_tab=false; join_tab=true; forgotten_tab=false">SignUp</span>

        </div>

      </div>

    </div>


    <div class="col-12" v-else>

      <pin-form/>

    </div>


  </div>

</template>
<script setup>

import {userState} from "@/composables/login";
import {Recovery} from "@/composables/login_forgotten";

import PinForm from '@/components/login/forgotten/PinForm.vue'

const {forgotten_tab, login_tab, join_tab} = userState();

const {email, email_exists, sendPin} = Recovery(); // getUser,

</script>
<template>

  <button class="btn btn-danger btn-lg rounded-pill mt-3 comfortaa animate__animated animate__fadeIn"
          data-bs-toggle="modal" data-bs-target="#checkAvailabilityModal">
    REQUEST
  </button>

  <!-- Modal: check availability - public  -->
  <div class="modal fade" id="checkAvailabilityModal" tabindex="-1" aria-labelledby="ContactSellerModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content rounded-4">
        <div class="modal-header">
          <div class="row container m-0 p-0">

            <div class="col-10">
              <span class="h5 oswald modal-title" id="ContactSellerModalLabel">Contact Seller</span><br>

              <small class="text-muted">

                <img :src="'/img/brand/' +car.model.brand.id + '.svg'" style="max-height: 24px"
                     class="img-fluid float-start me-1" :alt="car.model.brand.name">
                {{ car.model.model }}

                {{ car.model_addition }}
              </small>
            </div>

            <div class="col-2 text-end">

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

          </div>


        </div>
        <div class="modal-body">


          <div class="row">

            <div class="col-6">

              <div class="form-floating mb-3">
                <input v-model="availability.name" type="text" class="form-control" id="floatingInputAvailabilityName" placeholder="First name">
                <label for="floatingInputAvailabilityName">First name</label>
              </div>
            </div>

            <div class="col-6">

              <div class="form-floating mb-3">
                <input  v-model="availability.surname" type="text" class="form-control" id="floatingInputAvailabilitySurname" placeholder="Last name">
                <label for="floatingInputAvailabilitySurname">Last name</label>
              </div>
            </div>


            <div class="col-6">

              <div class="form-floating mb-3">
                <input  v-model="availability.email" type="email" class="form-control" id="floatingInputAvailabilityEmail" placeholder="name@example.com">
                <label for="floatingInputAvailabilityEmail">Email address</label>
              </div>
            </div>

            <div class="col-6">

              <div class="form-floating mb-3">
                <input  v-model="availability.phone" type="tel" class="form-control" id="floatingInputAvailabilityPhone" placeholder="Phone">
                <label for="floatingInputAvailabilityPhone">Phone</label>
              </div>
            </div>

            <div class="col-12">

              <select  v-model="availability.type" class="form-select form-select-lg" id="floatingSelect" aria-label="Floating label select example">
                <option value="Check availability and Carfax" selected>Check availability & Carfax</option>
                <option value="Get a price quote">Get a price quote</option>
                <option value="Schedule a test drive">Schedule a test drive</option>
                <option value="Discuss financing">Discuss financing</option>
                <option value="Ask a question">Ask a question</option>
              </select>


            </div>

            <div class="col-12 mt-3">

              <small for="exampleFormControlTextarea1" class="form-label text-muted">Comments</small>
              <textarea v-model="availability.comments" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>

            </div>

            <div class="col-12 mt-3">


              <check-availability-trade-in/>


            </div>

          </div>

          <button v-if="!sent" @click="sent = true, sendRequest()"
                  type="button" class="btn btn-primary rounded-pill btn-lg float-end mt-3">REQUEST</button>
          <span v-else class="h5 d-inline-block mt-3 text-danger animate__animated animate__fadeInDown">Request Sent!</span>

        </div>


        <!--        <div class="modal-footer">-->
        <!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
        <!--          -->
        <!--        </div>-->
      </div>
    </div>
  </div>


</template>

<script setup>
import {AvailabilityPublic} from '@/composables/availability_public'
import CheckAvailabilityTradeIn from "@/components/offer/CheckAvailabilityTradeIn.vue";
import {onMounted, ref} from "vue";

const {availability, sendRequest, sent, availability_car} = AvailabilityPublic()



const props = defineProps(['car'])

onMounted( () => {

  availability.value.type = 'Check availability and Carfax'
  availability_car.value = props.car.id


})
</script>
import {nextTick, computed} from "vue";
//import Masonry from "masonry-layout";
import {userState} from '@/composables/login';
import Masonry from 'masonry-layout';
import {useRouter} from "vue-router";



export const Helpers = () => {

    const router = useRouter()

    const {user_type, user_active} = userState()

    const markFieldUpdated = e => {

        if (!e.target.classList.contains('is-valid')) {

            e.target.classList.add('is-valid')

            setTimeout(() => {
                e.target.classList.remove('is-valid')
            }, 2000);
        }
    }

    /**
     * @keypress="numbersOnly"
     *
     * @param e
     */
    const numbersOnly = e => {

        //console.log(e.target.value)

        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '');


        // const keyCode = (e.keyCode ? e.keyCode : e.which);
        // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        //     e.preventDefault();
        // }
    }


    /**
     * Minutes - to hours + minutes
     *
     * @param n: number
     * @returns {*}
     */
    const timeConvert = (n) => {

        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        //return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
        return (rhours > 0 ? rhours + "h " : '') + (rminutes != 0 ? rminutes + 'min' : '');
    }

    const listHoursMinutes = () => {

        const minutes = {}

        for (let i = 15; i < 615; i += 15) {
            minutes[i] = timeConvert(i);
        }

        return minutes

    }

    // delete fbclid from fb
    const removeFbMark = () => {

        if (/^\?fbclid=/.test(location.search)) {
            location.replace(location.href.replace(/\?fbclid.+/, ''));
        }
    }

    const roundDistance = (d) => {

        let distance;

        if (d) {
            distance = Math.round(parseInt(d) * 10) / 10
        }

        return distance > 0 ? distance + ' miles from you' : ''
    }

    /**
     * Get min & max price of Service(s) for json-ld
     *
     * @returns {String}
     */
    const pricesRange = (services_grouped) => {

        const price = []

        Object.values(services_grouped.data).forEach((v) => {

            if (v.price > 0) {
                price.push(v.price)

                //console.log(v.price)
            }

            // check sub prices
            if (v.sub_prices) {

                v.sub_prices.forEach(sub => {

                    if (sub.sprice > 0) {
                        price.push(sub.sprice)
                    }

                    //console.log(sub.sprice)
                });
            }

        })

        return Math.min(...price) + (Math.max(...price) != Math.min(...price) ? '-' + Math.max(...price) : '')
    }

    const copyProfileLink = async (id) => {
        try {
            await navigator.clipboard.writeText('https://bebeauty.pro/profile/' + id);
            alert('Link to your website copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }

    const hideTooltip = async () => {


        await nextTick(() => {
            let tooltip = document.querySelector(".tooltip")

            if (tooltip) {
                tooltip.remove();
            }
        })

    }

    /**
     * Trigger when visible =>
     *
     *      onVisible(document.querySelector("#myElement"), () => console.log("it's visible"));
     *
     * https://stackoverflow.com/questions/1462138/event-listener-for-when-element-becomes-visible
     *
     * @param element
     * @param callback
     */
    const onVisible = (element, callback) => {

        new IntersectionObserver((entries, observer) => {

            entries.forEach(entry => {

                if (entry.intersectionRatio > 0) {

                    callback(element);
                    observer.disconnect();
                }

            });

        }).observe(element);
    }


    /*
    https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport
    * */
    // const onVisible= new window.IntersectionObserver(([entry]) => {
    //         if (entry.isIntersecting) {
    //             //console.log('ENTER')
    //             return
    //         }
    //         //console.log('LEAVE')
    //     }, {
    //         root: null,
    //         threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
    //     })


    const validateEmail = (email) => {

        let check = String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

        if (check != null) {

            return true
        }
    };

    // const activateMasonry = async () => {
    //
    //
    //
    //     await nextTick( async () => {
    //
    //         let grid = document.querySelector('.row_masonry');
    //
    //         if(grid){
    //
    //             new Masonry(grid, {});
    //
    //             //  https://github.com/desandro/masonry/issues/1147
    //             await Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
    //
    //                 //let grid = document.querySelector('.row_masonry');
    //                 //let msnry = new Masonry(grid, {
    //                 new Masonry(grid, {});
    //
    //             });
    //
    //             // video
    //             setTimeout( () => {
    //
    //                 new Masonry(grid, {});
    //
    //             }, 5000)
    //
    //         }
    //
    //     } )
    //
    // }

    const checkBTwoB = computed(() => (user_type.value == '1' || user_type.value == '2') && user_active.value == '1' ? true : false)


    function convertToShortForm(number) {
        if (number >= 1000 && number < 1000000) {
            return (number / 1000).toString().split('.')[0] + 'k';
        }
        return number.toString();
    }

    const notifySound = () => {

        let sound = new Audio('/img/video/notify_sound.mp3');
        sound.loop = false;

        //sound.load();
        sound.muted = false
        //let resp = await sound.play();



        sound.play();

        // if (resp !== undefined) {
        //     resp.then(_ => {
        //         // Autoplay started!
        //     }).catch(error => {
        //         // Autoplay was prevented.
        //         // Show a "Play" button so that user can start playback.
        //     });
        // }

        //console.log('NS')
    }

    const activateMasonry = async () => {

        await nextTick( async () => {

            let grid = document.querySelector('.row_masonry');

            if(grid){

                //await new Masonry(grid, {});

                //  https://github.com/desandro/masonry/issues/1147
                await Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {

                    //let g = document.querySelector('.row_masonry');
                    //let msnry = new Masonry(grid, {
                    //new Masonry(grid, {});

                });

                await new Masonry(grid, {});

                // video
                setTimeout( () => {

                    new Masonry(grid, {});

                }, 5000)

            }

        } )

    }

    const states = {

        'AL': 'Alabama',
        'AK': 'Alaska',
        'AS': 'American Samoa',
        'AZ': 'Arizona',
        'AR': 'Arkansas',
        'CA': 'California',
        'CO': 'Colorado',
        'CT': 'Connecticut',
        'DE': 'Delaware',
        'DC': 'District Of Columbia',
        'FM': 'Federated States Of Micronesia',
        'FL': 'Florida',
        'GA': 'Georgia',
        'GU': 'Guam',
        'HI': 'Hawaii',
        'ID': 'Idaho',
        'IL': 'Illinois',
        'IN': 'Indiana',
        'IA': 'Iowa',
        'KS': 'Kansas',
        'KY': 'Kentucky',
        'LA': 'Louisiana',
        'ME': 'Maine',
        'MH': 'Marshall Islands',
        'MD': 'Maryland',
        'MA': 'Massachusetts',
        'MI': 'Michigan',
        'MN': 'Minnesota',
        'MS': 'Mississippi',
        'MO': 'Missouri',
        'MT': 'Montana',
        'NE': 'Nebraska',
        'NV': 'Nevada',
        'NH': 'New Hampshire',
        'NJ': 'New Jersey',
        'NM': 'New Mexico',
        'NY': 'New York',
        'NC': 'North Carolina',
        'ND': 'North Dakota',
        'MP': 'Northern Mariana Islands',
        'OH': 'Ohio',
        'OK': 'Oklahoma',
        'OR': 'Oregon',
        'PW': 'Palau',
        'PA': 'Pennsylvania',
        'PR': 'Puerto Rico',
        'RI': 'Rhode Island',
        'SC': 'South Carolina',
        'SD': 'South Dakota',
        'TN': 'Tennessee',
        'TX': 'Texas',
        'UT': 'Utah',
        'VT': 'Vermont',
        'VI': 'Virgin Islands',
        'VA': 'Virginia',
        'WA': 'Washington',
        'WV': 'West Virginia',
        'WI': 'Wisconsin',
        'WY': 'Wyoming'
    }

    const getFirstWords = (text) => {
        // Split the string into an array of words
        let words = text.split(' ');

        // Slice the first 10 words
        let firstTenWords = words.slice(0, 10);

        // Join them back into a string
        return firstTenWords.join(' ');
    }

    const focusOnSearch = () => {

        if (router.currentRoute.value.path != '/') {

            router.push('/')

            setTimeout(() => {

                document.getElementById('searchControlInput').focus()

            }, 1500)

            setTimeout(() => {

                document.getElementById('searchControlInput').focus()

            }, 2500)

        } else {

            document.getElementById('searchControlInput').focus()
        }

    }

    return {
        markFieldUpdated,
        numbersOnly,
        timeConvert,
        listHoursMinutes,
        removeFbMark,
        roundDistance,
        pricesRange,
        copyProfileLink,
        hideTooltip,
        onVisible,
        validateEmail,
        checkBTwoB,
        convertToShortForm,
        states,
        notifySound,
        activateMasonry,
        getFirstWords,
        focusOnSearch
    }
};
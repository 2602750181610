import {ref} from "vue";
// @ts-ignore
import Modal from 'bootstrap/js/dist/modal';
import router from "@/router";
import {userState} from "@/composables/login";

const {loggedIn} = userState();

//const btn_buy = ref(false)
const btn_sell = ref(false)

/**
 * Helper for Buy & Sell modal
 */
export const BuySellCar = () => {

    const activateBuyCarModal = async () => {

        //let myModal = new Modal(document.getElementById('carRequestModal'), {});

        //const myButton = document.getElementById('my-button-id');

        await router.push('/')

        const myModal = document.getElementById('carRequestModal');

        const modal = new Modal(myModal); // Instantiates your modal

        modal.show(); // shows your modal

    }


    const activateSellCarModal = async () => {

        //let myModal = new Modal(document.getElementById('carRequestModal'), {});

        //const myButton = document.getElementById('my-button-id');

        await router.push('/')

        const myModal = document.getElementById(loggedIn.value ? 'addCarModal' : 'sellCarModal'); //addCarModal // sellCarModal

        const modal = new Modal(myModal); // Instantiates your modal

        modal.show(); // shows your modal

        // if(btn_sell.value){
        //
        //     document.getElementById('changeModalToSell').click()
        // }

        //btn_sell.value = false

    }

    return {activateBuyCarModal, activateSellCarModal, btn_sell}
}